import { images } from '../../images';
import { IClient, IServiceInfoDetail, ITown } from '../models';
import { Colors, InputType, SortCriteria } from '../models/enums';
import { StorageService, UsersService } from '../services';
import { LiteralService } from '../services/literal/literal.service';
import { formData } from './serviceData';

export function getShowTimesValues(serviceInfoDetail?: IServiceInfoDetail, literalService?: LiteralService): unknown[] {
  if (serviceInfoDetail) {
    const data = [];
    serviceInfoDetail.showTheoreticalTimes && data.push({ id: 'showTheoreticalTimes', name: literalService?.get('showTheoreticalTimes', true), code: 'showTheoreticalTimes' });
    serviceInfoDetail.showRealTimes && data.push({ id: 'showRealTimes', name: literalService?.get('showRealTimes', true), code: 'showRealTimes' });
    return data;
  }
  return [
    { id: 'showTheoreticalTimes', name: literalService?.get('showTheoreticalTimes', true), code: 'showTheoreticalTimes' },
    { id: 'showRealTimes', name: literalService?.get('showRealTimes', true), code: 'showRealTimes' }
  ];
}

export async function getClientsTowns(usersService: UsersService): Promise<any> {
  return await usersService.getPossibleClientTowns().then((res) => {
    const clients: any[] = [];
    res.clients.map((client: IClient) => {
      clients.push({ id: client.id, value: client, label: client.name });
    });
    return { clients: clients };
  });
}

export async function getTownsByClient(usersService: UsersService, clientId: any[]): Promise<any> {
  return await usersService.getTownsByClient(clientId).then((res) => {
    // const towns: any[] = [];
    // res.map((town: ITown) => {
    //   towns.push({ id: town.id, value: town, label: town.name });
    // });
    return res;
  });
}

export async function getServiceForm(serviceInfoDetail: IServiceInfoDetail, newService: boolean, usersService: UsersService, literalService: LiteralService, storageService: StorageService, currentStep: number): Promise<unknown[]> {
  let clients: unknown[] = [];
  let towns: unknown[] = [];
  await getClientsTowns(usersService).then((res) => {
    clients = res.clients;
  });
  console.log(serviceInfoDetail)
  if (serviceInfoDetail.client) {
    // towns = await getTownsByClient(usersService, [JSON.parse(serviceInfoDetail.client.toString()).id]);
    towns = await getTownsByClient(usersService, [serviceInfoDetail.client.id]);
  }
  console.log(clients)
  console.log(towns)
  console.log(serviceInfoDetail)
  const data: formData[] = [
    {
      img: images.services.client,
      title: 'services.data.client',
      value: serviceInfoDetail.client,
      required: true,
      expanded: false,
      color: true,
      hidden: !newService,
      informativeToNoChange: serviceInfoDetail.client ? true : false,
      informativeToNoChangeText: serviceInfoDetail.client && serviceInfoDetail.client.name,
      detailDesign: true,
      edit: {
        id: serviceInfoDetail.client,
        formControlName: 'client',
        inputType: InputType.SELECT_SEARCH,
        hidden: !newService,
        translateValue: false,
        required: true,
        values: clients,
        valueToChange: 'client',
      },
    },
    {
      img: images.area,
      title: 'services.data.town',
      value: serviceInfoDetail.town,
      required: true,
      expanded: false,
      color: true,
      hidden: !newService,
      edit: {
        id: serviceInfoDetail.town,
        formControlName: 'town',
        inputType: InputType.SELECT,
        hidden: currentStep === 1 || !newService,
        translateValue: false,
        required: true,
        disabled: serviceInfoDetail.client === undefined ? true : false,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.services.tag,
      title: 'services.data.tag',
      value: serviceInfoDetail.tag,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        formControlName: 'tag',
        hidden: currentStep === 1 && newService,
        required: true,
        valueToChange: 'tag',
      },
    },
    {
      img: images.services.color,
      title: 'services.data.color',
      value: serviceInfoDetail.color,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.COLOR,
        formControlName: 'color',
        hidden: currentStep === 1 && newService,
        required: true,
        values: Object.values(Colors),
        valueToChange: 'color',
      },
    },
    {
      img: images.services.name,
      title: 'services.data.name',
      value: serviceInfoDetail.name,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        formControlName: 'name',
        hidden: currentStep === 1 && newService,
        required: true,
        valueToChange: 'name',
      },
    },
    {
      img: images.services.forward,
      title: 'services.data.forwardDirection',
      value: serviceInfoDetail.forwardDirection,
      required: false,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        hidden: currentStep === 1 && newService,
        required: false,
        valueToChange: 'forwardDirection',
      },
    },
    {
      img: images.services.backward,
      title: 'services.data.backwardsDirection',
      value: serviceInfoDetail.backwardsDirection,
      required: false,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        hidden: currentStep === 1 && newService,
        required: false,
        valueToChange: 'backwardsDirection',
      },
    },
    {
      img: images.services.criteria,
      title: 'services.data.stopsOrder',
      value: serviceInfoDetail.sortCriteria,
      required: false,
      expanded: false,
      color: true,
      edit: {
        id: serviceInfoDetail.sortCriteria,
        inputType: InputType.SELECT_WITH_ENUM,
        hidden: currentStep === 1 && newService,
        required: false,
        values: Object.values(SortCriteria),
        translateValue: true,
        valueToChange: 'sortCriteria',
      },
    },
    {
      img: images.services.showTimes,
      title: 'services.data.showTimes',
      value: getShowTimesValues(serviceInfoDetail, literalService),
      required: false,
      expanded: false,
      array: true,
      color: true,
      edit: {
        inputType: InputType.MULTISELECT_CHECKBOXES,
        hidden: currentStep === 1 && newService,
        required: false,
        values: getShowTimesValues(undefined, literalService),
        selectedValues: getShowTimesValues(serviceInfoDetail, literalService),
        translateValue: true,
        valueToChange: ['showRealTimes', 'showTheoreticalTimes'],
      },
    }
  ];
  return data;
}