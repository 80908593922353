import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { IAvailability, IBookingCreate, IReservationAvailability, IReservationAvailabilityFailure, IServiceInfo } from 'src/app/models';
import { LiteralService, UsersService } from 'src/app/services';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';
import {
  BookingsService,
} from 'src/app/services';

@Component({
  selector: 'app-booking-availabilities',
  templateUrl: './booking-availabilities.component.html',
  styleUrl: './booking-availabilities.component.scss'
})
export class BookingAvailabilitiesComponent implements OnInit, OnChanges {

  @Input() availabilities: any;
  @Input() userId: any;
  @Input() newBooking: IBookingCreate;
  @Input() serviceInfos: IServiceInfo[];
  @Input() loadingAvailabilities: boolean = true;
  @Input() availabilitiesSelected: any;
  @Input() allAvailabilitiesSelected: boolean;
  @Output() manual: EventEmitter<any> = new EventEmitter();
  @Output() book: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkAllAvailabilitiesSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextAvailability: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookMultiple: EventEmitter<any> = new EventEmitter<any>();
  @Output() showMaxReservationTimeModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  public images = images;
  public moment = moment;
  public TooltipPosition = TooltipPosition;

  public availabilitySelected: IReservationAvailability | IReservationAvailabilityFailure | null;

  public interval: any;
  public timeCounter = 0;

  constructor(
    public literalService: LiteralService,
    public bookingsService: BookingsService,
    public userService: UsersService) {

  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timeCounter++;
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.availabilities = changes['availabilities'].currentValue;
  }

  selectAvailability(availability: IReservationAvailability | IReservationAvailabilityFailure) {
    this.availabilitySelected = availability;
    this.nextAvailability.emit(availability);
    console.log(this.availabilitySelected)
  }

  checkAvailabilitySelected(availabilityId: number) {
    return this.availabilitiesSelected.find((availability: any) => availability.availabilityId === availabilityId);
  }

  isIReservationAvailability = (obj: any): obj is IReservationAvailability => 'optimal' in obj;

  async checkExceptions(eventEmitter: EventEmitter<any>) {
    const exceedsKmLimit = await this.bookingsService.isExceedingKmLimit(
      this.newBooking.targetUserId,
      this.availabilitySelected?.inStop.id,
      this.availabilitySelected?.outStop.id,
      this.availabilitySelected?.serviceId
    );
  
    const maxReservationTime = 
      this.availabilitySelected!.maxBookingTime > 0 && 
      this.timeCounter >= this.availabilitySelected!.maxBookingTime;
  
    if (maxReservationTime) {
      this.showMaxReservationTimeModalEventEmitter.emit();
    } else if (exceedsKmLimit) {
      this.showExceedingKmLimitModalEventEmitter.emit(this.availabilitySelected);
    } else if (this.isIReservationAvailability(this.availabilitySelected)) {
      eventEmitter.emit();
    }
  }
  
  async makeBooking() {
    await this.checkExceptions(this.book);
    this.availabilitySelected = null;
    this.availabilities = null;
  }
  
  async multipleBooking() {
    await this.checkExceptions(this.bookMultiple);
    this.availabilities = null;
  }

  checkIfAlternativeIsOnInitial(availability: IAvailability) {
    if (this.newBooking.originStopId !== availability.inStop.id) {
      return true;
    }
    return false;
  }

  checkIfAlternativeIsOnFinal(availability: IAvailability) {
    if (this.newBooking.destinationStops[0].exitStop.id !== availability.outStop.id) {
      return true;
    }
    return false;
  }

  getAlterationDestinationName(availability: IAvailability, initial: boolean = true) {
    if (this.checkIfAlternativeIsOnInitial(availability) && initial) {
      const origin = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.originStopId);
      return origin.label;
    }
    if (this.checkIfAlternativeIsOnFinal(availability) && !initial) {
      const destination = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.destinationStops[0].exitStop.id);
      return destination.label;
    }
    return null;
  }

  hasAlterations(serviceInfoId: number) {
    const serviceInfo = this.serviceInfos.find((serviceInfo: IServiceInfo) => serviceInfo.id === serviceInfoId);
    return serviceInfo ? serviceInfo.alterationMessages.length > 0 : false;
  }

  getAlterationMessageByService(serviceInfoId: number) {
    let messages = `<strong>${this.literalService.get('bookings.alteration', true)}:</strong></br>`;
    const serviceInfo = this.serviceInfos.find((serviceInfo: IServiceInfo) => serviceInfo.id === serviceInfoId);
    serviceInfo && serviceInfo.alterationMessages.forEach((message: string) => {
      messages += message;
    });
    return messages;
  }

  getVariabilityText(availabilityInOutStop: any) {
    if (availabilityInOutStop.minDateTime === availabilityInOutStop.maxDateTime) {
      return this.literalService.get('bookings.tooltips.withoutVariability', true);
    }
    return this.literalService.get('bookings.tooltips.variability', true) + ' <strong>' + moment(availabilityInOutStop.minDateTime).format('HH:mm') + ' - ' + moment(availabilityInOutStop.maxDateTime).format('HH:mm') + '</strong>';
  }

}
