import { Injectable } from '@angular/core';
import { StorageService, UsersService } from '../services';
import { initialUserState } from '../store/initialisation/users';
import { IUser } from 'src/app/models';
import { UserStatus, UserType } from 'src/app/models/enums';
import { objectToCamel } from 'ts-case-convert';
import { initialPaginationState } from '../store/initialisation/pagination';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UsersResolver {
  constructor(
    private usersService: UsersService,
    public storageService: StorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resolve(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    this.storageService.setRouteName('users')
    const page = route.data['users']?.pagination.actualPage || 1;
    const size = route.data['users']?.pagination.size || 10;
    const userSortType = await this.storageService.getUserSortType();
    const {
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
    } = await this.usersService.getUsers(userSortType, page, size);
    return this.getData(
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
      page,
    );
  }

  async getUsers(route: ActivatedRouteSnapshot, searchValue?: string) {
    const page = route.data['users']?.pagination.actualPage || 1;
    const size = route.data['users']?.pagination.size || 10;
    const userSortType = await this.storageService.getUserSortType();
    const {
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
    } = await this.usersService.getUsers(userSortType, page, size, searchValue);
    return this.getData(
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
      page,
    );
  }

  async filter(route: ActivatedRouteSnapshot, clientsSelected: number[], townsSelected: number[], typesSelected: UserType[], statusSelected: UserStatus[], searchValue?: string) {
    const page = route.data['users']?.pagination.actualPage || 1;
    const size = route.data['users']?.pagination.size || 10;
    const userSortType = await this.storageService.getUserSortType();
    const {
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
    } = await this.usersService.filter(userSortType, page, size, clientsSelected, townsSelected, typesSelected, statusSelected, searchValue);
    return this.getData(
      content,
      numberOfElements,
      totalElements,
      totalPages,
      first,
      last,
      page,
    );
  }

  getData = (
    content: any,
    number_of_elements: any,
    total_elements: any,
    total_pages: any,
    first: any,
    last: any,
    page: any,
  ) => {
    const data = initialUserState;
    data.value = objectToCamel(content) as Array<IUser>;
    data.isFetchingUsers = false;
    data.pagination = initialPaginationState;
    data.pagination.numberOfElements = number_of_elements;
    data.pagination.totalPages = total_pages;
    data.pagination.totalElements = total_elements;
    data.pagination.actualPage = page;
    data.pagination.first = first;
    data.pagination.last = last;
    return data;
  };
}
