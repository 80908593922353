<form #form novalidate (ngSubmit)="onSubmit()">
    <!-- TODO: Uncomment this when we use me/permissions  -->
    <!-- <div id="userDetailsContainer" class="container absolute top-20 right-0 hiddenDetails" [ngClass]="{ 'openDetails': user && !edit && roleService.permissions['USER']['READ'], 'openDetailsEdit': user && edit && roleService.permissions['USER']['UPDATE'], 'expandedDetails': expanded && user.type === userType.Regular && (adminRole === 'SUPERADMIN' || adminRole === 'ADMIN') && roleService.permissions['USER']['READ'], 'expandedDetailsDriver': expanded && ((user.type === userType.Driver && adminRole !== 'DRIVER_MANAGER') || adminRole === 'OPERATOR'), 'expandedSmallDetailsDriver': expanded && (user.type === userType.Driver && adminRole === 'DRIVER_MANAGER'), 'h-[70rem]': edit || newUser, 'h-[54rem]': !edit && !newUser }"> -->
    <div id="userDetailsContainer" class="container absolute top-20 right-0 hiddenDetails" [ngClass]="{ 'openDetails': user && !edit, 'openDetailsEdit': user && edit, 'expandedDetails': expanded && user.type === userType.Regular && (adminRole === 'SUPERADMIN' || adminRole === 'ADMIN'), 'expandedDetailsDriver': expanded && ((user.type === userType.Driver && adminRole !== 'DRIVER_MANAGER') || adminRole === 'OPERATOR'), 'expandedSmallDetailsDriver': expanded && (user.type === userType.Driver && adminRole === 'DRIVER_MANAGER'), 'h-[70rem]': edit || newUser, 'h-[57rem]': !edit && !newUser }">
        <section class="topbar h-14 mt-4 flex items-center">
            @if (user?.id && user.role) {
                <img [src]="(user.role === userRole.USER && user.type === userType.Regular) && images.roles.userRegular ||
                            user.role === userRole.ADMIN && images.roles.admin ||
                            user.role === userRole.SUPERADMIN && images.roles.superadmin ||
                            user.role === userRole.OPERATOR && images.roles.operator ||
                            (user.role === userRole.USER && user.type === userType.Driver) && images.roles.userDriver ||
                            user.role === userRole.DRIVER_MANAGER && images.roles.driverManager"
                            alt="userRole" class="h-6 w-6 ml-8" />
                <h1 class="ml-2 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('users.roles.' + user.role.toLowerCase(), true) }}</h1>
                <div class="flex items-center justify-center ml-2 mr-2" [ngClass]="{'cursor-pointer content-image': expanded && user.status !== 'Deleted'}">
                    <img [src]="user.status!.toUpperCase() === userStatus.ACTIVE && images.user.userActive ||
                            user.status!.toUpperCase() === userStatus.INACTIVE && images.user.userDisabled ||
                            user.status!.toUpperCase() === userStatus.DELETED && images.user.userDeleted ||
                            user.status!.toUpperCase() === userStatus.BLOCKED && images.user.userBlocked" 
                        alt="userStatus" class="h-6 w-6"
                        (click)="expanded && user.status !== 'Deleted' ? editStatusClick() : null"
                        [tooltip]="user.status!.toUpperCase() === userStatus.BLOCKED ? getPenalizedEndDate(user) : showDistanceTravelled(user)" [position]="TooltipPosition.BELOW"
                        [ngClass]="{'active': user.status!.toUpperCase() === userStatus.ACTIVE,
                            'disabled': user.status!.toUpperCase() === userStatus.INACTIVE,
                            'deleted': user.status!.toUpperCase() === userStatus.DELETED,
                            'blocked': user.status!.toUpperCase() === userStatus.BLOCKED}"/>
                </div>
                @if (editStatus) {
                    <app-select class="mt-0" [elements]="userStatusList" [value]="user.status" [id]="user.status!.toUpperCase()" [translateValue]="true" [haveImage]="true" (change)="changeStatus($event.target)" (onBlur)="changeStatus(null)" />
                }
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center content-image mr-2" (click)="copyLink(user.id)">
                        <img [tooltip]="literalService.get('copyLink', true)" [position]="TooltipPosition.BELOW" [src]="images.link" alt="copy" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div> 
                    <div class="flex items-center justify-center content-image mr-2" (click)="showViewBookingsEventEmitter.emit(user.id)">
                        <img [tooltip]="literalService.get('users.tooltips.bookings', true)" [position]="TooltipPosition.BELOW" [src]="images.user.calendar" alt="calendar" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div> 
                    @if (user?.type !== userType.Driver) {
                        <div class="flex items-center justify-center content-image mr-2" (click)="showFavourites.emit()">
                            <img [tooltip]="literalService.get('users.tooltips.favoriteRoutes', true)" [position]="TooltipPosition.BELOW" [src]="images.user.star" alt="favorite" class="h-6 w-6 mr-4 ml-4 cursor-pointer">
                        </div>
                    }
                    <!-- TODO: Uncomment this when we use me/permissions  -->
                    <!-- @if (expanded && roleService.permissions['USER']['UPDATE']) { -->
                    @if (expanded) {
                        @if (user?.type === userType.Regular) {
                            <!-- TODO: Uncomment this when we use me/permissions  -->
                            <!-- <div class="flex items-center justify-center content-image mr-2" [hidden]="!roleService.permissions['USER']['UPDATE']"> -->
                            <div class="flex items-center justify-center content-image mr-2">
                                <!-- TODO: Uncomment this when we use me/permissions  -->
                                <!-- <img [hidden]="!roleService.permissions['USER']['UPDATE']" [tooltip]="literalService.get('users.tooltips.document', true)" [position]="TooltipPosition.BELOW" [src]="images.document" alt="editDocuments" class="h-6 w-6 mr-4 ml-4 cursor-pointer" (click)="showDocumentsModalEventEmitter.emit(user.id)" /> -->
                                <img [tooltip]="literalService.get('users.tooltips.document', true)" [position]="TooltipPosition.BELOW" [src]="images.document" alt="editDocuments" class="h-6 w-6 mr-4 ml-4 cursor-pointer" (click)="showDocumentsModalEventEmitter.emit(user.id)" />
                            </div>
                        }
                        <!-- TODO: Uncomment this when we use me/permissions  -->
                        <!-- <div class="flex items-center justify-center content-image mr-2" [hidden]="!roleService.permissions['USER']['UPDATE']"> -->
                        <div class="flex items-center justify-center content-image mr-2">
                            <!-- TODO: Uncomment this when we use me/permissions  -->
                            <!-- <img [hidden]="!roleService.permissions['USER']['UPDATE']" [tooltip]="literalService.get('users.tooltips.edit', true)" [position]="TooltipPosition.BELOW" [src]="images.user.edit" alt="editUser" class="h-6 w-6 mr-4 ml-4 cursor-pointer" (click)="editAction()" /> -->
                            <img [tooltip]="literalService.get('users.tooltips.edit', true)" [position]="TooltipPosition.BELOW" [src]="images.user.edit" alt="editUser" class="h-6 w-6 mr-4 ml-4 cursor-pointer" (click)="editAction()" />
                        </div>
                    }
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [tooltip]="literalService.get('closeWindow', true)" [position]="TooltipPosition.BELOW" [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            } @else if (!user?.id) {
                @if (user?.type === userType.Regular) {
                    <h1 class="ml-8 text-xl font-[600] max-w-[40%] truncate">{{ literalService.get('users.newUser', true) }}</h1>
                } @else {
                    <h1 class="ml-8 text-xl font-[600] max-w-[40%] truncate">{{ literalService.get('users.newDriver', true) }}</h1>
                }
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6 cursor-pointer" (click)="close()" />
                    </div>
                </section>
            } 
        </section>     
        <section class="h-14 mt-8 ml-8 mr-20 items-center">
            <div class="grid grid-rows-7 grid-flow-col gap-5" [ngClass]="{'grid-rows-7': user && user.type === userType.Regular, 'grid-rows-5': user && user.type === userType.Driver}">
                <ng-container>
                @if (!edit && !newUser) {
                    <article *ngFor="let element of userForm" [ngClass]="{'hidden': element.hidden}">
                        @if (expanded ? true : !element.expanded) {
                            <app-detail [data]="element" [value]="element.value" />
                        }
                    </article>
                } @else if (edit || newUser) {
                    <article *ngFor="let element of userForm" [ngClass]="{'hidden': element.hidden}">
                        @if (expanded && element.edit ? true : !element?.expanded) {
                            <app-input  [data]="element"
                                        [edit]="true"
                                        [independent]="false"
                                        [translateValue]="element.edit?.translateValue"
                                        [hasErrors]="invalid.includes(element.edit?.valueToChange)"
                                        [error]="showError(element.value, element.edit?.valueToChange)"
                                        (changeEventEmitter)="utilsService.isArray(element.edit?.valueToChange) || element.edit?.valueToChange === 'clients' ? changeData($event.target, element) : user[element.edit?.valueToChange] = changeData($event.target, element)" />
                        }
                    </article>
                }
                </ng-container>
                <section class="flex items-center absolute right-5 top-0 bottom-0">
                    <img alt="arrowToExpand" [src]="images.arrowLeft" class="h-5 w-5 cursor-pointer expand" [ngClass]="{'actionRotateArrowOpen': !expanded, 'actionRotateArrowClose': expanded, 'filter-red': isExpandedFormInvalid(invalid)}" (click)="expanded = !expanded" />
                </section>
            </div>
            <!-- TODO: Uncomment this when we use me/permissions  -->
            <!-- <app-modal-change-password [id]="'changePasswordModal'" [modal]="changePasswordModal" [password]="password" [confirmPassword]="confirmPassword" [hidden]="!changePasswordShowed && !roleService.permissions['USER']['UPDATE']" [loading]="changingPassword" (action)="changePassword($event)" /> -->
            <app-modal-change-password [id]="'changePasswordModal'" [modal]="changePasswordModal" [password]="password" [confirmPassword]="confirmPassword" [hidden]="!changePasswordShowed" [loading]="changingPassword" (action)="changePassword($event)" />
            <!-- TODO: Uncomment this when we use me/permissions  -->
            <!-- <app-modal-add-to-app [id]="'addToAppModal'" [modal]="addToAppModal" [hidden]="!addToAppShowed && !roleService.permissions['USER']['UPDATE']" [values]="apps" [text]="'selectAppsToAdd'" (action)="addToApp($event)" /> -->
            <app-modal-add-to-app [id]="'addToAppModal'" [modal]="addToAppModal" [hidden]="!addToAppShowed" [values]="apps" [text]="'selectAppsToAdd'" (action)="addToApp($event)" />
            <div class="flex w-full mt-7" [ngClass]="{'ml-auto': expanded}">
                <!-- TODO: Uncomment this when we use me/permissions  -->
                <!-- @if (expanded && !edit && !newUser && roleService.permissions['USER']['UPDATE']) { -->
                @if (expanded && !edit && !newUser && adminRole !== userRole.OPERATOR && adminRole !== userRole.USER) {
                    <button id="changePassword" type="button" [ngClass]="{ 'modalOpened': changePasswordShowed }" class="flex ml-auto mr-6 password-btn font-semibold" data-modal-target="changePasswordModal" data-modal-toggle="changePasswordModal" (click)="showChangePassword()">
                        <img  [src]="images.user.password" alt="password" class="h-5 w-5 cursor-pointer" />
                        {{ literalService.get('users.data.updatePassword', true) }}
                    </button>
                }
                @if (user && user.type === userType.Regular && expanded && !edit && !newUser) {
                    <button id="addToApp" type="button" [ngClass]="{ 'modalOpened': addToAppShowed, 'ml-auto': adminRole === userRole.OPERATOR || adminRole === userRole.USER}" data-modal-target="addToAppModal" data-modal-toggle="addToAppModal" class="flex add-app-btn font-semibold" (click)="showAddToApp()">
                        <img  [src]="images.user.smartPhone" alt="addToApp" class="h-5 w-5 cursor-pointer" />
                        {{ literalService.get('users.data.addToApp', true) }}
                    </button>
                }
                @if (user && user.type === userType.Regular && (edit || newUser)) {
                    <div class="flex" [ngClass]="{'flex-row-reverse': expanded, 'flex-col': !expanded}">
                        <div class="flex">
                            <div class="flex w-72 mb-3 gap-2 privacy-policy-btn font-semibold justify-center items-center text-start" [ngClass]="{'ml-6': expanded}">
                                <img [src]="images.user.privacyPolicy" alt="privacyPolicy" class="h-5 w-5 cursor-pointer " />
                                <div>
                                    <a [href]="getPrivacyLink()" target="_blank">
                                        <label class="link">{{ literalService.get('users.data.privacyPolicy', true) }}</label>
                                    </a>
                                    <label>
                                         {{ literalService.get('users.data.and', false) }} 
                                    </label>
                                    <a [href]="getTermsLink()" target="_blank">
                                        <label class="link">{{ literalService.get('users.data.terms') }}</label>
                                    </a>
                                </div>
                                <input type="checkbox" class="ml-2 cursor-pointer" [checked]="user.policyAccepted" (change)="user.policyAccepted = !user.policyAccepted" />
                            </div>
                        </div>
                        <div class="mt-2 flex">
                            <input type="submit" class="primary text-white gap-4 border-2 font-semibold h-14 w-[7.5rem] uppercase mr-2.5 cursor-pointer" [value]="literalService.get('save', true)" [disabled]="disabledButton()" />
                            <input type="button" class="secondary gap-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer" [value]="literalService.get('cancel', true)" (click)="newUser ? close() : edit = !edit"  />
                        </div>
                    </div>
                }
            </div>   
            @if (user && !edit && !newUser) {
                <div class="flex flex-row gap-6">
                    @if (user && user.type === userType.Regular) {
                        <div class="w-72 flex flex-row justify-center items-center gap-2 primary-purple-light-text mt-5">
                            <img [src]="images.user.privacyPolicy" alt="privacyPolicy" class="h-5 w-5 imgColor" />
                            <div>
                                <a [href]="getPrivacyLink()" target="_blank">
                                    <label class="link">{{ literalService.get('users.data.privacyPolicy', true) }}</label>
                                </a>
                                 {{ literalService.get('users.data.and', false) }} 
                                <a [href]="getTermsLink()" target="_blank">
                                    <label class="link">{{ literalService.get('users.data.terms') }}</label>
                                </a>
                                <label> {{ literalService.get('privacypolicy.' + user.policyAccepted, false) }} </label>
                            </div>
                        </div>
                    }
                    <div *ngIf="expanded" class="flex flex-row justify-center items-center gap-2 mt-5">
                        <img [src]="images.user.changelog" alt="changelog" class="h-5 w-5 imgColor" />
                        <div>
                            @if (user.creatorName || user.createdAt) {
                                <p class="changelog primary-purple-light-text font-light text-sm">{{ literalService.get('created', true) }}
                                    @if (user.creatorName) {
                                        <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ user.creatorName }}</span>
                                    }
                                    {{ literalService.get('at') }} {{ utilsService.convertDate(user.createdAt) | date: 'dd/MM/yyyy HH:mm' }}
                                </p>
                            }
                            @if (user.modifierName || user.lastModifiedAt) {
                                <p class="changelog primary-purple-light-text font-light text-sm">{{ literalService.get('lastModification', true) }}
                                    @if (user.modifierName) {
                                        <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ user.modifierName }}</span>
                                    }
                                     {{ literalService.get('at') }} {{ utilsService.convertDate(user.lastModifiedAt) | date: 'dd/MM/yyyy HH:mm' }}
                                </p>
                            }
                        </div>
                    </div>
                </div>
            }
            @if (user && user.type === userType.Driver) {
                <div class="flex">
                    <div *ngIf="edit || newUser">
                        <div class="mt-2 flex">
                            <input type="submit" class="primary text-white gap-4 border-2 font-semibold h-14 w-[7.5rem] uppercase mr-2.5 cursor-pointer" [value]="literalService.get('save', true)" />
                            <input type="button" class="secondary gap-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer" [value]="literalService.get('cancel', true)" (click)="newUser ? close() : edit = !edit" />
                        </div>
                    </div>
                </div>
            }
        </section>
    </div>
</form>

