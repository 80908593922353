import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APP } from '../../config/app';
import { HttpMethods, NotificationTypes } from 'src/app/models/enums';
import { NotificationService, StorageService } from 'src/app/services';
import { images } from 'src/images';
import { ErrorsCode } from 'src/app/models/enums/errorsCode';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (
            request.method === HttpMethods.POST ||
            request.method === HttpMethods.PUT ||
            request.method === HttpMethods.PATCH ||
            request.method === HttpMethods.DELETE
          ) {
            console.log(request);
          }
          this.shouldUpdateState(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0 || error.status === 401) {
          this.router.navigate(['/'], {replaceUrl: true});
          this.storageService.removeToken();
          this.storageService.removePermissions();
          return throwError(() => new Error(error.message));
        }
        this.shouldUpdateState(error);
        console.log("ERROR", error, error.status, error.error);
        let errorTitle;
        let errorMessage;
        let errorType;
        if ( error.error && (
          error.error.error_code === ErrorsCode.ENTITY_DOES_NOT_EXIST ||
          error.error.error_code === ErrorsCode.ENTITY_ALREADY_EXISTS ||
          error.error.error_code === ErrorsCode.INVALID_REQUEST || 
          error.error.error_code === ErrorsCode.NO_AVAILABILITIES_FOUND || 
          error.error.error_code === ErrorsCode.TICKET_CREATION || 
          error.error.error_code === ErrorsCode.PAYMENT || 
          error.error.error_code === ErrorsCode.MAX_CAPACITY || 
          error.error.error_code === ErrorsCode.UNFEASIBLE_BOOKING || 
          error.error.error_code === ErrorsCode.INVALID_TRIP_STATUS || 
          error.error.error_code === ErrorsCode.DATABASE_CONFLICT
        ))
        {
          errorTitle = 'errorOccurred';
          errorMessage = error.error.error_code;
          errorType = error.error.type;
        } else  {
          errorTitle = 'errorOccurred';
          errorMessage = error.status;
        }
        if (error.error.error_code !== ErrorsCode.PERMISSION_DENIED) {
          this.notificationService.image = images.error;
          this.notificationService.title = errorMessage.toString(); // TODO: should be improved and swapped with title
          this.notificationService.message = errorTitle!;
          this.notificationService.translate = true;
          this.notificationService.typeError = errorType;
          this.notificationService.show(NotificationTypes.DANGER);
        }
        return throwError(() => new Error(error.message));
      }),
    );
  }

  private shouldUpdateState(event: any) {
    let authHeader = undefined;
    if (event.headers instanceof HttpHeaders) {
      authHeader = event.headers.get(APP.HEADER_RESPONSE_TOKEN_AUTH_KEY);
    }

    if (authHeader) {
      const tokenType = authHeader.split(' ')[0];
      const token = authHeader.split(' ')[1];
      this.authService.setToken(token).setTokenType(tokenType);
      this.authService.setRole();
    }

    return this;
  }
}
