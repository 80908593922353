import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() elements?: any[] = [];
  @Input() image: boolean = false;
  @Input() value?: string;
  @Input() id?: any;
  @Input() disabled?: boolean = false;
  @Input() required?: boolean = false;
  @Input() isSimple?: boolean = false;
  @Input() translateValue?: boolean = false;
  @Input() haveImage?: boolean = false;
  @Input() hasErrors?: boolean = false;
  @Input() error?: string = '';
  @Input() valueToShow?: string[];
  @Input() placeholder?: string;
  @Output() changeEventEmitter = new EventEmitter<unknown>();
  @Output() onBlur = new EventEmitter<any>();

  public options: any[] = [];
  public selected = '';

  public itemSelected = '';

  public images = images;

  constructor(
    public literalService: LiteralService,
    public componentElement: ElementRef
  ) {
    this.elements?.map((element: any) => {
      element.name = this.literalService.get(element.name, true);
      this.options.push(element.id);
    });
  }

  @HostListener('focusout')
  onFocusOut() {
    this.onBlur.emit();
  }

  ngOnInit(): void {
    this.itemSelected = this.id || (this.elements?.at(0) && this.elements?.at(0).id);
    this.elements?.map((element: any) => {
      this.options.push(element.id);
    });
    this.selected = this.options.find((option: string) => {
      return this.value && this.value === option;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
    if (this.value) {
      const test = this.componentElement.nativeElement;
      const selectElement = test.children[0];
      selectElement.focus();
    }
  }

  getValueToShow = (item: any) => {
    let text = '';
    this.valueToShow &&
      this.valueToShow.map((res) => (text += item[res] + ' '));
    // console.log(this.valueToShow ? text : item.name)
    return this.valueToShow ? text : item.name;
  };
}
