import { Injectable } from '@angular/core';
import { BookingsService, StorageService } from '../services';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { initialBookingsState } from '../store/initialisation/bookings';
import { objectToCamel } from 'ts-case-convert';
import { IBooking, IBase } from 'src/app/models';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { initialPaginationState } from '../store/initialisation/pagination';

@Injectable({
  providedIn: 'root',
})
export class BookingsResolver {
  constructor(
    private bookingsService: BookingsService,
    public storageService: StorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resolve(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    this.storageService.setRouteName('bookings')
    const page = route.data['bookings']?.pagination.actualPage || 1;
    const size = route.data['bookings']?.pagination.size || 10;
    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.bookingsService.getBookings(size, page);

    return this.storageService.getUserSelected().then(
      (userId) => {
        userId 
          ? this.getFilteredData(
              content,
              first,
              last,
              size,
              userId
            )
          : this.getData(
              content,
              number_of_elements,
              total_elements,
              total_pages,
              first,
              last,
              page,
            );
      }
    );
  }

  async getBookings(
    route: ActivatedRouteSnapshot,
    expeditionClients: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    cancelled: boolean,
    searchValue?: string
  ) {
    const page = route.data['bookings']?.pagination.actualPage || 1;
    const size = route.data['bookings']?.pagination.number_of_elements || 10;
    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.bookingsService.getBookingsFiltered(size, page, expeditionClients, expeditionTowns, expeditionTypes, expeditionStatus, cancelled, searchValue);
    return this.getData(
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
      page,
    );
  }


  getFilteredData(content: any, first: any, last: any, size: number, userId: any) {
    this.storageService.removeUserSelected();
    const data = initialBookingsState;
    const newValues = objectToCamel(content) as Array<IBooking>;
    data.value = newValues.filter((value) => value.userId === Number(userId));
    data.isFetchingBookings = false;
    data.pagination = initialPaginationState;
    data.pagination.numberOfElements = data.value.length;
    data.pagination.totalPages = Math.ceil(data.value.length / size);
    data.pagination.totalElements = data.value.length;
    data.pagination.actualPage = 1;
    data.pagination.first = first;
    data.pagination.last = last;
    return data;
  }

  getData = (
    content: any,
    number_of_elements: any,
    total_elements: any,
    total_pages: any,
    first: any,
    last: any,
    page: any,
  ) => {
    const data = initialBookingsState;
    data.value = objectToCamel(content) as Array<IBooking>;
    data.isFetchingBookings = false;
    data.pagination = initialPaginationState;
    data.pagination.numberOfElements = number_of_elements;
    data.pagination.totalPages = total_pages;
    data.pagination.totalElements = total_elements;
    data.pagination.actualPage = page;
    data.pagination.first = first;
    data.pagination.last = last;
    return data;
  };
}
