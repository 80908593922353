<div class="flex h-screen w-screen">
  <!-- <p class="dark:text-white">sign-in works!</p> -->
  <!-- <app-dark-mode></app-dark-mode> -->
   <div class="flex w-[60%] justify-center items-center relative">
    <div class="">
      <img [src]="images.landscape" alt="landscape" class="w-screen p-32" />
    </div>
    <label class="font-normal text-xs neutrals-grey-03-text absolute bottom-0 right-0 p-2">Image designed by Freepik from Storyset</label>
  </div>
  <div class="flex flex-col w-[40%] h-full justify-center items-center neutrals-grey-01-background">
    <img [src]="images.logos.backofficeLogo" alt="logo" class="rounded-[32px]" />
    <label class="font-bold text-[40px] mt-5">BackOffice</label>
    <form #form novalidate (ngSubmit)="onSubmit()">
      <div class="relative mb-4 mt-5">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <img id="username" [src]="images.login.username" class="w-6" />
        </div>
        <input type="text" id="usr" [placeholder]="literalService.get('login.username', true)" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" class="block pl-12 w-96 h-12 px-3 py-2 bg-white border rounded-s rounded-e text-sm placeholder-slate-400 focus:outline-none" />
      </div>
      <div class="relative mb-6">
        <div class="absolute z-10 inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <img id="password" [src]="images.login.password" class="w-6" />
        </div>
        <app-input-password [id]="'password'" class="w-6" [placeholder]="literalService.get('login.password', true)" (changeEventEmitter)="setPassword($event)" [hasIcon]="true" required />
      </div>
      <button type="submit" [disabled]="doingLogin" class="primary cursor-pointer text-white font-semibold uppercase w-96 h-14">
        @if (doingLogin) {
          <ng-lottie [options]="loadingLogin" (animationCreated)="animationCreated($event)" />
        } @else {
          {{ literalService.get('login.login', true) }}
        }
      </button>
    </form>
  </div>
</div>
