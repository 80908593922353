import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { UserSortType, UserType, UserStatus } from 'src/app/models/enums';
import { IUser, IUserDriver, IUserRegular } from 'src/app/models';
import { objectToSnake } from 'ts-case-convert';
import { IDocumentCreate } from 'src/app/models/documents/documentCreate';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService extends BaseHttpService {
  private baseURL = `users`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public createUser(user: IUser) {
    console.log('createUser');
    let body = objectToSnake(
      { 
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        language: user.language,
        phone: user.phone,
        additionalPhone: user.additionalPhone,
        clients: user.clients,
        town: user.town,
        role: user.role,
        status: user.status,
        type: user.type,
        createdAt: user.createdAt,
        modifierName: user.modifierName,
        lastModifiedAt: user.lastModifiedAt,
      }
    );
    body = user.type == UserType.Driver ? this.extraPropertiesUserDriver(user as IUserDriver, body) : this.extraPropertiesUserRegular(user as IUserRegular, body);
    const path = `/${user.type?.toLowerCase()}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.create(body, url);
  }

  private extraPropertiesUserDriver(user: IUserDriver, body: any) {
    return { 
      ...body,
      operator: user.operator,
    };
  }
  
  private extraPropertiesUserRegular(user: IUserRegular, body: any) {
    return objectToSnake({ 
      ...body,
      dni: user.dni,
      sms: user.sms,
      push: user.push,
      sendEmail: user.sendEmail,
      settlement: user.settlement,
      policyAccepted: user.policyAccepted,
      birthDate: user.birthDate,
      gender: user.gender,
      prm: user.prm,
    });
  }

  public editUser(user: IUser) {
    const path = `/${user.type?.toLowerCase()}/${user.id}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.update(user, url);
  }

  public getUser(userType: UserType, userId: number) {
    const path = `/${userType.toString().toLowerCase()}/${userId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getUsers(
    page: number = 1,
    size: number = 10,
    sort: UserSortType = UserSortType.CREATION,
    searchValue?: string,
    type?: UserType,
  ) {
    let path = `?page=${page}&size=${size}&sorting=${sort}`;
    path += type ? `&type=${type}` : '';
    path +=
      searchValue
        ? `&value=${searchValue}`
        : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public filter(
    page: number = 1,
    size: number = 10,
    sort: UserSortType = UserSortType.CREATION,
    clientsSelected: number[], 
    townsSelected: number[], 
    typesSelected: UserType[], 
    statusSelected: UserStatus[], 
    searchValue?: string
  ) {
    let path = `/filter?page=${page}&size=${size}&sorting=${sort}`;
    if (clientsSelected.length > 0) {
      const clientIds = clientsSelected.map(client => client).join(',');
      path += `&clients=${clientIds}`;
    }
    if (townsSelected.length > 0) {
      const townsIds = townsSelected.map(town => town).join(',');
      path += `&towns=${townsIds}`;
    }
    if (typesSelected.length > 0) {
      const types = typesSelected.map(types => types).join(',');
      path += `&types=${types}`;
    }
    if (statusSelected.length > 0) {
      const status = statusSelected.map(status => status).join(',');
      path += `&status=${status}`;
    }
    path +=
      searchValue
        ? `&search=${searchValue}`
        : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getPossibleClientTowns() {
    const path = `/me/client-towns`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getOperators() {
    const path = `/me/operators`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getLoggedUserTown() {
    const path = `/me/town`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getVehicles(tripId: number) {
    const path = `/me/vehicles?trip_id=${tripId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getDrivers(tripId: number) {
    const path = `/me/drivers?trip_id=${tripId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getTownsByClient(clients: number[]) {
    const path = `/towns-by-client?client_ids=${clients}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getOperatorsByClient(clients: number[]) {
    const path = `/operators-by-client?client_ids=${clients}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
  
  public getDocumentDownload(userId: number, documentId: number) {
    const path = `/${userId}/document/${documentId}/download`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.readBlob(url);
  }

  public getTownsByUser(userId: number) {
    const path = `/${userId}/towns`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
  
  public getUsersApps(userId: number) {
    const path = `/${userId}/apps`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public changePassword(userId: number, password: string) {
    const path = `/${userId}/password`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    const body = { password };
    return this.updatePatch(body, url);
  }

  public changeStatus(user: IUser, status: string, penaltyEndDate?: Date, penaltyReason?: string) {
    const path = `/${user.type?.toLowerCase()}/${user.id}/status`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    const body = 
    { 
      'status': status, 
      'penalty_end_date': penaltyEndDate, 
      'comment': penaltyReason 
    };
    return this.updatePatch(body, url);
  }

  public addToApp(userId: number, appId: number) {
    const path = `/${userId}/app-register`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    const body = { apps_ids_to_register: [appId] };
    return this.create(body, url);
  }

  public getBookingsByUser(userId: number) {
    const path = `/${userId}/bookings`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
  
  public deleteDocument(documentId: number, userId: number) {
    const path = `/${userId}/document/${documentId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.delete(url);
  }

  public createDocuments(userId: number, documents : IDocumentCreate[]) {
    const path = `/${userId}/documents`;
    const body: any =  [];
    documents.forEach(doc => {
      body.push({
        base64: doc.base64,
        file_name: doc.fileName,
        type: doc.type
      });
    });
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.create(body, url);
  }
}
