import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BookingSeatsComponent } from 'src/app/components/booking/booking-seats/booking-seats.component';
import { ServiceTagComponent } from 'src/app/components/service-tag/service-tag.component';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { images } from 'src/images';
import { TooltipPosition } from '../tooltip/tooltip-position';

@Component({
  selector: 'app-editable-detail',
  templateUrl: './editable-detail.component.html',
  styleUrls: ['./editable-detail.component.scss'],
})
export class EditableDetailComponent implements OnInit {
  @Input() data: any;
  @Input() value?: any;
  @Input() isHoveringOriginDestinationDetail: boolean;
  @Output() action = new EventEmitter<any>();
  @Output() setIsHoveringOriginDestination = new EventEmitter<any>();


  public images = images;
  public textValues = '';
  public text = '';
  public isHovering = false;
  public isHoveringOriginOrDestination = false;

  public TooltipPosition = TooltipPosition;
  public InputType = InputType;
  public ServiceTagComponent = ServiceTagComponent;
  public BookingSeatsComponent = BookingSeatsComponent;

  constructor(
    public literalService: LiteralService,
    public utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.getText();
  }

  getText() {
    this.text = this.data.value;
  }

  onMouseEnter(event: MouseEvent) {
    const hoveredElement = event.target as HTMLElement;
    if (hoveredElement.id === "bookings.data.destinationStop" || hoveredElement.id === "bookings.data.originStop") {
      this.setIsHoveringOriginDestination.emit(true);
    }
    else {
      this.isHovering = true;
    }
  }

  onMouseLeave(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (relatedTarget && !target.contains(relatedTarget)) {
      if (target.id === "bookings.data.destinationStop" || target.id === "bookings.data.originStop") {
        this.setIsHoveringOriginDestination.emit(false);
      }
      this.isHovering = false;
    }
  }

  onClick() {
    this.action.emit();
  }
}
