<section class="flex justify-between w-full">
    <article>
        <div class="flex">
            <img [src]="images.pax" alt="pax" class="h-5 w-5 mr-2" />
            <label>{{ literalService.get('bookings.data.standard', true) }}</label>
        </div>
        <div class="flex justify-center items-center mt-3">
            <img [src]="images.minus" alt="minusPax" class="h-6 w-6 mr-1 cursor-pointer" [ngClass]="{'disabled': pax === 0}" (click)="pax! > 0 && changeEventEmitter.emit({ key:'minus', type:'pax' })" />
            <input id="pax" type="text" [(ngModel)]="pax" [ngModelOptions]="{standalone: true}" (change)="changeEventEmitter.emit({ key: 'value', value: pax, type: 'pax' })" class="block w-12 h-10 text-center bg-white border rounded-s rounded-e text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none" />
            <img [src]="images.plus" alt="plusPax" class="h-6 w-6 ml-1 cursor-pointer" (click)="changeEventEmitter.emit({ key: 'plus', type: 'pax' })" />
        </div>
    </article>
    <article>
        <div class="flex">
            <img [src]="images.prm" alt="prm" class="h-5 w-5 mr-2" />
            <label class="truncate">{{ literalService.get('bookings.data.reducedMovement', true) }}</label>
        </div>
        <div class="flex justify-center items-center mt-3">
            <img [src]="images.minus" alt="minusPrm" class="h-6 w-6 cursor-pointer mr-1" [ngClass]="{'disabled': prm === 0}" (click)="prm! > 0 && changeEventEmitter.emit({ key:'minus', type:'prm' })" />
            <input id="prm" type="text" [(ngModel)]="prm" [ngModelOptions]="{standalone: true}" (change)="changeEventEmitter.emit({ key: 'value', value: prm, type: 'prm' })" class="block w-12 h-10 text-center bg-white border rounded-s rounded-e text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none" />
            <img [src]="images.plus" alt="plusPrm" class="h-6 w-6 cursor-pointer ml-1" (click)="changeEventEmitter.emit({ key: 'plus', type: 'prm' })" />
        </div>
    </article>
</section>
