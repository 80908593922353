<section>
    <article class="flex justify-center items-center" [ngClass]="{'cancelled': booking.cancelled}">
        <img [src]="images.pax" alt="pax" class="h-6 w-6">
        <label class="ml-2"[ngClass]="{'neutrals-grey-04-text': booking.cancelled}" >{{ booking.seats }}</label>
    </article>
    <article class="flex justify-center items-center" [ngClass]="{'cancelled': booking.cancelled}">
        <img [src]="images.prm" alt="prm" class="h-6 w-6">
        <label class="ml-2" [ngClass]="{'neutrals-grey-04-text': booking.cancelled}" >{{ booking.prmSeats }}</label>
    </article>
</section>
