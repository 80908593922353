import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocument } from 'src/app/models/documents/document';
import { IDocumentCreate } from 'src/app/models/documents/documentCreate';
import { UsersService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-document-tag',
  templateUrl: './document-tag.component.html',
  styleUrl: './document-tag.component.scss'
})
export class DocumentTagComponent {

  @Input() document: IDocument | IDocumentCreate;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<any> = new EventEmitter();


  constructor(private userService: UsersService) {}

  public images = images;

  async onDelete (event: MouseEvent) {
    event.stopPropagation();
    this.delete.emit(this.document);
  }
  
  onClick () {
    this.download.emit(this.document);
  }
   
  isDocumentCreateRequest(): boolean {
    return 'base64' in this.document;
  }
}
