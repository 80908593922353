import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrl: './modal-confirmation.component.scss',
})
export class ModalConfirmationComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() icon: string | null;
  @Input() title: string;
  @Input() text: string;
  @Input() sameLineButtons: boolean;
  @Input() width: string;
  @Input() centerText: boolean = false;
  @Output() action = new EventEmitter<any>();

  public images = images;

  constructor(public literalService: LiteralService) {}
}
