import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  IBooking,
  ITrip,
  ITripDetail,
  IUserDriver,
  IVehicle,
  IBase,
} from 'src/app/models';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import {
  StorageService,
  TripsService,
  UsersService,
  UtilsService,
  RoleService,
} from 'src/app/services';
import { LanguageService } from 'src/app/services/language/language.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { initialTripsState } from 'src/app/store/initialisation/trips';
import { images } from 'src/images';

@Component({
  selector: 'app-trip-modal',
  templateUrl: './trip-modal.component.html',
  styleUrl: './trip-modal.component.scss',
})
export class TripModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() trips = initialTripsState;
  @Input() showTripDetails: boolean;
  @Input() selectedTrip: ITrip;
  @Input() tripDetails: ITripDetail;
  @Input() percentages: any[];
  @Input() searching: boolean = false;
  @Output() getTripEventEmitter = new EventEmitter<number>();
  @Output() refreshTripsEventEmitter = new EventEmitter();
  @Output() clearMap = new EventEmitter();
  @Output() showChangeDriverEventEmitter = new EventEmitter();
  @Output() showChangeVehicleEventEmitter = new EventEmitter();
  @Output() showViewBookingsEventEmitter = new EventEmitter();
  @Output() showViewPickUpsEventEmitter = new EventEmitter();
  @Output() showViewDropOffsEventEmitter = new EventEmitter();
  @Output() showViewChangeOversEventEmitter = new EventEmitter();
  @Output() showAddPaxEventEmitter = new EventEmitter();
  @Output() showSetReadyEventEmitter = new EventEmitter();
  @Output() showSetClosedEventEmitter = new EventEmitter();
  @Output() showDeleteTripEventEmitter = new EventEmitter();

  public images = images;
  public language: string = 'es';
  public expeditionStatus = ExpeditionStatus;
  public clientsSelected: IBase[] = [];
  public operatorsSelected: IBase[] = [];
  //public townsSelected: IBase[] = [];
  public typesSelected: ExpeditionType[] = [];
  public statusSelected: ExpeditionStatus[] = [];
  public searchValue: string;

  public bookings: IBooking[];
  public vehicles: IVehicle[];
  public drivers: IUserDriver[];

  private languageSubscription: Subscription;

  public TooltipPosition = TooltipPosition;

  public interval: any;

  constructor(
    public literalService: LiteralService,
    public utilsService: UtilsService,
    public languageService: LanguageService,
    public tripsService: TripsService,
    public usersService: UsersService,
    public storageService: StorageService,
    public activatedRoute: ActivatedRoute,
    public roleService: RoleService
  ) {}

  async ngOnInit() {
    console.log(this.selectedTrip, this.tripDetails);
    this.languageSubscription = this.languageService.language$.subscribe(
      (language: string) => {
        this.language = language;
      },
    );

    this.interval = setInterval(() => {
      this.getTrips();
    }, 60000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    console.log(this.tripDetails, changes);
    console.log(this.selectedTrip, this.tripDetails);
    // this.percentages = []
    // this.tripDetails.stops.map((stop: ITripStop, index: number) => {
    //   this.percentages.push(this.getPercentage(index, this.tripDetails.stops))
    // })
    // console.log(this.percentages)
  }

  async getTrips() {
    this.trips.pagination.numberOfElements = 8;
    this.refreshTripsEventEmitter.emit({
      snapshot: this.activatedRoute.snapshot,
      clientsSelected: this.clientsSelected,
      operatorsSelected: this.operatorsSelected,
      typesSelected: this.typesSelected,
      statusSelected: this.statusSelected,
      searchValue: this.searchValue
    });
  }

  async changePage(event: { isNext: boolean, specificPage?: number }) {
    this.trips = initialTripsState;
    this.trips.value = [];
    if (!event.specificPage) {
      this.trips.pagination.actualPage = event.isNext
        ? this.trips.pagination.actualPage + 1
        : this.trips.pagination.actualPage - 1;
    } else {
      this.trips.pagination.actualPage = event.specificPage;
    }
    await this.getTrips();
    this.searching = false;
  }

  getTripDetails = (event: any) => {
    this.showTripDetails ? this.goBack() : this.getTripEventEmitter.emit(event);
  };

  // getPercentage = (index: number, stops: ITripStop[]) => {
  //   if ((index * (100 / (stops.length - 1))) > 0 && (index * (100 / (stops.length - 1))) < 100) {
  //     const hasDecimals = this.utilsService.numberHasDecimals((index * (100 / (stops.length - 1))))
  //     const percentage = hasDecimals ? (index * (100 / (stops.length - 1))).toFixed(2) : (index * (100 / (stops.length - 1)))
  //     return percentage.toString()
  //   } else {
  //     return (index * (100 / (stops.length - 1))).toString()
  //   }
  // }

  goBack = () => {
    this.clearMap.emit();
  };

  ngOnDestroy(): void { 
    this.languageSubscription.unsubscribe();
    clearInterval(this.interval);
  }

  get strokeBg(): any {
    return {
      'background-color': `${this.selectedTrip.service && this.selectedTrip.service.color !== '#FFFFFFFF' ? this.selectedTrip.service.color : "#000000"}`
    };
  }

  get greenBg(): any {
    return {
      'background-color': '#0dedab'
    };
  }

  get purpleBg(): any {
    return {
      'background-color': '#8136db'
    };
  }

  public filter = async (event: {selectedClients: IBase[], selectedOperators: IBase[], selectedTypes: ExpeditionType[], selectedStatus: ExpeditionStatus[]}) => {
    this.trips = initialTripsState;
    this.trips.value = [];
    this.clientsSelected = event.selectedClients;
    this.operatorsSelected = event.selectedOperators;
    this.typesSelected = event.selectedTypes;
    this.statusSelected = event.selectedStatus;
    this.trips.pagination.actualPage = 0;
    await this.getTrips();
    this.searching = false;
  };

  search = async (event: any) => {
    this.trips = initialTripsState;
    this.trips.value = [];
    this.searchValue = event;
    this.trips.pagination.actualPage = 0;
    await this.getTrips();
    this.searching = false;
  };

  isLate(delay?: number): boolean {
    return delay ? delay > 5 : false;
  }

  isEarly(delay?: number): boolean {
    return delay ? delay < -3 : false;
  }

  shouldHaveStarted(dateTime?: Date): boolean {
    return dateTime ? new Date(dateTime) < new Date() : false;
  }
}
