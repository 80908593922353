import { Injectable } from '@angular/core';
import { ServiceHttpService } from './service.http.service';
import { objectToCamel } from 'ts-case-convert';
import { lastValueFrom } from 'rxjs';
import { IServiceInfoDetail } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private serviceHttpService: ServiceHttpService) { }

  async getAvailabilities(serviceId: number, page?: number, size?: number) {
    const source$ = this.serviceHttpService.getAvailablities(serviceId, page, size);
    const response = await lastValueFrom(source$);
    const availabilities = objectToCamel(response as object);
    const result = this.getData(availabilities);
    return result;
  }
  
  async getDestinationAvailabilityStops(availabilityId: number, inStopId: number, page: number, size: number) {
    const source$ = this.serviceHttpService.getAvailablityStops(availabilityId, page, size, inStopId);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }
  
  async getAvailabilityStops(availabilityId: number, page: number, size: number) {
    const source$ = this.serviceHttpService.getAvailablityStops(availabilityId, page, size);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }

  async getServices(page: number, size: number) {
    const source$ = this.serviceHttpService.getServices(page, size);
    const response = await lastValueFrom(source$);
    const services = objectToCamel(response as object);
    const result = this.getData(services);
    return result;
  }

  async getService(idService: number) {
    const source$ = this.serviceHttpService.getService(idService);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as object);
    return service;
  }

  async getServiceConfig(idService: number) {
    const source$ = this.serviceHttpService.getServiceConfig(idService);
    const response = await lastValueFrom(source$);
    const serviceConfig = objectToCamel(response as object);
    return serviceConfig;
  }
  
  getCurrentDomain(): string {
    return window.location.origin;
  }

  async importServiceFromGtfs(data: any, clientId: number, townId: number) {
    const source$ = this.serviceHttpService.importServiceFromGtfs(data, clientId, townId);
    const response = await lastValueFrom(source$);
    const services = objectToCamel(response as object);
    const result = this.getData(services);
    return result;
  }

  async downloadGtfs(routeId: number) {
    const source$ = this.serviceHttpService.downloadGtfs(routeId);
    const response = await lastValueFrom(source$);
    console.log(response)
    // const services = objectToCamel(response as object);
    // const result = this.getData(services);
    return response;
  }

  async getRoutesByServiceInfo(serviceIds: number[], page?: number, size?: number) {
    const source$ = this.serviceHttpService.getRoutesByServiceInfo(serviceIds, page, size);
    const response = await lastValueFrom(source$);
    const serviceArray = this.getData(response);
    console.log(serviceArray)
    return serviceArray;
  }

  async getTerrains(townsIds?: number[]) {
    const source$ = this.serviceHttpService.getTerrains(townsIds);
    const response = await lastValueFrom(source$);
    console.log(response)
    const terrainsArray = objectToCamel(response as []);
    console.log(terrainsArray)
    return terrainsArray;
  }
  
  getData(response: any) {
    return response.data;
  }
}
