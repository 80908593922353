<div class="flex w-[411px]">
    <section class="w-full">
        <article id="header" class="flex primary-green-2-background rounded-ss-lg rounded-se-lg">
            <div class="flex ml-5 my-4 items-center w-full">
                <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('services.routes.title', true) }}</h1>
            </div>
            <div class="flex items-center mr-8">
                <img [src]="images.services.routes.addRoute" alt="addRoute" class="w-6 h-6 mr-4 cursor-pointer" />
                <img [src]="images.close" alt="close" (click)="close.emit()" class="w-6 h-6 cursor-pointer" />
            </div>
        </article>
        <article id="search" class="flex bg-white">
            <div class="mx-5 my-4 items-center w-full">
                <app-service-routes-search-bar
                    [placeholder]="literalService.get('services.routes.search', true)"
                    [searching]="searching"
                    (searchEventEmitter)="search($event)">
                </app-service-routes-search-bar>
            </div>
        </article>
        <article id="body" class="flex bg-white">
            <div class="mx-5 my-5 items-center min-w-[371px] max-w-[371px]">
                @for (route of routes()?.value; track $index) {
                    <div class="row">
                        <!-- <app-service-routes-row class="w-full" [route]="route" (click)="selectServiceInfo.emit(serviceInfo)"></app-service-routes-row> -->
                        <app-service-routes-row [route]="route" (selectRoute)="selectRoute.emit($event)"></app-service-routes-row>
                    </div>
                }
            </div>
        </article>
        <!-- <article id="footer" class="h-4 flex items-center bg-white rounded-es-lg rounded-ee-lg"> -->
        <article id="footer" class="flex items-center bg-white rounded-es-lg rounded-ee-lg pb-20">
            <app-pagination id="routesPagination" [id]="'routesPagination'" class="mx-5" [pagination]="routes()?.pagination" (changePage)="changePageRoutes($event)" [small]="true" [maxLength]="5" [right]="false" />
        </article>
    </section>
</div>
  