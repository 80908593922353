<section class="flex items-center">
    <article class="pt-2 pb-2 pl-4 pr-4 rounded mr-4 border w-full">
      <app-input-search [placeholder]="placeholder" [width]="'full'" (submitEventEmitter)="search($event)"></app-input-search>
    </article>
    <article class="flex border rounded w-10 h-10 items-center justify-center neutrals-grey-01-background neutrals-grey-02-border" (click)="filterOpened = !filterOpened">
      <img [src]="images.filter" alt="filter" class="p-1 m-4" [ngClass]="{ 'filterOpened': filterOpened }" />
    </article>
    <article class="flex absolute mt-20 w-auto z-10 bg-white" [ngClass]="{ 'hidden': !filterOpened }">
      <!-- <app-booking-filter (filter)="filter.emit($event)" (closeFilter)="filterOpened = false" [componentOpen]="'buttonOpenFilter'"></app-booking-filter> -->
    </article>
    @if (searching) {
      <article class="flex items-center pb-2">
        <img [src]="images.loading" alt="loading" class="h-10 w-10 ml-4" />
        <label class="font-semibold text-base ml-4">{{ literalService.get('services.searchingServices', true) }}...</label>
      </article>
    }
  </section>
  