<div id="user-profile" class="flex items-center cursor-pointer rounded">
  <div id="openDropdownUser" class="flex items-center ml-3" (click)="openDropDownUser()" data-dropdown-toggle="dropdown-user">
    <div>
      <button
        type="button"
        class="flex text-sm bg-gray-800 rounded-full"
        aria-expanded="false"
      >
        <span class="sr-only">Open user menu</span>
        <div class="avatar rounded-full w-12 h-12 flex items-center justify-center">
          @if (isDevMode) {
            <h1 class="uppercase">{{ 'Nemi'.charAt(0) }}</h1>
          } @else {
            <h1 class="uppercase">{{ jwtTokenService.getUserFirstName() && jwtTokenService.getUserFirstName()!.charAt(0) }}</h1>
          }
        </div>
      </button>
    </div>
    <div class="ml-4 font-bold dark:text-white">
      @if (isDevMode) {
        <label class="avatar-name cursor-pointer text-xl font-bold">{{ 'Nemi' }}</label>
      } @else {
        <label class="avatar-name cursor-pointer text-xl font-bold">{{ jwtTokenService.getUserFirstName()! }}</label>
      }
    </div>
    <div class="ml-4">
      <img [src]="images.arrowDown" alt="arrow" class="h-5" [ngClass]="{'actionRotateArrowOpen': !open, 'actionRotateArrowClose': open}" />
    </div>
  </div>
  <div class="flex items-center ml-3">
    <div class="absolute z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 border border-gray-300" id="dropdown-user">
      <div class="px-4 py-3">
        <app-user-profile-box id="languages" image="languages" text="userProfile.language" [value]="language" (click)="openDropDownLanguages()" data-dropdown-toggle="dropdown-language" data-dropdown-placement="left-start" arrow="true"></app-user-profile-box>
        <div data-modal-target="changePasswordModalGeneral" data-modal-toggle="changePasswordModalGeneral">
          <app-user-profile-box id="changePasswordGeneral" image="changePassword" text="userProfile.changePassword" (click)="changePasswordModalGeneral.toggle()"></app-user-profile-box>
        </div>
        <app-user-profile-box id="signOut" image="signOut" text="userProfile.signOut" (click)="signOut()"></app-user-profile-box>
      </div>
    </div>
    <div class="absolute z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 border border-gray-300" id="dropdown-language">
      <div class="px-4 py-3">
        <app-user-profile-language [id]="'language-' + flag.name" *ngFor="let flag of flags" [image]="flag.img" [text]="flag.name.toLowerCase()" [language]="language" (getLanguage)="getLanguage()" (setLanguage)="setLanguage($event)"></app-user-profile-language>
      </div>
    </div>
  </div>
</div>
