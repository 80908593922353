@if (this.values.value.length > 0) {
  <div class="flex absolute pb-10" [ngClass]="{'right-4': !right, 'right-28': right}">
    <div id="back" (click)="!values.pagination.first && changePage.emit({isNext: false})" [ngClass]="{'item': !small, 'item-small': small, 'cursor-pointer': !values.pagination.first}" class="flex justify-center items-center rounded-[10px] p-[10px] gap-[10px] mr-1">
      <img [src]="images.back" class="h-4 w-4" [ngClass]="{'disabled': values.pagination.first}" alt="back" />
    </div>
    @for (page of utilsService.getPaginationItems(values.pagination.actualPage, values.pagination.totalPages, 7); track $index) {
      @if (isNaN(page)) {
        <div class="flex justify-center items-center rounded-[10px] p-[10px] gap-[10px]" [ngClass]="{'item': !small, 'item-small': small}">
          <label class="font-semibold text-xl">···</label>
        </div>
      } @else {
        <div [id]="'page'+$index" (click)="changePage.emit({isNext: false, specificPage: page})" class="flex page-number justify-center items-center rounded-[10px] p-[10px] gap-[10px] ml-1 mr-1 cursor-pointer" [ngClass]="{'selected': page === values.pagination.actualPage, 'item': !small, 'item-small': small}">
          <label class="font-semibold text-xl cursor-pointer">{{ page }}</label>
        </div>
      }
    }
    <div id="next" (click)="!values.pagination.last && changePage.emit({isNext: true})"  [ngClass]="{'item cursor-pointer': !values.pagination.last, 'item': !small, 'item-small': small}" class="flex justify-center items-center rounded-[10px] p-[10px] gap-[10px] ml-1">
      <img [src]="images.next" class="h-4 w-4" [ngClass]="{'disabled': values.pagination.last}" alt="next" />
    </div>
  </div>
}