<section id="filterContainer">
    <article class="pl-5 pt-5">
        <h1 class="title">{{ literalService.get('filterBy', true) }}</h1>
    </article>
    <article class="lg:flex sm:relative pl-5 pt-5 pr-5 place-content-between">
        <!--label class="caption w-32 mr-4">{{ literalService.get('filterByField', true) }}</label>
        <label class="w-48 text-center title-columns mr-4"></label-->
        @if(showClients) {
            <label class="w-48 text-center title-columns mr-4">{{ literalService.get('users.data.clients', true) }}</label>
        }
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('trips.area', true) }}</label>
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('trips.type', true) }}</label>
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('trips.status', true) }}</label>
    </article>
    <form #form novalidate>
        <article class="lg:flex sm:relative pl-5 pt-5 pr-5">
            @if(showClients) {
                <article class="lg:flex rounded w-48 h-12 items-center mr-4">
                    <app-multi-select [title]="'clients'" [elements]="clients" [values]="selectedValuesClients" [width]="'48'" />
                </article>
            }
            <article class="lg:flex rounded w-48 h-12 items-center mr-4">
                <app-multi-select [title]="'towns'" [elements]="towns" [values]="selectedValuesTowns" [width]="'48'" />
            </article>
            <article>
                @for (type of expeditionTypes; track $index) {
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center bg-white">
                        <input type="checkbox" class="mr-2" [checked]="type.checked" (change)="type.checked = !type.checked" />
                        <!--img [src]="getImage(type.value, 'type')" class="mr-2" /-->
                        <label>{{ literalService.get('trips.' + type.value.toLowerCase(), true) }}</label>
                    </article>
                }
            </article>
            <article>
                @for (status of expeditionStatus; track $index) {
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center" [ngClass]="!closedStatusSelected && !cancelledStatusSelected ? 'bg-white' : 'bg-gray-100'">
                        <input type="checkbox" class="mr-2" [checked]="status.checked" (change)="status.checked = !status.checked" [disabled]="closedStatusSelected || cancelledStatusSelected" />
                        <!--img [src]="getImage(status.value, 'status')" class="mr-2" [ngClass]="{
                            'ongoing': status.value.toUpperCase() === ExpeditionStatus.ONGOING,
                            'ready': status.value.toUpperCase() === ExpeditionStatus.READY,
                            'open': status.value.toUpperCase() === ExpeditionStatus.OPEN,
                            'closed': status.value.toUpperCase() === ExpeditionStatus.CLOSED,
                            }" /-->
                            <label>{{ literalService.get('trips.' + status.value.toLowerCase(), true) }}</label>
                        </article>
                    }
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center" [ngClass]="!(closedStatusSelected || cancelledStatusSelected) ? 'bg-white' : 'bg-gray-100'">
                        <input type="checkbox" switch class="mr-2" [checked]="closedStatusSelected" (change)="closedStatusSelected = !closedStatusSelected" [disabled]="cancelledStatusSelected" />
                        <label>{{ literalService.get('trips.closed', true) }}</label>
                    </article>
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center" [ngClass]="!(closedStatusSelected || cancelledStatusSelected) ? 'bg-white' : 'bg-gray-100'">
                        <input type="checkbox" switch class="mr-2" [checked]="cancelledStatusSelected" (change)="cancelledStatusSelected = !cancelledStatusSelected" [disabled]="closedStatusSelected" />
                        <label>{{ literalService.get('trips.cancelled', true) }}</label>
                    </article>
            </article>
        </article>
        <article class="pt-10 pl-5 pb-5">
            <article class="flex pt-5 place-content-between">
                <div class="flex">
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center bg-white">
                        <input #select id="selectAll" type="checkbox" class="mr-2" (change)="selectAll($event)" />
                        <label for="selectAll">{{ literalService.get('selectAll', true) }}</label>
                    </article>
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center">
                        <label class="cursor-pointer hover:underline" (click)="clearSelection()">{{ literalService.get('clearSelection', true) }}</label>
                    </article>
                </div>
                <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center">
                    <input type="button" class="primary text-white gap-4 p-2 border-2 font-semibold h-12 w-48 uppercase cursor-pointer" [value]="literalService.get('filter', true)" (click)="onSubmit(); closeFilter.emit()" />
                </article>
            </article>
        </article>
    </form>
</section>

