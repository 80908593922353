import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputType } from 'src/app/models/enums';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrl: './color.component.scss'
})
export class ColorComponent {

  @Input() type: InputType;
  @Input() value: any;
  @Input() values: any[];
  @Output() changeEventEmitter = new EventEmitter<unknown>();

  changeColor(event: any) {
    console.log(event.target.value)
    this.value = event.target.value;
    console.log(this.value)
    this.changeEventEmitter.emit(this.value);
  }

}
