<form #form novalidate>
  <div id="bookingDetailsContainer" class="container absolute top-20 right-0 hiddenDetails w-0 h-[66rem]" [ngClass]="{ 'openDetails md:w-[47%] 2xl:w-[35%]': booking && roleService.permissions['RESERVATION']['READ'], 'openDetails md:w-[68%] 2xl:w-[54%]': booking && expanded && roleService.permissions['RESERVATION']['READ']}">
    @if (booking) {
      <section class="topbar w-100 h-14 mt-4 flex items-center">
        @if (booking.id) {
          <h1 class="ml-8 text-xl font-[600] max-w-[40%] truncate">
            {{ literalService.get("bookings.name", true) }}
          </h1>
          <section id="actions" class="flex ml-auto mr-8">
            <div class="flex items-center justify-center content-image mr-2" (click)="copyLink()">
              <img
                [tooltip]="literalService.get('copyLink', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.link"
                alt="copy"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <div class="flex items-center justify-center content-image mr-2" (click)="createFavouriteModal.toggle();">
              <img
                [tooltip]="literalService.get('bookings.tooltips.favorite', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.favorite"
                alt="favorite"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            @if (!booking.cancelled) {
              <div class="flex items-center justify-center content-image mr-2" (click)="goToTrip(booking.tripId)">
                <img
                  [tooltip]="literalService.get('bookings.tooltips.trip', true)"
                  [position]="TooltipPosition.DYNAMIC"
                  [src]="images.booking.actions.trip"
                  alt="trip" 
                  class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                />
              </div>
            }
            <div class="flex items-center justify-center content-image mr-2" (click)="goToUser(booking.userId)">
              <img
                [tooltip]="literalService.get('bookings.tooltips.user', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.user"
                alt="user"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <!--div class="flex items-center justify-center content-image mr-2">
              <img
                [tooltip]="literalService.get('bookings.tooltips.open', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.open"
                alt="open"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div-->
            @if (expanded && roleService.permissions['RESERVATION']['UPDATE']) {
              <!--div
                class="flex items-center justify-center content-image mr-2"
                (click)="editBooking.emit(booking)"
              >
                <img
                  [tooltip]="literalService.get('bookings.tooltips.edit', true)"
                  [position]="TooltipPosition.DYNAMIC"
                  [src]="images.booking.actions.edit"
                  alt="edit"
                  class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                />

              </div-->
              @if (!booking.cancelled) {
                <div
                  class="flex items-center justify-center content-image mr-2"
                  (click)="setFaultModal.toggle()"
                >
                  <img
                    [tooltip]="booking.missed ? literalService.get('bookings.tooltips.unMarkAsAFault', true) : literalService.get('bookings.tooltips.markAsAFault', true)"
                    [position]="TooltipPosition.DYNAMIC"
                    [src]="images.booking.actions.missed"
                    alt="missed"
                    class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                    [ngClass]="{'missed': booking.missed }"
                  />
                </div>
                <div
                  class="flex items-center justify-center content-image mr-2"
                  (click)="deleteBooking.emit()"
                >
                  <img
                    [tooltip]="literalService.get('bookings.tooltips.delete', true)"
                    [position]="TooltipPosition.LEFT"
                    [src]="images.booking.actions.delete"
                    alt="delete"
                    class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                  />
                </div>
              }
            }
            <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
              <img
                [src]="images.close"
                alt="closeDetails"
                class="h-6 w-6"
              />
            </div>
          </section>
        }
      </section>
      <section class="w-100 mt-8 ml-8 mr-20 items-center">
        <div class="grid grid-rows-6 grid-flow-col" [ngClass]="{ 'grid-rows-6': booking }">
          <article *ngFor="let element of bookingForm">
            @if (expanded ? true : !element.expanded) {
              @if (element.edit) {
                <app-editable-detail [data]="element" [value]="element.value" [isHoveringOriginDestinationDetail]="isHoveringOriginDestinationDetail" (action)="openModal(element.edit.valueToChange)" (setIsHoveringOriginDestination)="setIsHoveringOriginDestinationDetail($event)" />
              } @else {
                <div [ngClass]="{'mt-5 mb-5': booking.cancelled}">
                  <app-detail [data]="element" [value]="element.value" />
                </div>
              }
            }
          </article>
          @if (expanded) {
            <!-- <button id="viewMap" type="button" class="flex items-center font-semibold mb-5" (click)="openInFullMap.emit(booking)">
              <img  [src]="images.world" alt="viewMap" class="h-5 w-5 cursor-pointer" />
              {{ literalService.get('bookings.data.viewMap', true) }}
            </button> -->
          }
        </div>
        @if (booking && !edit && !newBooking) {
          <div class="flex text-right justify-end" [ngClass]="{'mr-10': booking.cancelled}">
            <div class="text-right mt-5">
              @if (booking.creatorName || booking.createdAt) {
                  <p class="changelog primary-purple-light-text font-light text-sm">{{ literalService.get('created', true) }}
                      @if (booking.creatorName) {
                          <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ booking.creatorName }}</span>
                      }
                      {{ literalService.get('at') }} {{ utilsService.convertDate(booking.createdAt) | date: 'dd/MM/yyyy HH:mm' }}
                  </p>
              }
              @if (booking.modifierName || booking.lastModifiedAt) {
                <p class="changelog primary-purple-light-text font-light text-sm">{{ !booking.cancelled  ? literalService.get('lastModification', true) : literalService.get('cancellation', true)}}
                    @if (booking.modifierName) {
                        <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ booking.modifierName }}</span>
                    }
                    {{ literalService.get('at') }} {{ utilsService.convertDate(booking.lastModifiedAt) | date: 'dd/MM/yyyy HH:mm' }}
                </p>                                            
              }
            </div>
            @if (booking.cancellationComment) {
              <label class ="primary-purple-light-text font-light text-sm mt-10">{{'...'}}&nbsp;</label>
              <img [src]="images.plus" class="h-5 w-5 plus ml-2 mt-9" [tooltip]="cancellationReason + booking.cancellationComment">
            }
          </div>
        }
      </section>
      <section class="flex items-center absolute right-5 top-0 bottom-0">
        <img alt="arrowToExpand" [src]="images.arrowLeft" class="h-5 w-5 cursor-pointer expand" [ngClass]="{ actionRotateArrowOpen: !expanded, actionRotateArrowClose: expanded }" (click)="expanded = !expanded" />
      </section>
    }
    <section>
      <div id="mapContainer" class="p-5 h-[400px]" [ngClass]="{'hidden': !viewMap}">
        <img [src]="images.openInFull" class="absolute bg-white rounded-[50%] z-10 h-10 w-10 right-5 p-2 mt-4 mr-4 cursor-pointer" (click)="openInFullMap.emit(booking)" />
        <app-marker-od id="origin" [ngClass]="{ hidden: !booking }" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [text]="literalService.get('origin').toUpperCase()"></app-marker-od>
        <app-marker-od id="destination" [ngClass]="{ hidden: !booking }" [textColor]="'text-black'" [color]="'primary-green-background'" [text]="literalService.get('destination').toUpperCase()"></app-marker-od>
        <div id="map" class="w-full h-full"></div>
      </div>
    </section>
    
  </div>
  <app-modal-change-booking-kms [id]="'changeKms'" [booking]="booking" [modal]="changeKms" [hidden]="false"  (action)="submitBookingKmsEdit($event)" />
  <app-modal-change-booking-availability [id]="'changeAvailability'" [booking]="bookingCopy" [modal]="changeAvailability" [element]="availablityElement" [hidden]="false"  (action)="submitBookingEdit($event)" />
  <app-modal-change-booking-origin-destination [id]="'changeOriginDestination'" [booking]="bookingCopy" [modal]="changeOriginDestination" [originElement]="originElement" [destinationElement]="destinationElement" [hidden]="false" [updating]="updatingOriginDestination" (action)="submitBookingEdit($event)" />
  <app-modal-change-booking-seats [id]="'changeSeats'" [booking]="bookingCopy" [modal]="changeSeats" [hidden]="false" (action)="submitBookingEdit($event)" />
  <app-modal-create-favourite [id]="'createFavouriteFromDetail'" [modal]="createFavouriteModal" [title]="literalService.get('bookings.actions.createFavourite.title')" [text]="literalService.get('bookings.actions.createFavourite.text')" [hidden]="!createFavouriteShowed" [creating]="creatingFavourite" (action)="createFavourite($event)"></app-modal-create-favourite>
  <app-modal-confirmation [id]="'setFault'" [modal]="setFaultModal" [title]="literalService.get('bookings.actions.setFault.title')" [text]="booking && booking.missed ? literalService.get('bookings.actions.setFault.unFault') : literalService.get('bookings.actions.setFault.fault')" [hidden]="!setFaultShowed" (action)="markAsAFault.emit(); setFaultModal.toggle();"></app-modal-confirmation>
</form>
