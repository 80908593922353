<!-- MENU -->
<aside
  id="sidebar"
  class="dark:sidebar fixed top-0 left-0 w-20 z-40 h-screen pt-0 transition-transform -translate-x-full border-gray-200 sm:translate-x-0 bg-white dark:bg-gray-800 dark:border-gray-700 overflow-hidden"  aria-label="Sidebar"
>
  <div class="h-full pb-4 overflow-y-auto dark:bg-gray-800">
    <ul class="font-medium">
      <li class="h-20 flex">
        <a
          class="h-16 justify-center m-auto flex text-gray-900 rounded-lg dark:text-white"
        >
          <img
            id="logoSection"
            [src]="images.logos.nemiSmall"
            alt="logo"
            class="w-12"
          />
        </a>
      </li>
      <li id="adminsSectionList" class="sidebar-item-list hidden">
        <a
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="adminsSection"
            [src]="images.sidebar.admin"
            alt="admins"
            class="w-8 mx-5"
          />
        </a>
      </li>
      <li class="sidebar-item-list" [ngClass]="{'active': routeName === 'users'}" (click)="setRouteNameSelected('users')">
        <a
          [routerLink]="['/users']"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="usersSection"
            [src]="images.sidebar.users"
            alt="users"
            class="w-8 mx-5"
          />
        </a>
      </li>
      <li class="sidebar-item-list" [ngClass]="{'active': routeName === 'bookings'}"  (click)="setRouteNameSelected('bookings')">
        <a
          [routerLink]="['/bookings']"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="bookingsSection"
            [src]="images.sidebar.bookings"
            alt="bookings"
            class="w-8 mx-5"
          />
        </a>
      </li>
      <li class="sidebar-item-list" [ngClass]="{'active': routeName === 'trips'}"  (click)="setRouteNameSelected('trips')">
        <a
          [routerLink]="['/trips']"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="tripsSection"
            [src]="images.sidebar.trips"
            alt="trips"
            class="w-8 mx-5"
          />
        </a>
      </li>
      <!--li class="sidebar-item-list">
        <a
          [href]="getUrl() + 'operators'"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="operatorsSection"
            [src]="images.sidebar.operators"
            alt="operators"
            class="w-8 mx-5"
          />
        </a>
      </li-->
      @if (role === 'SUPERADMIN' || role === 'ADMIN' || role === 'DRIVER_MANAGER') {
        <li class="sidebar-item-list">
          <a
            [href]="getUrl() + 'vehicles'"
            class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <img
              id="vehiclesSection"
              [src]="images.sidebar.vehicles"
              alt="vehicles"
              class="w-8 mx-5"
            />
          </a>
        </li>
      }
      @if (role === 'SUPERADMIN' || role === 'ADMIN') {
        <li class="sidebar-item-list">
          <a
            [href]="getUrl() + 'services'"
            class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <img
              id="servicesSection"
              [src]="images.sidebar.services"
              alt="services"
              class="w-8 mx-5"
            />
          </a>
        </li>
      }
      <!--li class="sidebar-item-list">
        <a
          [href]="getUrl() + 'towns'"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="townsSection"
            [src]="images.sidebar.towns"
            alt="towns"
            class="w-8 mx-5"
          />
        </a>
      </li-->
      <li class="sidebar-item-list">
        <a
          [href]="getUrl() + 'messages'"
          class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img
            id="messagesSection"
            [src]="images.sidebar.messages"
            alt="messages"
            class="w-8 mx-5"
          />
        </a>
      </li>
      @if (role === 'SUPERADMIN' || role === 'ADMIN') {
        <li class="sidebar-item-list">
          <a
            [href]="getDashboardUrl()"
            class="h-16 flex items-center justify-center text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <img
              id="dashboardSection"
              [src]="images.sidebar.dashboard"
              alt="dashboard"
              class="w-8 mx-5"
            />
          </a>
        </li>
      }
    </ul>
  </div>
</aside>
<!-- SUBMENU -->
<aside
  id="sidebar-submenu"
  class="fixed top-0 left-0 w-52 z-[55] h-screen pt-0 transition-transform -translate-x-full border-gray-200 sm:translate-x-20 dark:bg-gray-800 dark:border-gray-700"
  [ngClass]="{ 'fadeOut': hidden, 'fadeIn': !hidden }"
  aria-label="Sidebar"
>
  <div class="h-full pb-4 overflow-y-auto dark:bg-gray-800">
    <ul class="font-medium">
      <li class="h-20 flex">
        <a
          class="h-16 justify-center m-auto flex text-gray-900 rounded-lg dark:text-white"
        >
          <img
            id="logoSection"
            [src]="images.logos.nemiLarge"
            alt="logo"
            class="w-36"
          />
        </a>
      </li>
      <li id="adminsSubmenuSectionList" class="sidebar-submenu-item-list hidden">
        <a
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.administrator', true) }}</h1>
        </a>
      </li>
      <li class="sidebar-submenu-item-list" [ngClass]="{'active': routeName === 'users'}"  (click)="setRouteNameSelected('users')">
        <a
          [routerLink]="['/users']"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.users', true) }}</h1>
        </a>
      </li>
      <li class="sidebar-submenu-item-list" [ngClass]="{'active': routeName === 'bookings'}"  (click)="setRouteNameSelected('bookings')">
        <a
          [routerLink]="['/bookings']"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.bookings', true) }}</h1>
        </a>
      </li>
      <li class="sidebar-submenu-item-list" [ngClass]="{'active': routeName === 'trips'}"  (click)="setRouteNameSelected('trips')">
        <a
          [routerLink]="['/trips']"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.trips', true) }}</h1>
        </a>
      </li>
      <!--li class="sidebar-submenu-item-list">
        <a
          [href]="getUrl() + 'operators'"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.operators', true) }}</h1>
        </a>
      </li-->
      @if (role === 'SUPERADMIN' || role === 'ADMIN' || role === 'DRIVER_MANAGER') {
        <li class="sidebar-submenu-item-list">
          <a
            [href]="getUrl() + 'vehicles'"
            class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <h1>{{ literalService.get('sidebar.vehicles', true) }}</h1>
          </a>
        </li>
      }
      @if (role === 'SUPERADMIN' || role === 'ADMIN') {
        <li class="sidebar-submenu-item-list">
          <a
            [href]="getUrl() + 'services'"
            class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <h1>{{ literalService.get('sidebar.services', true) }}</h1>
          </a>
        </li>
      }
      <!--li class="sidebar-submenu-item-list">
        <a
          [href]="getUrl() + 'towns'"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.towns', true) }}</h1>
        </a>
      </li-->
      <li class="sidebar-submenu-item-list">
        <a
          [href]="getUrl() + 'messages'"
          class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <h1>{{ literalService.get('sidebar.messageUsers', true) }}</h1>
        </a>
      </li>
      @if (role === 'SUPERADMIN' || role === 'ADMIN') {
        <li class="sidebar-submenu-item-list">
          <a
            [href]="getDashboardUrl()"
            class="h-16 flex items-center justify-left ml-5 font-bold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
          >
            <h1>{{ literalService.get('sidebar.dashboard', true) }}</h1>
          </a>
        </li>
      }
    </ul>
  </div>
</aside>
<!-- ADMINISTRATORS SUBMENU -->
<aside
  id="sidebar-submenu-admin"
  class="fixed top-20 left-0 h-56 w-60 z-60 pt-0 transition-transform -translate-x-full border-gray-200 sm:translate-x-72 dark:bg-gray-800 dark:border-gray-700"
  [ngClass]="{ 'fadeOut': hiddenAdmin, 'fadeIn': !hiddenAdmin }"
  aria-label="Sidebar"
>
  <div class="h-full pb-4 overflow-y-auto dark:bg-gray-800">
    <ul class="font-medium">
      <li class="sidebar-submenu-admin-item-list">
        <a
          href="#"
          class="h-14 flex items-center justify-left font-semibold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img [src]="images.sidebar.clients" alt="apps" [height]="18" [width]="18" />
          <h1 class="ml-4">{{ literalService.get('sidebar.clients', true) }}</h1>
        </a>
      </li>
      <li class="separator"></li>
      <li class="sidebar-submenu-admin-item-list">
        <a
          href="#"
          class="h-14 flex items-center justify-left font-semibold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img [src]="images.sidebar.towns" alt="towns" [height]="20" [width]="20" />
          <h1 class="ml-4">{{ literalService.get('sidebar.towns', true) }}</h1>
        </a>
      </li>
      <li class="separator"></li>
      <li class="sidebar-submenu-admin-item-list">
        <a
          href="#"
          class="h-14 flex items-center justify-left font-semibold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
        <img [src]="images.sidebar.identityProvider" alt="identityProvider" [height]="21" [width]="22" />
          <h1 class="ml-4">{{ literalService.get('sidebar.identityProvider', true) }}</h1>
        </a>
      </li>
      <li class="separator"></li>
      <li class="sidebar-submenu-admin-item-list">
        <a
          href="#"
          class="h-14 flex items-center justify-left font-semibold text-base text-gray-900 rounded-lg dark:text-white sidebar-item"
        >
          <img [src]="images.sidebar.apps" alt="apps" [height]="22" [width]="14" />
          <h1 class="ml-4">{{ literalService.get('sidebar.apps', true) }}</h1>
        </a>
      </li>
    </ul>
  </div>
</aside>