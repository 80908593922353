import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import tinycolor from 'tinycolor2';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public urlsToCache = [];

  public storageService = new StorageService();

  public getLanguage = async () => {
    return this.storageService.getLanguage();
  };

  public capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  public visibleTooltip = (id: any) => {
    const e = document.getElementById(id);
    return e!.offsetWidth < e!.scrollWidth || e!.offsetHeight < e!.scrollHeight;
  };

  public isArray = (value: any) => {
    return Array.isArray(value);
  };

  public closeComponent = (
    e: Event,
    inputs: HTMLElement[],
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const input = inputs.find(
      (input: HTMLElement) => input.id === 'input-' + title,
    );
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (input && dropdown) {
      const isClickInsideInput = input.contains(e.target as Node);
      const isClickInsideDropdown = dropdown.contains(e.target as Node);

      if (!isClickInsideInput && !isClickInsideDropdown) {
        return true;
      }
    }
    return false;
  };

  public closeComponentOnlyWithDropdown = (
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (!isClickInsideDropdown && !isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public openComponentOnlyWithDropdown = (
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (isClickInsideDropdown || isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModals = (
    e: Event,
    modal: HTMLElement,
    componentOpen: HTMLElement,
  ): boolean => {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );

      if (!isClickInsideModal && !isClickInsideComponentOpen) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModalsWithModals = (
    e: Event,
    modal: HTMLElement,
    modals: Node[],
    componentOpen: HTMLElement,
  ): boolean => {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );
      const isClickInsideModals = modals.find((modal: Node) => {
        return modal.contains(e.target as Node);
      });

      if (!isClickInsideModal && !isClickInsideComponentOpen && !isClickInsideModals) {
        return true;
      }
    }
    return false;
  };

  public isDark(hexcolor: string) {
    return hexcolor ? tinycolor(hexcolor).isDark() : false;
  }

  public getSvgImageData = async (image: string) => {
    return await fetch(image)
      .then((response) => response.text())
      .then((svg) => svg);
  };

  public encryptSHA256 = (password: string): string => {
    return CryptoJS.SHA256(password).toString();
  };

  public compareObjects(obj1: any, obj2: any): string[] {
    return Object.keys(obj1)
      .filter((key) => obj1[key] !== obj2[key])
      .concat(Object.keys(obj2).filter((key) => obj2[key] && !obj1[key]));
  }

  public numberHasDecimals = (number: number) => {
    const result = number - Math.floor(number) !== 0;
    if (result) return true;
    return false;
  };

  public compare(propName: string) {
    return function(a: any, b: any) {
        if (a[propName] < b[propName])
            return -1;
        if (a[propName] > b[propName])
            return 1;
        return 0;
    };
  }

  public convertDate = (dateTime: any) => {
    const date = new Date(dateTime).toString();
    let dateString = date.toString().replace(/GMT.*/g, "");
    dateString = dateString + " UTC";
    return new Date(dateString);
  };

  public counter(i: number) {
    return new Array(i);
  }

  public isTextSelected() {
    return window.getSelection()!.toString().length > 0;
  }

  public getPaginationItems(currentPage: number, lastPage: number, maxLength: number) {
    const res: Array<number> = [];
  
    // handle lastPage less than maxLength
    if (lastPage <= maxLength) {
      for (let i = 1; i <= lastPage; i++) {
        res.push(i);
      }
    }
    else if (currentPage === 1) {
      for (let i = 1; i <= 5; i++) {
        res.push(i);
      }
      res.push(NaN);
      res.push(lastPage);
      return res;
    }
    else if (currentPage === lastPage) {
      res.push(1);
      res.push(NaN);
      for (let i = lastPage -  5; i <= lastPage; i++) {
        res.push(i);
      }
      return res;
    }
    // handle ellipsis logics
    else {
      const firstPage = 1;
      const confirmedPagesCount = 3;
      const deductedMaxLength = maxLength - confirmedPagesCount;
      const sideLength = deductedMaxLength / 2;
  
      // handle ellipsis in the middle
      if (
        currentPage - firstPage < sideLength ||
        lastPage - currentPage < sideLength
      ) {
        for (let j = 1; j <= sideLength + firstPage; j++) {
          res.push(j);
        }
  
        res.push(NaN);
  
        for (let k = lastPage - sideLength; k <= lastPage; k++) {
          res.push(k);
        }
      }
      // handle two ellipsis
      else if (
        currentPage - firstPage >= deductedMaxLength &&
        lastPage - currentPage >= deductedMaxLength
      ) {
        const deductedSideLength = sideLength - 1;
  
        res.push(1);
        res.push(NaN);
  
        for (
          let l = currentPage - deductedSideLength;
          l <= currentPage + deductedSideLength;
          l++
        ) {
          res.push(l);
        }
  
        res.push(NaN);
        res.push(lastPage);
      }
      // handle ellipsis not in the middle
      else {
        const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
        let remainingLength = maxLength;
  
        if (isNearFirstPage) {
          for (let m = 1; m <= currentPage + 1; m++) {
            res.push(m);
            remainingLength -= 1;
          }
  
          res.push(NaN);
          remainingLength -= 1;
  
          for (let n = lastPage - (remainingLength - 1); n <= lastPage; n++) {
            res.push(n);
          }
        } else {
          for (let o = lastPage; o >= currentPage - 1; o--) {
            res.unshift(o);
            remainingLength -= 1;
          }
  
          res.unshift(NaN);
          remainingLength -= 1;
  
          for (let p = remainingLength; p >= 1; p--) {
            res.unshift(p);
          }
        }
      }
    }
    return res;
  }
}
