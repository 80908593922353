import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { StorageService, RoleService } from 'src/app/services';
import { images } from '../../../../images';
import { UserRole } from 'src/app/models/enums';

@Component({
  selector: 'app-booking-search-bar',
  templateUrl: './booking-search-bar.component.html',
  styleUrl: './booking-search-bar.component.scss'
})
export class BookingSearchBarComponent implements OnInit {

  @Input() placeholder = '';
  @Input() searching = false;
  @Output() addBookingEventEmitter = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() searchEventEmitter = new EventEmitter<string>();
  @Output() sort = new EventEmitter();

  public filterOpened = false;
  public sortedOpened = false;

  public images = images;

  public adminRole: UserRole;

  constructor(
    public literalService: LiteralService,
    public storageService: StorageService,
    public roleService: RoleService
  ) { }

  async ngOnInit() {
    this.adminRole = await this.storageService.getRole();
  }

  search = (event: any) => {
    this.searchEventEmitter.emit(event);
  };
}
