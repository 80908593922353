import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import countries from '../../../countries.json';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() edit?: boolean;
  @Input() independent?: boolean;
  @Input() translateValue?: boolean = false;
  @Input() hasErrors?: boolean = false;
  @Input() error?: string = '';
  @Output() changeEventEmitter = new EventEmitter<any>();
  @Output() setEventEmitter = new EventEmitter<any>();
  @Output() scrollEventEmitter = new EventEmitter<any>();
  @Output() searchEventEmitter = new EventEmitter<any>();
  @Output() onFocusEventEmitter = new EventEmitter<any>();

  public InputType = InputType;
  public countries: any[] = [];
  public dialCodes: any[] = [];
  public images = images;
  public dialCodeSelected = '+34';
  public dialCodeCountry = 'ES';

  public timeout = setTimeout(() => {}, 0);

  public searching = false;

  public disabledKeys = ['ArrowDown', 'ArrowUp', 'Backspace', 'Enter'];

  @HostListener('keydown', ['$event'])
  onKeyDown(e: any) {
    if (e.key && this.disabledKeys.includes(e.key)) {
      switch(e.key) {
        case 'Backspace': 
          if(this.data.inputType !== InputType.AUTOCOMPLETE_SEARCH) {
            this.searching = e.target.value !== '';
          }
          if (e.target.value === '') e.preventDefault();
          break;
        case 'Enter': 
          if(this.data.inputType === InputType.AUTOCOMPLETE_SEARCH) {
            this.searching = true;
            this.searchEventEmitter.emit({search: e.target.value});
            setTimeout(() => {
              if (this.searching) this.searching = false;
            }, 1500);
          }
          break;
        default: 
          this.searching = false;
          break;
      }
    } else {
      if(this.data.inputType !== InputType.AUTOCOMPLETE_SEARCH) this.searching = true;
    }
  }

  constructor(public literalService: LiteralService) {
    countries.map((country: any) => {
      const element = {
        id: country.dial_code,
        image: country.emoji,
        name: country.dial_code,
      };
      this.countries.push(element);
      this.dialCodes.push(country.dial_code);
    });
  }

  ngOnInit(): void {
    if (this.data.edit?.inputType === InputType.PHONE) {
      this.dialCodeSelected = this.dialCodes.find((option: string) => {
        return this.data.value && this.data.value.includes(option, 0);
      });
      this.data.value = this.data.value?.replace(this.dialCodeSelected, '');
      this.dialCodeCountry =
        countries.find(
          (country: any) => country.dial_code === this.dialCodeSelected,
        )?.dial_code || '+34';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      if (this.data.title === 'bookings.data.originStop' || this.data.title === 'bookings.data.destinationStop') {
        if (this.searching && this.data.edit.values.length > 0) this.searching = false;
        if (this.data.edit?.inputType === InputType.AUTOCOMPLETE_SEARCH) {
          if (this.data.edit?.searchPlaceholder !== '') {
            const select2Element = document.getElementById(`${this.data.title}-search-field`);
            select2Element?.setAttribute('placeholder', this.data.searchPlaceholder);
          }
        }
      }
    }
  }

  change(event: any) {
    this.changeEventEmitter.emit(event);
  }

  cancelEvent = (event: any)  => {
    event.preventDefault();
  };

  searchSelect2 = (event: any) => {
    if (this.searching) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.searchEventEmitter.emit(event), 500);
    }
  };

  onClose() {
    this.searching = false;
  }
}
