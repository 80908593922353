import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { IBooking } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-create-favourite',
  templateUrl: './modal-create-favourite.component.html',
  styleUrl: './modal-create-favourite.component.scss'
})
export class ModalCreateFavouriteComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() booking: IBooking;
  @Input() creating?: boolean;
  @Output() action = new EventEmitter<any>();

  public favouriteName = '';

  public images = images;

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/star.json'
  };

  constructor(public literalService: LiteralService) {}

  change = (favouriteName: string) => {
    this.favouriteName = favouriteName;
  };

  onSubmit = () => {
    this.action.emit(this.favouriteName);
    this.favouriteName = '';
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1);
  }

}
