export enum IDocumentType {
    ID_CARD = 'ID_CARD',
    PASSPORT = 'PASSPORT',
    DRIVING_LICENSE = 'DRIVING_LICENSE',
    CENSUS = 'CENSUS',
    UNIVERSITY_ID = 'UNIVERSITY_ID',
    MOBILITY_CARD = 'MOBILITY_CARD',
    PRM = 'PRM',
    PENSIONER = 'PENSIONER',
    SINGLE_PARENT_FAMILY = 'SINGLE_PARENT_FAMILY',
    LARGE_FAMILY = 'LARGE_FAMILY',
    YOUTH_CARD = 'YOUTH_CARD',
    PARENTAL_AUTHORISATION = 'PARENTAL_AUTHORISATION',
    HEALTH_CARD = 'HEALTH_CARD'
  }
  