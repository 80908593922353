<section class="absolute mt-2 ml-2 w-[42rem] z-10">
    <!--article class="header flex h-16 w-[32.688rem] primary-green-2-background items-center rounded-t-lg">
        <div class="cursor-pointer" (click)="goBack()">
            @if (showTripDetails) {
                <img [src]="images.arrowLeft" class="h-6 w-6 ml-4" />
            }
        </div>
        <h1 class="text-[22px] font-bold" [ngClass]="{'ml-4': !showTripDetails, 'ml-2': showTripDetails}">{{ literalService.get('trips.name', true) }}</h1>
    </article-->

        <article class="flex bg-white justify-start primary-green-2-background items-center pt-4 pl-4 pr-4" [ngClass]="{'hidden': showTripDetails}">
            <app-trip-search-bar
            [placeholder]="literalService.get('trips.search', true)"
            [searching]="searching"
            (filter)="filter($event)"
            (searchEventEmitter)="search($event)"
          ></app-trip-search-bar>
            <!--label [id]="expeditionStatus.ONGOING" class="font-semibold text-lg mr-2 w-full text-center cursor-pointer" (click)="statusSelected = expeditionStatus.ONGOING; changeStatus(expeditionStatus.ONGOING)">{{ literalService.get('trips.' + expeditionStatus.ONGOING.valueOf(), true) }}</label>
            <label [id]="expeditionStatus.READY" class="font-semibold text-lg mr-2 w-full text-center cursor-pointer" (click)="statusSelected = expeditionStatus.READY; changeStatus(expeditionStatus.READY)">{{ literalService.get('trips.' + expeditionStatus.READY.valueOf(), true) }}</label>
            <label [id]="expeditionStatus.OPEN" class="font-semibold text-lg mr-2 w-full text-center cursor-pointer" (click)="statusSelected = expeditionStatus.OPEN; changeStatus(expeditionStatus.OPEN)">{{ literalService.get('trips.' + expeditionStatus.OPEN.valueOf(), true) }}</label>
            <label [id]="expeditionStatus.CLOSED" class="font-semibold text-lg mr-2 w-full text-center cursor-pointer" (click)="statusSelected = expeditionStatus.CLOSED; changeStatus(expeditionStatus.CLOSED)">{{ literalService.get('trips.' + expeditionStatus.CLOSED.valueOf(), true) }}</label-->
        </article>
        <!--article class="flex bg-white justify-around primary-green-2-background items-center pl-4 pr-4 pt-2">
            <div class="w-full mr-2" [ngClass]="{'h-1 primary-green-background': statusSelected === expeditionStatus.ONGOING, 'h-[2px] neutrals-grey-02-background': statusSelected !== expeditionStatus.ONGOING }"></div>
            <div class="w-full mr-2" [ngClass]="{'h-1 primary-green-background': statusSelected === expeditionStatus.READY, 'h-[2px] neutrals-grey-02-background': statusSelected !== expeditionStatus.READY}"></div>
            <div class="w-full mr-2" [ngClass]="{'h-1 primary-green-background': statusSelected === expeditionStatus.OPEN, 'h-[2px] neutrals-grey-02-background': statusSelected !== expeditionStatus.OPEN }"></div>
            <div class="w-full mr-2" [ngClass]="{'h-1 primary-green-background': statusSelected === expeditionStatus.CLOSED, 'h-[2px] neutrals-grey-02-background': statusSelected !== expeditionStatus.CLOSED }"></div>
        </article-->
    @if (!showTripDetails) {
        <article class="pt-4 pb-14 bg-white rounded-b-lg">
            <div class="grid grid-cols-11 ml-4 mr-4 mb-4 text-center">
                <label class="flex justify-center items-center col-span-4">
                    <img [src]="images.trips.name" alt="name" class="h-6 w-6" />
                </label>
                <label class="flex justify-center items-center col-span-1">
                    <img [src]="images.trips.date" alt="date" class="h-6 w-6" />
                </label>
                <label class="flex justify-center items-center col-span-2">
                    <img [src]="images.trips.driver" alt="driver" class="h-6 w-6" />
                </label>
                <label class="flex justify-center items-center col-span-2">
                    <img [src]="images.trips.vehicle" alt="vehicle" class="h-6 w-6" />
                </label>
                <label class="flex justify-center items-center col-span-2">
                    <img [src]="images.moreOptions" alt="moreOptions" class="h-6 w-6" />
                </label>
            </div>
            @for (trip of trips.value; track $index) {
                <app-trip-row [trip]="trip"
                              [language]="language"
                              [index]="$index"
                              (getTripEventEmitter)="getTripDetails($event)"
                              (showChangeDriverEventEmitter)="showChangeDriverEventEmitter.emit($event)"
                              (showChangeVehicleEventEmitter)="showChangeVehicleEventEmitter.emit($event)"
                              (showViewBookingsEventEmitter)="showViewBookingsEventEmitter.emit($event)" 
                              (showSetReadyEventEmitter)="showSetReadyEventEmitter.emit($event)"
                              (showSetClosedEventEmitter)="showSetClosedEventEmitter.emit($event)"
                              (showDeleteTripEventEmitter)="showDeleteTripEventEmitter.emit($event)"/>
            }
            <div class="mt-6">
                <app-pagination [pagination]="trips.pagination" [right]="false" (changePage)="changePage($event)" />
            </div>
        </article>
    } @else if (tripDetails) {
        <article class="pt-4 pb-1 bg-white">
            <app-trip-row [trip]="selectedTrip"
            [language]="language"
            (getTripEventEmitter)="getTripDetails($event)"
            (showChangeDriverEventEmitter)="showChangeDriverEventEmitter.emit($event)"
            (showChangeVehicleEventEmitter)="showChangeVehicleEventEmitter.emit($event)"
            (showViewBookingsEventEmitter)="showViewBookingsEventEmitter.emit($event)"
            (showSetReadyEventEmitter)="showSetReadyEventEmitter.emit($event)"
            (showSetClosedEventEmitter)="showSetClosedEventEmitter.emit($event)"
            (showDeleteTripEventEmitter)="showDeleteTripEventEmitter.emit($event)"/>      
        </article>
        <article class="bg-white pb-4">
            <div class="flex justify-center items-center w-full">
                <div class="flex neutrals-grey-01-background justify-center items-center w-fit p-1 rounded">
                    <div class="flex" [tooltip]="literalService.get('trips.tooltips.totalDistance', true)" [position]="TooltipPosition.BELOW">
                        <img [src]="images.distance" class="h-6 w-6" />
                        <label class="ml-1 mr-4 font-semibold text-base">{{ tripDetails.distance | number:'1.0-2' || 0 }} km</label>
                    </div>
                    <div class="flex" [tooltip]="literalService.get('trips.tooltips.meanVelocity', true)" [position]="TooltipPosition.BELOW">
                        <img [src]="images.speed" class="h-6 w-6" />    
                        <label class="ml-1 mr-4 font-semibold text-base">{{ tripDetails.velocity || 0 }} km/h</label>
                    </div>
                    <div class="flex" [tooltip]="literalService.get('trips.tooltips.variationTime', true)" [position]="TooltipPosition.BELOW">
                        <img [src]="images.clock" class="h-6 w-6"/>
                        <label class="ml-1 mr-4 font-semibold text-base"
                        [ngClass]="{
                            'ongoingOk': selectedTrip.status === 'Ongoing' && !isLate(selectedTrip.delay) && !isEarly(selectedTrip.delay),
                            'ongoingLate': selectedTrip.status === 'Ongoing' && isLate(selectedTrip.delay),
                            'ongoingEarly': selectedTrip.status === 'Ongoing' && isEarly(selectedTrip.delay)
                          }">{{ tripDetails.delay || 0 }} min</label>
                    </div>
                    <div class="flex" [tooltip]="literalService.get('trips.tooltips.passengersOnBoard', true)" [position]="TooltipPosition.BELOW">
                        <img [src]="images.pax" class="h-6 w-6"/>
                        <label class="ml-1 mr-2 font-semibold text-base">{{ tripDetails.occupancy || 0 }}  /  {{ tripDetails.totalPax || 0 }} pax</label>
                    </div>
                    <!-- TODO: Uncomment this when we use me/permissions  -->
                    <!-- <img [src]="images.plus" class="h-5 w-5 cursor-pointer" (click)="showAddPaxEventEmitter.emit(selectedTrip.id)" data-modal-target="addPax" data-modal-toggle="addPax" [tooltip]="literalService.get('trips.actions.addPax.name', true)" [position]="TooltipPosition.ABOVE" [hidden]="!roleService.permissions['EXPEDITION']['UPDATE']"/> -->
                    <img [src]="images.plus" class="h-5 w-5 cursor-pointer" (click)="showAddPaxEventEmitter.emit(selectedTrip.id)" data-modal-target="addPax" data-modal-toggle="addPax" [tooltip]="literalService.get('trips.actions.addPax.name', true)" [position]="TooltipPosition.ABOVE" />
                </div>
            </div>
        </article>
        <article class="bg-white">
            <div class="h-[1px] neutrals-grey-02-background ml-4 mr-4"></div>
        </article>
        <article class="bg-white pt-4 h-[35rem] overflow-auto 2xl:h-[500px]">
            <div class="flex">
                <div class="ml-4 mr-4">
                    @for (stop of tripDetails.stops; track $index) {
                        <article class="flex neutrals-grey-01-background w-[110px] rounded ps-2 pe-2 pt-2 pb-2 mb-10 justify-center">
                            <label class="font-semibold text-base">{{ stop.estimatedTime }}</label>
                            @if (stop.realTime) {
                                <article class="w-[1px] neutrals-grey-03-background mr-2 ml-2"></article>
                                <label class="font-bold text-base">{{ stop.realTime }}</label>
                            } @else if (stop.foreseenTime){
                                <article class="w-[1px] neutrals-grey-03-background mr-2 ml-2"></article>
                                <label class="italic text-base">{{ stop.foreseenTime }}</label>
                            }
                        </article>
                    }
                </div>
                <div class="ml-4 mt-2 mr-4 w-1 h-full justify-center">
                    @for (stop of tripDetails.stops; track $index) {
                        <article [class]="tripDetails.stops.length === $index + 1 ? '' : 'h-20'" [ngStyle]="tripDetails.stops[$index].realTime ? greyBg : strokeBg">
                            <div [class]="'text-white h-6 w-6 rotate-180 rounded-[50%] -ml-[0.6rem]'" [ngStyle]="getStopColor($index)">
                                <div class="h-3 w-3 rounded-[50%] bg-white ml-[6px] mt-[6px] absolute"></div>
                            </div>
                        </article>
                    }
                </div>
                <div class="ml-4 justify-center font-semibold text-base w-full pr-2">
                    @for (stop of tripDetails.stops; track $index) {
                        <article [id]="'stop' + stop.id" class="flex h-[40px] items-center mb-10">
                            <label class="mr-4 flex-auto">{{ stop.name }}</label>
                            <div class="flex-auto"></div>
                            @if (stop.passengersIn > 0 || stop.prmIn > 0) {
                                <article class="secondary-green-light-background w-[60px] rounded ps-2 pe-2 mr-2 cursor-pointer" (click)="showViewPickUpsEventEmitter.emit(stop.id)">
                                @if (stop.passengersIn > 0) {
                                    <div class="flex justify-center items-center">
                                        + {{ stop.passengersIn }}
                                        <img [src]="images.pax" alt="pax" class="h-4 w-4" />
                                    </div>
                                }
                                @if (stop.prmIn > 0) {
                                    <div class="flex justify-center items-center">
                                        + {{ stop.prmIn }}
                                        <img [src]="images.prm" alt="prm" class="h-4 w-4" />
                                    </div>
                                }
                                </article>
                            }
                            @if (stop.passengersOut > 0 || stop.prmOut > 0) {
                                <article class="secondary-orange-light-background w-[60px] rounded ps-2 pe-2 mr-2 cursor-pointer" (click)="showViewDropOffsEventEmitter.emit(stop.id)">
                                @if (stop.passengersOut > 0) {
                                    <div class="flex justify-center items-center">
                                        - {{ stop.passengersOut }}
                                        <img [src]="images.pax" alt="prm" class="h-4 w-4" />
                                    </div>
                                }
                                @if (stop.prmOut > 0) {
                                    <div class="flex justify-center items-center">
                                        - {{ stop.prmOut }}
                                        <img [src]="images.prm" alt="prm" class="h-4 w-4" />
                                    </div>
                                }
                                </article>
                            }
                            @if (stop.unexpectedPassengers !== 0) {
                                <article class="primary-green-2-background w-[50px] rounded ps-2 pe-2 mr-2">
                                    <div class="flex justify-center items-center">
                                        + {{ stop.unexpectedPassengers }}
                                    </div>
                                </article>
                            }
                            @if (stop.changeover) {
                                <article class="primary-purple-light-2-background w-[50px] rounded ps-2 pe-2 pt-1 pb-1 mr-2  cursor-pointer" (click)="showViewChangeOversEventEmitter.emit(stop.id)">
                                    <div class="flex justify-center items-center">
                                        <img [src]="images.changeover" alt="changeover" class="h-4 w-4" />
                                    </div>
                                </article>
                            }
                        </article>
                    }
                </div>
            </div>
        </article>
        <article class="bg-white">
            <div class="h-[1px] neutrals-grey-02-background ml-4 mr-4"></div>
        </article>
        <article class="bg-white pb-4">
            <div class="text-right pt-4 mr-4">
                @if (tripDetails.creatorName || tripDetails.createdAt) {
                    <p class="changelog primary-purple-light-text font-light text-sm">{{ literalService.get('created', true) }}
                        @if (tripDetails.creatorName) {
                            <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ tripDetails.creatorName }}</span>
                        }
                            {{ literalService.get('at') }} {{ utilsService.convertDate(tripDetails.createdAt) | date: 'dd/MM/yyyy HH:mm' }}
                    </p>
                }
                @if (tripDetails.modifierName || tripDetails.lastModifiedAt) {
                    <p class="changelog primary-purple-light-text font-light text-sm">{{ literalService.get('lastModification', true) }}
                        @if (tripDetails.modifierName) {
                            <span class="changelog font-light text-sm"> {{ literalService.get('by') }} {{ tripDetails.modifierName }}</span>
                        }
                            {{ literalService.get('at') }} {{ utilsService.convertDate(tripDetails.lastModifiedAt) | date: 'dd/MM/yyyy HH:mm' }}
                    </p>
                }
            </div>
        </article>
    }
</section>
