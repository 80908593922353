import { Injectable } from '@angular/core';
import { ServicesService, StorageService } from '../services';
import { ActivatedRouteSnapshot } from '@angular/router';
import { objectToCamel } from 'ts-case-convert';
import { IService, IServiceInfoDetail, ITown } from 'src/app/models';
import { initialPaginationState } from '../store/initialisation/pagination';
import { ServiceInfoService } from '../services/service-info/service-info.service';
import { initialServiceInfoDetailsState } from '../store/initialisation/service-infos';
import { initialServicesState } from '../store/initialisation/services';

@Injectable({
  providedIn: 'root',
})
export class RoutesResolver {

  constructor(private servicesService: ServicesService, public storageService: StorageService) {}

  async resolve(route: ActivatedRouteSnapshot, serviceInfos: number[]) {
    const page = route.data['routes']?.pagination.actualPage || 1;
    const size = route.data['routes']?.pagination.numberOfElements || 5;

    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.servicesService.getRoutesByServiceInfo(serviceInfos, page, size);

    return this.getData(
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
      page,
    );
  }

  getData(
    content: any,
    number_of_elements: any,
    total_elements: any,
    total_pages: any,
    first: any,
    last: any,
    page: any,
  ) {
    const data = initialServicesState;
    data.value = objectToCamel(content) as Array<IService>;
    data.isFetchingServices = false;
    data.pagination = {...initialPaginationState};
    data.pagination.numberOfElements = number_of_elements;
    data.pagination.totalPages = total_pages;
    data.pagination.totalElements = total_elements;
    data.pagination.actualPage = page;
    data.pagination.first = first;
    data.pagination.last = last;
    console.log(data)
    return data;
  }
}