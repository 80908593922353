<div id="toast-{{notification.type}}" class="absolute right-5 bottom-5 z-[60] w-2/5 p-4 mb-4 text-gray-500 rounded-lg shadow" role="alert"
     [ngClass]="{   'bg-green-100 dark:bg-green-700': notification.type === 'success',
                    'text-red-500 bg-red-100 dark:bg-red-700 dark:text-red-200': notification.type === 'danger',
                    'text-orange-500 bg-orange-100 dark:bg-orange-700 dark:text-orange-200': notification.type === 'warning',
                    'fadeIn': notification.show,
                    'fadeOut': !notification.show
                }">
    <div class="flex items-center justify-center flex-shrink-0">
        <img class="m-auto w-10 h-10" [src]="notification.image" alt="notificationImage" />
    </div>
    <div class="ml-3 h-auto" [class]="notification.link">
        <label class="font-bold text-lg">{{ notification.translate ? literalService.get(notification.title, true) : notification.title }}</label>
        <p class="text-sm font-normal m-auto">
            {{ notification.translate
                ? 
                    literalService.get(notification.message.toString(), true)
                : 
                    notification.message 
            }}
        </p>
        <a *ngIf="notification.link" [href]="notification.link" class="text-xs font-medium text-blue-400 hover:text-blue-600">View</a>
    </div>
    <button type="button" (click)="dismiss()" class="ml-auto h-auto -mx-1.5 -my-1.5 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex w-8 dark:text-gray-500 dark:hover:text-white"
            [attr.data-dismiss-target]="'#toast-'+notification.type" aria-label="Close">
        <img class="w-5 h-5 m-auto" [src]="images.notification.close" alt="close" />
    </button>
</div>