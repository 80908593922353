import { Injectable } from '@angular/core';
import { FavouritesHttpService } from './favourites.http.service';
import { lastValueFrom } from 'rxjs';
import { IBooking } from 'src/app/models';
import { objectToCamel } from 'ts-case-convert';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  constructor(private favouritesHttpService: FavouritesHttpService) { }

  getFavourites = async (userId: number) => {
    const source$ = this.favouritesHttpService.getFavourites(userId);
    const response = await lastValueFrom(source$);
    const favourites = objectToCamel(response as object);
    const favouriteArray = this.getData(favourites);
    return favouriteArray;
  };

  getFavourite = async (favouriteId: number) => {
    const source$ = this.favouritesHttpService.getFavourite(favouriteId);
    const response = await lastValueFrom(source$);
    const favourite = objectToCamel(response as object);
    return favourite;
  };

  createFavourite = async (favouriteName: string, booking: IBooking) => {
    const source$ = this.favouritesHttpService.createFavourite(favouriteName, booking);
    const response = await lastValueFrom(source$);
    const favourite = objectToCamel(response as object);
    return favourite;
  };
  
  deleteFavourite = async (favouriteId: number) => {
    const source$ = this.favouritesHttpService.deleteFavourite(favouriteId);
    const response = await lastValueFrom(source$);
    return response;
  };

  getData = (response: any) => {
    return response.data;
  };
}
