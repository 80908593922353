import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { FavouritesApiRoutes } from './favourites-api.routes';
import { IBooking } from 'src/app/models';
import { JWTTokenService } from '../auth/jwtToken.service';
import { objectToSnake } from 'ts-case-convert';

@Injectable({
  providedIn: 'root',
})
export class FavouritesHttpService extends BaseHttpService {
  private baseURL = `favorites`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
    private jwtTokenService: JWTTokenService,
  ) {
    super(httpClient);
  }

  getFavourites = (userId: number) => {
    const path = `${FavouritesApiRoutes.GET_USERS_FAVOURITES}/${userId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  };

  getFavourite = (favouriteId: number) => {
    const path = `/${favouriteId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  };
  
  deleteFavourite = (favouriteId: number) => {
    const path = `/${favouriteId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.delete(url);
  };

  createFavourite = (favouriteName: string, booking: IBooking) => {
    const body = objectToSnake({
      userId: booking.userId,
      name: favouriteName,
      townId: booking.service.town.id,
      passengers: booking.seats,
      prmPassengers: booking.prmSeats,
      time: booking.origin.time,
      inStopId: booking.origin.serviceStopId,
      outStopId: booking.destination.serviceStopId
    });
    const url = this.routerService.mount(`${this.baseURL}`);
    return this.create(body, url);
  };

}
