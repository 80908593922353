@if (isDocumentCreateRequest()) {
    <div class="border border-black rounded-[50px] document-request-tag w-[8rem] mr-1 mb-1 flex items-center justify-between">
        <label class="block truncate ml-1">{{document.fileName}}</label>
        <img [src]="images.close" class="cursor-pointer h-4 w-4 ml-auto" (click)="onDelete($event)"/>
    </div>
} @else {
    <div class="bg-white border border-black rounded-[50px] p-1 w-[150px] flex items-center" (click)="onClick()">
        <label class="block truncate cursor-pointer ml-1">{{document.fileName}}</label>
        <img [src]="images.close" class="cursor-pointer h-4 w-4 ml-auto" (click)="onDelete($event)"/>
    </div>
}