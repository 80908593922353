<div [id]="data.title"  class ="pt-5 pb-2 pr-5 pl-5" [ngClass]="{'primary-purple-light-2-background transition duration-300 ease-in-out rounded-md':  (isHovering || isHoveringOriginDestinationDetail && (data.title === 'bookings.data.originStop' || data.title === 'bookings.data.destinationStop'))}" (mouseover)="onMouseEnter($event)" (mouseout)="onMouseLeave($event)" (click)="onClick()">
    @if (!data.component) {
        <div class="flex items-center mb-2">
            <img [src]="data.img" [ngClass]="{'imgColor': data.setColor === undefined}" alt="detailImage" class="h-5 w-5 mr-1" />
            <label class="text-base font-normal">{{ literalService.get(data.title, true) }}{{ data.required && '*' || ''}}</label>
            <img [src]="images.booking.actions.editCircle" *ngIf="data.img" alt="hoverImage" class="h-6 w-6 ml-auto" [ngClass]="{ 'hidden': !(isHovering || isHoveringOriginDestinationDetail && (data.title === 'bookings.data.originStop' || data.title === 'bookings.data.destinationStop')) }" />
        </div>
        @if (!data.array) {
            <div [id]="'text' + data.title" class="border-b mb-2 mt-1 truncate w-64">
                <label class="text-base font-semibold" [tooltip]="utilsService.visibleTooltip('text' + data.title) ? text : ''" [visibleTooltip]="utilsService.visibleTooltip('text' + data.title)" [position]="TooltipPosition.DYNAMIC">
                    {{ text ? data.translateValue ? literalService.get(text, true) : text : '-' }}
                </label>
            </div>
        } @else {
            <div [id]="'textArray' + data.title" class="border-b mb-2 mt-1 truncate w-64">
                <label class="text-base font-semibold" [tooltip]="utilsService.visibleTooltip('textArray' + data.title) ? textValues : ''" [visibleTooltip]="utilsService.visibleTooltip('textArray' + data.title)" [position]="TooltipPosition.DYNAMIC">
                    {{ textValues }}
                </label>
            </div>
        }
        @if (data.extraText && data.extraValue) {
            <label class="font-normal text-sm primary-purple-light-text">
                {{ literalService.get(data.extraText, true)}} {{ data.extraValue }} {{ data.extraText.includes('requestedTimeDiff') ? 'min' : '' }}
            </label>
        }
    } @else if (data.component === ServiceTagComponent) {
        <div class="flex items-center mb-2">
            <img [src]="data.img" [ngClass]="{'imgColor': data.setColor === undefined}" alt="detailImage" class="h-5 w-5 mr-1" />
            <label class="text-base font-normal">{{ literalService.get(data.title, true) }}{{ data.required && '*' || ''}}</label>
        </div>
        <div [id]="'text' + data.title" class="flex border-b mb-2 mt-1 pb-1 truncate w-64 items-center">
            <app-service-tag [serviceInfo]="value" />
            <label class="text-base font-semibold ml-2">{{ value && value.name }}</label>
        </div>
    } @else if (data.component === BookingSeatsComponent) {
        <div class="flex items-center mb-2">
            <img [src]="data.img" [ngClass]="{'imgColor': data.setColor === undefined}" alt="detailImage" class="h-5 w-5 mr-1" />
            <label class="text-base font-normal">{{ literalService.get(data.title, true) }}{{ data.required && '*' || ''}}</label>
            <img [src]="images.booking.actions.editCircle" *ngIf="data.img" alt="hoverImage" class="h-6 w-6 ml-auto" [ngClass]="{ 'hidden': !isHovering }" />
        </div>
        <div [id]="'text' + data.title" class="flex border-b mb-2 mt-1 pb-1 truncate w-64 items-center">
            <app-booking-seats [booking]="value" />
        </div>
    }
</div>    
