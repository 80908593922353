import { Injectable } from '@angular/core';
import { ServiceHttpService } from './service.http.service';
import { objectToCamel } from 'ts-case-convert';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private serviceHttpService: ServiceHttpService) { }

  async getAvailabilities(serviceId: number, page?: number, size?: number) {
    const source$ = this.serviceHttpService.getAvailablities(serviceId, page, size);
    const response = await lastValueFrom(source$);
    const users = objectToCamel(response as object);
    const usersArray = this.getData(users);
    return usersArray;
  }
  
  async getDestinationAvailabilityStops(availabilityId: number, inStopId: number, page: number, size: number) {
    const source$ = this.serviceHttpService.getAvailablityStops(availabilityId, page, size, inStopId);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }
  
  async getAvailabilityStops(availabilityId: number, page: number, size: number) {
    const source$ = this.serviceHttpService.getAvailablityStops(availabilityId, page, size);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }
  
  getCurrentDomain(): string {
    return window.location.origin;
  }
  
  getData(response: any) {
    return response.data;
  }
}
