import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';

@Injectable({
  providedIn: 'root',
})
export class RolesHttpService extends BaseHttpService {
  private baseURL = `users`;

  constructor(
      @Inject(HttpClient) httpClient: HttpClient,
      private routerService: ApiRouterService,
    ) {
      super(httpClient);
    }

    public getUserPermissions() {
        const path = `/me/permissions`;
        const url = this.routerService.mount(`${this.baseURL}${path}`);
        return this.read(url);
    }
}
