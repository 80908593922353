import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';

@Injectable({
  providedIn: 'root',
})
export class ServiceHttpService extends BaseHttpService {
  private baseURL = `services`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getAvailablities(serviceId: number, page?: number, size?: number,) {
    const url = this.routerService.mount(`${this.baseURL}/${serviceId}/availabilities?page=${page}&size=${size}`);
    return this.read(url);
  }

  public getAvailablityStops(availabilityId: number, page: number, size: number, inStopId?: number) {
    const inStopIdString = inStopId ? `&in_stop_id=${inStopId}` : '';
    const url = this.routerService.mount(`${this.baseURL}/${availabilityId}/availability-stops?page=${page}&size=${size}${inStopIdString}`);
    return this.read(url);
  }

}
