import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { IServiceInfoDetail, ITown } from 'src/app/models';
import { objectToSnake } from 'ts-case-convert';

@Injectable({
  providedIn: 'root',
})
export class ServiceInfoHttpService extends BaseHttpService {
  private baseURL = `service-infos`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getServiceInfos(size: number, page: number, towns?: ITown[]) {
    const townIds = towns && towns.map((town: ITown) => town.id);
    let url = this.routerService.mount(`${this.baseURL}?page=${page}&size=${size}`);
    url += townIds ? `&towns=${townIds}` : '';
    return this.read(url);
  }

  public getServiceInfo(serviceId: number) {
    const url = this.routerService.mount(`${this.baseURL}/${serviceId}`);
    return this.read(url);
  }

  public createService(newService: IServiceInfoDetail) {
    const url = this.routerService.mount(`${this.baseURL}`);
    const body = objectToSnake(
      {
        name: newService.name,
        tag: newService.tag,
        color: newService.color,
        forwardDirection: newService.forwardDirection,
        backwardsDirection: newService.backwardsDirection,
        showTheoreticalTimes: newService.showTheoreticalTimes,
        showRealTimes: newService.showRealTimes,
        sortCriteria: newService.sortCriteria,
        townId: Number(newService.town),
        clientId: newService.client?.id
      }
    );
    return this.create(body, url);
  }
}
