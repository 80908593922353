<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-1/2 max-h-full rounded-lg primary-purple-light-2-background">
        <div class="flex">
            <div class="w-1/2 p-8">
                <h1 class="font-bold text-[18px] primary-purple-text mb-4">{{ literalService.get('users.documents.modal.uploadedDocuments', true)}}</h1>
                @if (getDocumentTypes().length === 0) {
                    <div class="flex flex-col justify-between items-center w-48 h-48 px-6 mt-6">
                        <img [src]="images.noDocuments" alt="noDocuments" class="h-32 w-32"/>
                        <label class=" text-center text-[16px] neutrals-grey-04-text">
                            {{ literalService.get('users.documents.modal.noDocuments', true)}}
                        </label>
                    </div>
                }
                <div *ngFor="let docType of getDocumentTypes()">
                    <h4 class="text-black font-semibold my-2">{{ literalService.get('users.documents.'+docType, true)}}</h4>
                    <ul>
                      <li class="mb-2" *ngFor="let doc of documents[docType]">
                        <app-document-tag [document]="doc" (delete)="this.deleteAction.emit($event)" (download)="this.downloadDocument.emit($event)"/>
                      </li>
                    </ul>
                  </div>
            </div>
            <div class="w-1/2 p-8">
                <h1 class="font-bold text-[18px] primary-purple-text">{{ literalService.get('users.documents.modal.addDocuments', true)}}</h1>
                <h4 class="text-black font-semibold my-2">{{ literalService.get('users.documents.modal.firstStep', true)}}</h4>
                <app-select [elements]="getAllDocumentTypes()" [id]="getAllDocumentTypes()[0].id" (change)="changeType($event.target)"/>
                <h4 class="text-black font-semibold my-2">{{ literalService.get('users.documents.modal.secondStep', true)}}</h4>
                <div>
                    <input type="file" #fileInput multiple accept=".png, .jpg, .jpeg, .pdf" (change)="onSelectFile($event)" hidden />
                  </div>
                <div class="dashed-border mb-3 bg-white cursor-pointer" (click)="fileInput.click()">
                    <img [src]="images.document" alt="editUser" class="h-9 w-9 mb-3" />
                    <label class="upload-button-text font-semibold text-base cursor-pointer">{{ literalService.get('users.documents.modal.uploadFiles', true)}}</label>                
                </div>
                <div class="flex flex-wrap mb-2">
                    @for (documentCreate of documentCreateRequests; track $index) {
                        <app-document-tag [document]="documentCreate" (delete)="deleteDocumentCreateRequest($event)" (download)="downloadDocument.emit($event)"/>
                    }                
                </div>
                <div class="flex justify-between">
                    <input type="button" class="secondary gap-4 border-2 primary-purple-text font-semibold h-14 w-32 uppercase cursor-pointer" [value]="literalService.get('cancel', true)" (click)="onClose()" />
                    <input type="button" class="primary gap-4 border-2 text-white font-semibold h-14 w-32 uppercase cursor-pointer" (click)="onUpload()" [value]="literalService.get('add', true)" [disabled]="documentCreateRequests.length === 0" />
                </div>
            </div>
        </div>
    </div>
</div>