import { images } from '../../images';
import { IRouteDetails } from '../models';
import { InputType } from '../models/enums';
import { StorageService, TownsService } from '../services';
import { LiteralService } from '../services/literal/literal.service';
import { formData } from './routeData';

// export function getShowTimesValues(serviceInfoDetail?: IServiceInfoDetail, literalService?: LiteralService): unknown[] {
//   if (serviceInfoDetail) {
//     const data = [];
//     serviceInfoDetail.showTheoreticalTimes && data.push({ id: literalService?.get('showTheoreticalTimes', true), name: literalService?.get('showTheoreticalTimes', true), code: literalService?.get('showTheoreticalTimes', true) });
//     serviceInfoDetail.showRealTimes && data.push({ id: literalService?.get('showRealTimes', true), name: literalService?.get('showRealTimes', true), code: literalService?.get('showRealTimes', true) });
//     return data;
//   }
//   return [
//     { id: literalService?.get('showTheoreticalTimes', true), name: literalService?.get('showTheoreticalTimes', true), code: literalService?.get('showTheoreticalTimes', true) },
//     { id: literalService?.get('showRealTimes', true), name: literalService?.get('showRealTimes', true), code: literalService?.get('showRealTimes', true) }
//   ];
// }

export function getNotificationsValues(route?: IRouteDetails): unknown[] {
  const notifications = [
    { id: 'sms', name: 'SMS', code: 'SMS' },
    { id: 'email', name: 'EMAIL', code: 'EMAIL' },
    { id: 'push', name: 'PUSH', code: 'PUSH' },
  ];

  return route
    ? notifications.filter(notification => route[notification.id as keyof Object])
    : notifications;
}

export function getOtherFunctionalities(serviceInfoDetail?: IRouteDetails): unknown[] {
  if (serviceInfoDetail) {
    const data: any[] = [];
    // serviceInfoDetail.informative && data.push({ id: 'informative', name: 'informative', code: 'informative' });
    // serviceInfoDetail.showBusPosition && data.push({ id: 'informative', name: 'informative', code: 'informative' });
    console.log(data)
    return data;
  }
  return [
      { id: "showOnlyEcoRoutes", value: "valueA", label: "showOnlyEcoRoutes" },
      { id: "informative", value: 'informative', label: "informative" },
      { id: "busPosition", value: 'showBusPosition', label: "busPosition" },
      { id: "showUsers", value: "valueC", label: "showUsers" }
    ]
}

export async function getRouteForm(route: IRouteDetails, townsService: TownsService, literalService: LiteralService, storageService: StorageService): Promise<unknown[]> {
  // const towns = await townsService.getTowns();
  // console.log(towns)
  const notifications = getNotificationsValues();
  console.log(notifications)
  const data: formData[] = [
    {
      img: images.services.routes.area,
      title: 'services.routes.data.area',
      value: route.townId,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'area',
      },
    },
    {
      img: images.services.routes.client,
      title: 'services.routes.data.client',
      // value: route.client,
      value: '',
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'client',
      },
    },
    {
      img: images.services.routes.visibility,
      title: 'services.routes.data.visibility',
      value: route.visibility,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'visibility',
      },
    },
    {
      img: images.services.routes.ticketing,
      title: 'services.routes.data.ticketingType',
      value: route.ticketingType,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'ticketingType',
      },
    },
    {
      img: images.services.routes.additionalClients,
      title: 'services.routes.data.additionalClientIds',
      value: route.additionalClientIds,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'additionalClientIds',
      },
    },
    {
      img: images.services.routes.apps,
      title: 'services.routes.data.appIds',
      value: route.appIds,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'appIds',
      },
    },
    {
      img: images.services.routes.publishingSites,
      title: 'services.routes.data.publishingSites',
      value: route.publishingSites,
      required: false,
      expanded: false,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'publishingSites',
      },
    },
    {
      img: images.services.routes.notifications,
      title: 'services.routes.data.notificationsConfigurations',
      value: getNotificationsValues(route),
      array: true,
      required: false,
      expanded: true,
      edit: {
        inputType: InputType.MULTISELECT,
        required: false,
        values: notifications,
        selectedValues: getNotificationsValues(route),
        valueToChange: 'notificationsConfigurations',
      },
    },
    {
      img: images.services.routes.config,
      title: 'services.routes.data.routeConfig',
      value: '',
      required: false,
      expanded: true,
      edit: {
        inputType: InputType.MULTISELECT,
        required: false,
        valueToChange: 'routeConfig',
      },
    },
    {
      img: images.services.routes.times,
      title: 'services.routes.data.maxVariability',
      value: route.maxVariationTime,
      required: false,
      expanded: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'maxVariability',
      },
    },
    {
      img: images.services.routes.times,
      title: 'services.routes.data.maxRouteTime',
      value: route.maxPathTime,
      required: false,
      expanded: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'maxRouteTime',
      },
    },
    {
      img: images.services.routes.times,
      title: 'services.routes.data.minRouteTime',
      value: route.minPathTime,
      required: false,
      expanded: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'minRouteTime',
      },
    }
  ];
  return data;
}