<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    @if (booking) {
        <div class="relative w-[345px] max-h-full rounded-lg primary-purple-light-2-background">
            <div class="relative rounded-lg p-[25px] shadow dark:bg-gray-700">
                <div class="flex justify-center items-center">
                    <h3 class="w-full text-lg font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('bookings.actions.notifiedBooking.title', true) }}</h3>
                    <button (click)="modal.hide()" type="button" class="absolute right-2 mr-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="notifiedBooking">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                </div>
                <div class="mt-6">
                    <div class="flex items-center mb-4">
                        <img [src]="images.phone" class="w-6 h-6 mr-4" alt="phone"/>
                        <label class="font-normal text-base">{{ booking.userPhone || '-' }}</label>
                    </div>
                    <div class="flex items-center mb-4">
                        <img [src]="images.usr" class="w-6 h-6 mr-4" alt="phone"/>
                        <label class="font-normal text-base">{{ booking.firstName }} {{ booking.lastName }}</label>
                    </div>
                    <div class="flex items-center mb-2">
                        <img [src]="images.clock" class="w-6 h-6 mr-4" alt="phone"/>
                        <label class="font-normal text-base">{{ literalService.get('bookings.actions.notifiedBooking.pickupTime', true) }}:</label>
                    </div>
                    <div class="h-[75px] w-full rounded py-2 px-4 bg-white">
                        <div class="grid grid-cols-12 h-full items-center">
                            <div class="col-span-4 border-r-2 primary-purple-light-border">
                                <p class="font-semibold text-sm text-center mb-1">{{ literalService.get('bookings.actions.notifiedBooking.desired', true) }}</p>
                                <p class="font-normal text-sm text-center">{{ booking.requestedTime }}</p>
                            </div>
                            <div class="col-span-4 border-r-2 primary-purple-light-border">
                                <p class="font-semibold text-sm text-center mb-1">{{ literalService.get('bookings.actions.notifiedBooking.actual', true) }}</p>
                                <p class="font-normal text-sm text-center">{{ booking.initialOriginTime }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-semibold text-sm text-center mb-1">{{ literalService.get('bookings.actions.notifiedBooking.difference', true) }}</p>
                                <p class="font-normal text-sm text-center">{{ booking.requestedTimeDiff }} min</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex rounded w-full h-12 items-center">
                        <input type="checkbox" class="mr-2" [checked]="booking.isNotified" (change)="booking.isNotified = !booking.isNotified" />
                        <label class="font-normal text-base neutrals-grey-06-text">{{ literalService.get('bookings.actions.notifiedBooking.setBookingAsNotified', true) }}</label>
                    </div>
                </div>
                <div class="flex justify-center items-center mt-10">
                    <button type="button" class="secondary gap-4 border font-semibold h-10 w-full uppercase cursor-pointer mr-1" data-modal-hide="notifiedBooking" (click)="modal.hide()">{{ literalService.get('close', true) }}</button>
                    <button type="button" class="primary gap-4 border-2 font-semibold h-10 w-full uppercase cursor-pointer text-white" [disabled]="booking.tripStatus == ExpeditionStatus.OPEN" (click)="action.emit(booking.isNotified)" data-modal-hide="notifiedBooking">{{ literalService.get('accept', true) }}</button>
                </div>
            </div>
        </div>
    }
</div>