import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Modal } from 'flowbite';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import {
  IBase,
  IBooking,
  IServiceInfo,
  ITown,
  ITrip,
  ITripDetail,
  ITripStop,
  IUserDriver,
  IVehicle
} from 'src/app/models';
import { ExpeditionStatus, ExpeditionType, NotificationTypes } from 'src/app/models/enums';
import { ITripPosition } from 'src/app/models/trip/tripPosition';
import { TripsResolver } from 'src/app/resolvers';
import {
  BookingsService,
  MapUtilsService,
  NotificationService,
  RoleService,
  StorageService,
  TownsService,
  TripsService,
  UsersService,
  UtilsService,
} from 'src/app/services';
import { ApiRouterService } from 'src/app/services/http/api.router';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { initialTripsState } from 'src/app/store/initialisation/trips';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss'],
})
export class TripsComponent implements OnInit, AfterViewInit, OnDestroy {
  public trips = initialTripsState;
  public controller = new AbortController();
  public images = images;
  public map: google.maps.Map;
  public dataLayer: google.maps.Data;
  public selectedTrip: ITrip | undefined;
  public tripDetails: ITripDetail | undefined;
  public stops: any[] = [];
  public townStops: any[] = [];
  public townMarkers: HTMLElement[] = [];
  public bounds: google.maps.LatLngBounds;
  public towns: ITown[] = [];
  public selectedTowns: ITown[] = [];

  public drivers: IUserDriver[] = [];
  public vehicles: IVehicle[] = [];
  public bookings: IBooking[] = [];

  public clientsSelected: IBase[] = [];
  public operatorsSelected: IBase[] = [];
  //public townsSelected: IBase[];
  public typesSelected: ExpeditionType[] = [];
  public statusSelected: ExpeditionStatus[] = [];
  public searchValue: string;

  public changeDriver: Modal;
  public changeDriverShowed: boolean = false;
  public driverSelected?: number;

  public changeVehicle: Modal;
  public changeVehicleShowed: boolean = false;
  public vehicleSelected?: number;

  public addPax: Modal;
  public addPaxShowed: boolean = false;
  public nPax?: number;

  public setReady: Modal;
  public setReadyShowed: boolean = false;

  public setClosed: Modal;
  public setClosedShowed: boolean = false;

  public deleteTrip: Modal;
  public deleteTripShowed: boolean = false;
  public deletingTrip = false;
  public reason: string;

  public deleteBooking: Modal;
  public deleteBookingShowed: boolean = false;
  public deletingBooking = false;
  public bookingToDelete: number;
  public comment: string;

  public viewBookings: Modal;
  public viewBookingsShowed: boolean = false;
  public showDate = false;
  private routerSubscription: Subscription;

  public tripSelected: ITrip;
  public path: any;

  public getTripInterval: any;
  public getVehiclesInterval: any;
  public getBookingsInterval: any;

  public searching = false;

  public serviceInfos: IServiceInfo[] = [];

  public navigationSubscription: Subscription;

  constructor(
    public tripsService: TripsService,
    public routerService: ApiRouterService,
    public literalService: LiteralService,
    public utilsService: UtilsService,
    public mapUtils: MapUtilsService,
    public usersService: UsersService,
    public bookingsService: BookingsService,
    public notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService,
    public tripsResolver: TripsResolver,
    public activatedRoute: ActivatedRoute,
    private titleService: Title,
    public router: Router,
    public appComponent: AppComponent,
    private townsService: TownsService,
    public mapUtilsService: MapUtilsService,
    public roleService: RoleService
  ) {
  }

  async ngOnInit() {
    const town = await this.usersService.getLoggedUserTown();
    const coords = { lat: town.latitude ? town.latitude : 41.4696546, lng: town.longitude ? town.longitude : 2.0596017 };
    await this.storageService.getArea().then((areas: string) => {
      const parsedAreas = JSON.parse(areas ?? '[]');
      if (parsedAreas && parsedAreas.length > 0) {
        this.selectedTowns = parsedAreas;
      } else {
        this.storageService.setArea(JSON.stringify([town]));
        this.selectedTowns = [town];
      }
      if (this.selectedTowns.length > 0) {
        if (this.selectedTowns.length == 1) {
          this.setCenter({lat: this.selectedTowns[0].latitude, lng: this.selectedTowns[0].longitude});
          this.drawServiceInfos();
        }
        else {
          this.setCenter(coords).then(() => this.selectedTownsZoom());
        }
      }
      else this.setCenter(coords);
    }, () => {
      this.setCenter(coords);
    });
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort(
        (firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!,
      );
      this.towns = res.towns;
    });
    
    this.setVehiclesInterval();
    
    this.mapUtils.setUserCurrentPosition(this.map, this.images.user.name);
    
    this.titleService.setTitle(this.literalService.get('sidebar.trips', true) + ' - NEMI Backoffice');

    // Retrieve the object ID from the route parameters
    this.activatedRoute.params && this.activatedRoute.params.subscribe((params) => {
      const tripId: number = params['id'];
      tripId && this.getTrip(tripId);
    });
   
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the modal when navigating away from the current view
        this.closeModals();
      }
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearMap();
        this.ngOnDestroy();
        this.reload();
      }
    });
  }

  reload() {
    this.selectedTrip = undefined;
  }

  async selectedTownsZoom(): Promise<void> {
    if (this.selectedTowns.length > 0) {
      const padding = { top: 30, bottom: 30, left: 550, right: 0 };
      const bounds = new google.maps.LatLngBounds();
      this.selectedTowns.map((town: ITown) => {
        if (town.latitude && town.longitude) {
          bounds.union(new google.maps.LatLngBounds(
            new google.maps.LatLng(town.latitude, town.longitude)
          ));
        }
      });
      this.map.fitBounds(bounds, padding);
      this.map.setZoom(Math.min(this.map.getZoom()!, 15));
    } /*else {
      // TODO I wpuld say this should only be done at the beggining. It goes slow and it seems buggy when using the app
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            this.map.setCenter(pos);
          }
        )
      }
    }*/
  }

  async setCenter(coords: any) {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
        center: coords,
        zoom: 15,
        mapId: environment.mapId,
    });
    this.map.addListener('click', () => {
      this.mapUtilsService.clearInfoWindows();
    });
  }

  ngAfterViewInit(): void {
    this.initializeModals();
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && this.dataLayer && this.map) {
        this.dataLayer.setMap(null);
        this.dataLayer.setMap(this.map);
      }
    });
  }

  trackByFn(index: number): any {
    return index;
  }

  getColor(stop: ITripStop): string {
    if (stop.realTime) return '#b7bdcc';
    return this.selectedTrip && this.selectedTrip.service && this.selectedTrip.service.color !== '#FFFFFFFF' ?
      this.selectedTrip.service.color :
      '#000000';
  }  

  async refreshTrips(event: {snapshot: ActivatedRouteSnapshot, clientsSelected: IBase[], operatorsSelected: IBase[], typesSelected: ExpeditionType[], statusSelected: ExpeditionStatus[], searchValue: string}) {
    this.searching = true;
    this.activatedRoute.snapshot = event.snapshot;
    this.activatedRoute.snapshot.data['trips'] = this.trips;
    this.clientsSelected = event.clientsSelected;
    this.operatorsSelected = event.operatorsSelected;
    this.typesSelected = event.typesSelected;
    this.statusSelected = event.statusSelected;
    this.searchValue = event.searchValue;
    await this.getTrips();
    this.searching = false;
  }

  async getTrips() {
    await this.tripsResolver.getTrips(
      this.activatedRoute.snapshot,
      this.clientsSelected,
      this.operatorsSelected,
      this.selectedTowns,
      this.typesSelected,
      this.statusSelected,
      this.searchValue
    );
  }

  setVehiclesInterval() {
    const townIds = this.selectedTowns.map(town => town.id!);
    this.getTownsRealTimePositions(townIds);
    this.getVehiclesInterval = setInterval(() => {
      const townIds = this.selectedTowns.map(town => town.id!);
      this.getTownsRealTimePositions(townIds);
    }, 10000);
  }

  async getTownsRealTimePositions(townsIds: number[]) {
    if (townsIds.length <= 0) return;
    const mapUtils = this.mapUtils;
    const map = this.map;
    await this.tripsService.getTownsPositions(townsIds).then((townPositions: ITripPosition[]) => {
      townPositions.map((bus: any) => {
        mapUtils.addMarker(map, bus, images.bus, false, 50);
      });
    });
  }

  async getTripRealTimePositions(tripId: number) {
    if (!tripId) return;
    const mapUtils = this.mapUtils;
    const map = this.map;
    await this.tripsService.getTripPosition(tripId).then((tripPosition: ITripPosition) => {
      tripPosition && mapUtils.addMarker(map, tripPosition, images.bus, false, 50);
    });
  }

  async getTripDetails(tripId: number) {
    try {
      await this.tripsService
      .getTrip(tripId)
      .then((tripRecieved: ITripDetail) => {
        this.selectedTrip = tripRecieved;
        this.tripSelected = tripRecieved;
        const index = this.trips.value.findIndex((trip: ITrip) => trip.id === tripRecieved.id);
        if (index && index != -1) this.trips.value[index] = tripRecieved;
        if (this.redrawTrip(tripRecieved)) {
          this.tripDetails = tripRecieved;
          this.changeDetectorRef.detectChanges();
          if (this.dataLayer) this.dataLayer.forEach((feature: any) => this.dataLayer.remove(feature));
          else {
            this.dataLayer = new google.maps.Data();
            this.dataLayer.setMap(this.map);
          }
          this.dataLayer.setStyle({
            strokeColor: this.selectedTrip && this.selectedTrip.service && this.selectedTrip.service.color !== '#FFFFFFFF' ? this.selectedTrip.service.color : "#000000",
            strokeWeight: 5,
          });
          this.dataLayer.addGeoJson(tripRecieved.path);
          this.updateSegmentsColors(tripRecieved);

          this.path = tripRecieved.path;
          const bounds = new google.maps.LatLngBounds();
          this.path.features.map((feature: any) => {
            bounds.union(
              new google.maps.LatLngBounds(
                new google.maps.LatLng(
                  feature.geometry.coordinates[0][1],
                  feature.geometry.coordinates[0][0],
                ),
              ),
            );
          });
          bounds.extend(
              new google.maps.LatLng(
                this.path.features.at(-1).properties.end.geometry.coordinates[1],
                this.path.features.at(-1).properties.end.geometry.coordinates[0]            
            ),
          )
          const padding = { top: 40, bottom: 30, left: 700, right: 10 };

          setTimeout(() => {
            this.map.fitBounds(bounds, padding);
          }, 500);
          this.addOriginDestinationMarkers();
          this.addStopMarkers();
        } else {
          this.tripDetails = tripRecieved;
          this.updateSegmentsColors(tripRecieved);
          this.changeDetectorRef.detectChanges();
        }
        this.getTripRealTimePositions(tripId);
      });
    } catch {
      this.router.navigate(['/trips']);
    }
  }

  redrawTrip = (tripRecieved: ITripDetail) => {
    if (!this.tripDetails) return true;
    if (this.tripDetails.stops.length != tripRecieved.stops.length) return true;
    if (this.tripDetails.stops.some((item1, index) => item1.id != tripRecieved.stops[index].id)) return true;
    return false;
  };

  updateSegmentsColors(tripRecieved: ITripDetail) {
    this.dataLayer.forEach((feature: any) => {
      feature.toGeoJson((ft: any) => {
        const endSegmentStopId = ft.properties.end.properties.id;
        const endSegmentStop = tripRecieved.stops.find((stop) => stop.id === endSegmentStopId);
        if (endSegmentStop?.realTime) {
          this.dataLayer.overrideStyle(feature, {
            strokeColor: '#b7bdcc'
          })
        }
      });
    });
  }

  async getTrip(tripId: number) {
    this.selectedTrip = this.trips.value.find(
      (trip: ITrip) => tripId === trip.id,
    )!;
    this.getTripDetails(tripId);
    this.getTripInterval = setInterval(() => {
      this.getTripDetails(tripId);
    }, 10000);
    clearInterval(this.getVehiclesInterval);
    this.showTownMarkers(false);
  }

  addOriginDestinationMarkers = (clear: boolean = false) => {
    const markers = [];
    const markerOrigin = document.getElementById('origin') as HTMLElement;
    const markerDestination = document.getElementById('destination') as HTMLElement;
    
    markers.push({
      id: this.tripDetails?.stops.at(-1)?.id,
      name: this.tripDetails?.stops.at(-1)?.name,
      element: markerDestination,
      latitude:
        this.tripDetails!.stops.at(-1)?.latitude,
      longitude:
        this.tripDetails!.stops.at(-1)?.longitude,
    });
    markers.push({
      id: this.tripDetails?.stops[0].id,
      name: this.tripDetails?.stops[0].name,
      element: markerOrigin,
      latitude: this.tripDetails!.stops[0].latitude,
      longitude: this.tripDetails!.stops[0].longitude,
    });

    this.mapUtils.addCustomMarkers(this.map, markers, clear);
  };

  addStopMarkers = (clear: boolean = false) => {
    this.stops = [];
    if (!clear) {
      this.tripDetails!.stops.map((stop: any, index: number) => {
        if (index === 0) return;
        const marker = document.getElementById('stop' + index) as HTMLElement;
        this.stops.push({
          id: stop.id,
          name: stop.name,
          element: marker,
          latitude: stop.latitude,
          longitude: stop.longitude,
        });
      });
    }
    this.stops.pop();
    this.mapUtils.addCustomMarkers(this.map, this.stops, clear);
  };

  async centerToTheTown(event: any) {
    if (event.target.checked) {
      this.selectedTowns.push(
        this.towns.find((town: ITown) => town.id == event.target.value)!,
      );
    } else {
      const index = this.selectedTowns.findIndex(
        (town: ITown) => town.id == event.target.value,
      );
      this.selectedTowns.splice(index, 1);
    }

    this.selectedTownsZoom();
    this.storageService.removeArea();
    this.storageService.setArea(JSON.stringify(this.selectedTowns));

    this.getTrips();
    this.clearTownMap();
    this.serviceInfos = [];
    if (this.selectedTowns && this.selectedTowns.length === 1) {
      this.drawServiceInfos();
    }
    clearInterval(this.getVehiclesInterval);
    this.setVehiclesInterval();
  }

  drawServiceInfos = async () => {
    this.serviceInfos = await this.townsService.getServices(this.selectedTowns[0].id!);
    this.mapUtilsService.drawOutlines(this.map, this.serviceInfos);
    this.bounds = new google.maps.LatLngBounds();
    this.selectedTowns.forEach((town: ITown) => {
      this.bounds.union(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(
            town.latitude,
            town.longitude,
          ),
        ),
      );  
    });
    const padding = { top: 30, bottom: 20, left: 500, right: 0 };
    this.map.fitBounds(this.bounds, padding);
    this.map.setZoom(14);
  };

  showTownMarkers = (show: boolean) => {
    if (this.townMarkers.length > 0) {
      this.mapUtilsService.showClusterMarkers(show);
    }
  };

  clearTownMap = () => {
    this.townMarkers = [];
    this.mapUtilsService.clearPolygons();
    this.mapUtilsService.clearClustersMarkers();
  };

  clearMap = () => {
    this.addOriginDestinationMarkers(true);
    this.addStopMarkers(true);
    this.map.data.forEach((feature: any) => this.map.data.remove(feature));
    this.dataLayer.forEach((feature: any) => this.dataLayer.remove(feature));
    clearInterval(this.getTripInterval);
    this.setVehiclesInterval();
    this.selectedTrip = undefined;
    this.tripDetails = undefined;
    this.showTownMarkers(true);
    this.selectedTownsZoom();
  };

  initializeModals = async() => {
    this.changeDriver = new Modal(document.getElementById('changeDriver'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.changeDriverShowed = false),
      onShow: () => (this.changeDriverShowed = true),
    });
    this.changeVehicle = new Modal(document.getElementById('changeVehicle'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.changeVehicleShowed = false),
      onShow: () => (this.changeVehicleShowed = true),
    });
    this.addPax = new Modal(document.getElementById('addPax'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.addPaxShowed = false),
      onShow: () => (this.addPaxShowed = true),
    });
    this.setReady = new Modal(document.getElementById('setReady'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.setReadyShowed = false),
      onShow: () => (this.setReadyShowed = true),
    });
    this.setClosed = new Modal(document.getElementById('setClosed'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.setClosedShowed = false),
      onShow: () => (this.setClosedShowed = true),
    });
    this.deleteTrip = new Modal(document.getElementById('deleteTrip'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.deleteTripShowed = false);
        this.reason = '';
      },
      onShow: () => (this.deleteTripShowed = true),
    });
    this.viewBookings = new Modal(document.getElementById('viewBookings'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        clearInterval(this.getBookingsInterval);
        this.viewBookingsShowed = false;
      },
      onShow: () => (this.viewBookingsShowed = true),
    });
    this.deleteBooking = new Modal(document.getElementById('deleteBooking'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.deleteBookingShowed = false);
        this.comment = '';
      },
      onShow: () => (this.deleteBookingShowed = true),
    });
  };

  closeModals = () => {
    if (this.changeDriverShowed) this.changeDriver.toggle();
    if (this.changeVehicleShowed) this.changeVehicle.toggle();
    if (this.addPaxShowed) this.addPax.toggle();
    if (this.setReadyShowed) this.setReady.toggle();
    if (this.setClosedShowed) this.setClosed.toggle();
    if (this.deleteTripShowed) this.deleteTrip.toggle();
    if (this.viewBookingsShowed) this.viewBookings.toggle();
    if (this.deleteBookingShowed) this.deleteBooking.toggle();
  };

  changeDriverAction = async (id: any) => {
    const index = this.trips.value.findIndex(
      (trip: ITrip) => trip.id === this.tripSelected.id,
    );
    const tripDetail = await this.tripsService.changeDriver(
      this.tripSelected.id!,
      id,
    );
    this.trips.value[index] = tripDetail;
    if (this.selectedTrip) {
      this.selectedTrip = tripDetail;
      this.tripDetails = tripDetail;
    }
    this.changeDriver.toggle();
    this.notificationService.image = images.trips.driver;
    this.notificationService.title = this.literalService.get(
      'trips.actions.changeDriver.title',
      true,
    );
    this.notificationService.message = this.literalService.get(
      'trips.actions.changeDriver.text',
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  changeVehicleAction = async (id: any) => {
    const index = this.trips.value.findIndex(
      (trip: ITrip) => trip.id === this.tripSelected.id,
    );
    const tripDetail = await this.tripsService.changeVehicle(
      this.tripSelected.id!,
      id,
    );
    this.trips.value[index] = tripDetail;
    if (this.selectedTrip) {
      this.selectedTrip = tripDetail;
      this.tripDetails = tripDetail;
    }
    this.changeVehicle.toggle();
    this.notificationService.image = images.trips.vehicle;
    this.notificationService.title = this.literalService.get(
      'trips.actions.changeVehicle.title',
      true,
    );
    this.notificationService.message = this.literalService.get(
      'trips.actions.changeVehicle.text',
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  addPaxAction = async (nPax: any) => {
      console.log(nPax);
      if (!this.selectedTrip) return;
      this.tripDetails = await this.tripsService.addPax(
        this.selectedTrip.id || 0,
        nPax,
      );
      this.addPax.toggle();
      this.notificationService.image = images.pax;
      this.notificationService.title = this.literalService.get(
        'trips.actions.addPax.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.addPax.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
  };

  setReadyAction = async () => {
      const tripDetail = await this.tripsService.setStatus(this.tripSelected.id!, ExpeditionStatus.READY);
      await this.getTrips();
      const index = this.trips.value.findIndex(
        (trip: ITrip) => trip.id === this.tripSelected.id,
      );
      if (index && index != -1) {
        this.trips.value[index] = tripDetail;
        if (this.selectedTrip) {
          this.selectedTrip = tripDetail;
          this.tripDetails = tripDetail;
        }
      }
      this.setReady.toggle();
      this.notificationService.image = images.trips.ready;
      this.notificationService.title = this.literalService.get(
        'trips.actions.setReady.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.setReady.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
  };

  setClosedAction = async (confirmed: any) => {
      console.log('setClosedAction', confirmed);
      await this.tripsService.setStatus(this.tripSelected.id!, ExpeditionStatus.CLOSED);
      await this.getTrips();
      if (this.selectedTrip) this.clearMap();
      this.setClosed.toggle();
      this.notificationService.image = images.trips.close;
      this.notificationService.title = this.literalService.get(
        'trips.actions.setClosed.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.setClosed.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
  };

  deleteTripAction = async (reason?: string) => {
      this.deletingTrip = true;
      await this.tripsService.deleteTrip(this.tripSelected.id!, reason).then((resp: any) => {
        console.log(resp);
      }, (error: any) => {
        console.log(error);
        this.deletingTrip = false;
      });
      await this.getTrips().then((resp: any) => {
        console.log(resp);
      }, (error: any) => {
        console.log(error);
        this.deletingTrip = false;
      });
      if (this.selectedTrip) this.clearMap();
      this.deleteTrip.toggle();
      this.notificationService.image = images.trips.delete;
      this.notificationService.title = this.literalService.get(
        'trips.actions.deleteTrip.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.deleteTrip.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
      this.deletingTrip = false;
  };

  validatePickUpAction = async (bookingId: any) => {
      console.log(bookingId);
      const bookingIndex = this.bookings.findIndex(
        (booking: IBooking) => bookingId === booking.id,
      )!;
      const booking = await this.tripsService.validatePickUp(bookingId);
      this.bookings[bookingIndex] = booking;
      this.notificationService.image = images.confirm;
      this.notificationService.title = this.literalService.get(
        'trips.actions.validate.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.validate.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
  };

  validateDropOffAction = async (bookingId: any) => {
      console.log(bookingId);
      const bookingIndex = this.bookings.findIndex(
        (booking: IBooking) => bookingId === booking.id,
      )!;
      const booking = await this.tripsService.validateDropOff(bookingId);
      this.bookings[bookingIndex] = booking;
      this.notificationService.image = images.confirm;
      this.notificationService.title = this.literalService.get(
        'trips.actions.validate.title',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'trips.actions.validate.text',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
  };

  showChangeDriver = async (tripId: number) => {
      console.log('showChangeDriver', tripId);
      this.drivers = await this.usersService.getDrivers(tripId);
      const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
      this.tripSelected = trip!;
      this.driverSelected = trip?.driver.id || undefined;
      // this.initializeModals()
      this.changeDriver.toggle();
  };

  showChangeVehicle = async (tripId: number) => {
      console.log('showChangeVehicle', tripId);
      this.vehicles = await this.usersService.getVehicles(tripId);
      const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
      this.tripSelected = trip!;
      this.vehicleSelected = trip?.vehicle.id || undefined;
      // this.initializeModals()
      this.changeVehicle.toggle();
  };

  showAddPax = async (tripId: number) => {
      console.log('showAddPax', tripId);
      // const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
      // this.initializeModals()
      this.addPax.toggle();
  };

  showSetReady = async (tripId: number) => {
      const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
      this.tripSelected = trip!;
      this.setReady.toggle();
  };

  showSetClosed = async (tripId: number) => {
    const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
    this.tripSelected = trip!;
    this.setClosed.toggle();
  };

  showDeleteTrip = async (tripId: number) => {
    const trip = this.trips.value.find((trip: ITrip) => trip.id === tripId);
    this.tripSelected = trip!;
    this.deleteTrip.toggle();
  };

  showViewBookings = async (tripId: number) => {
    this.bookings = await this.tripsService.getBookingsByTrip(tripId);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.tripsService.getBookingsByTrip(tripId);
    }, 10000);
    // this.initializeModals()
    this.viewBookings.toggle();
  };

  showViewPickUps = async (stopId: number) => {
    this.bookings = await this.tripsService.getBookingsByStop(stopId, true);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.tripsService.getBookingsByStop(stopId, true);
    }, 10000);
    // this.initializeModals()
    this.viewBookings.toggle();
  };

  showViewDropOffs = async (stopId: number) => {
    this.bookings = await this.tripsService.getBookingsByStop(stopId, false);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.tripsService.getBookingsByStop(stopId, false);
    }, 10000);
    // this.initializeModals()
    this.viewBookings.toggle();
  };

  showViewChangeOvers = async (stopId: number) => {
    this.bookings = await this.tripsService.getChangeoversByStop(stopId);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.tripsService.getChangeoversByStop(stopId);
    }, 10000);
    // this.initializeModals()
    this.viewBookings.toggle();
  };

  prepareToDeleteBooking = (bookingId: any) => {
    this.bookingToDelete = bookingId;
    this.deleteBooking.toggle();
  };

  deleteBookingAction = async() => {
    this.deletingBooking = true;
    await this.bookingsService.deleteBooking(this.bookingToDelete, this.comment).then(() => {
      this.notificationService.image = images.sidebar.bookings;
      this.notificationService.title = this.literalService.get(
        `bookings.actions.deleteBooking.title`,
        true,
      );
      this.notificationService.message = this.literalService.get(
        `bookings.actions.deleteBooking.text`,
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, () => {
    });
    this.deleteBooking.toggle();
    this.viewBookings.toggle();
    this.deletingBooking = false;
  };

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.controller.abort();
    clearInterval(this.getTripInterval);
    clearInterval(this.getBookingsInterval);
    clearInterval(this.getVehiclesInterval);
    this.routerSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }
}
