<form #form novalidate (ngSubmit)="onSubmit()">
    <!-- <div id="routeDetailsContainer" class="container absolute top-20 right-0 z-10 hiddenDetails" [ngClass]="{ 'openDetails': route && !edit, 'openDetailsEdit': route && edit, 'expandedDetails': expanded, 'h-[60rem]': edit || newService, 'h-[54rem]': !edit && !newService }"> -->
    <div id="routeDetailsContainer" class="container absolute top-20 right-0 z-10 hiddenDetails h-[60rem]" [ngClass]="{ 'openDetails': route() && !edit, 'openDetailsEdit': route() && edit, 'h-[calc(100vh-80px)]': !edit, 'h-[calc(100vh)]': edit }">
    <!-- <div id="serviceDetailsContainer" class="container absolute top-20 right-0 z-10 hiddenDetails"> -->
        <section class="topbar h-14 mt-4 flex items-center">
            @if (route() && route().id) {
                <h1 class="ml-8 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.routes.routeTitle', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <!-- <div class="flex items-center justify-center content-image mr-2" (click)="copyLink(user.id)">
                        <img [tooltip]="literalService.get('copyLink', true)" [position]="TooltipPosition.DYNAMIC" [src]="images.link" alt="copy" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div> -->
                    <div class="flex items-center justify-center content-image mr-2">
                        <img [src]="images.services.edit" [tooltip]="literalService.get('services.routes.tooltips.edit', true)" alt="edit" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div>
                    <div class="flex items-center justify-center content-image mr-2">
                        <img [src]="images.services.routes.downloadGTFS" alt="downloadGTFS" class="w-6 h-6 mr-4 cursor-pointer" (click)="downloadGTFS()"  />
                    </div>
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            } @else {
                <h1 class="ml-8 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.routes.newRoute', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            }
        </section>  
        <section class="h-14 mt-8 ml-8 items-center">
            <div class="flex">
                <div class="grid grid-rows-7 grid-flow-col gap-5">
                    <ng-container>
                    @if (!edit) {
                        <article *ngFor="let element of routeForm" [ngClass]="{'hidden': element.hidden}">
                            <!-- @if (expanded ? true : !element.expanded) { -->
                                <app-detail [data]="element" [value]="element.value" />
                            <!-- } -->
                        </article>
                    } @else if (edit) {
                        <article *ngFor="let element of routeForm" [ngClass]="{'hidden': element.hidden}">
                            <!-- @if (expanded && element.edit ? true : !element?.expanded) { -->
                                <app-input  [data]="element"
                                            [edit]="true"
                                            [independent]="false"
                                            [translateValue]="element.edit?.translateValue" />
                            <!-- } -->
                        </article>
                    }
                    </ng-container>
                </div>
                <!-- <section class="flex items-center right-5 top-0 bottom-0 border-l-[1px] pl-3">
                    <img alt="arrowToExpand" [src]="images.arrowLeft" class="h-5 w-5 cursor-pointer expand" [ngClass]="{'actionRotateArrowOpen': !expanded, 'actionRotateArrowClose': expanded }" (click)="expanded = !expanded" />
                </section> -->
            </div>
        </section>
    </div>
</form>