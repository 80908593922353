<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-[40%] max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-10">
            <form novalidate #form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <section id="header">
                    <div class="flex items-center">
                        <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('services.modal.' + (edit ? 'editGTFS.title' : 'updateGTFS.title'), true) }}</h3>
                        <button (click)="modal.hide()" type="button" class="right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <img [src]="images.close" class="h-6 w-6" />
                        </button>
                    </div>
                </section>
                <section id="body" class="mt-6">
                    <label class="text-base font-semibold primary-purple-text">{{ literalService.get('services.modal.' + (edit ? 'editGTFS.text' : 'updateGTFS.text'), true) }}</label>
                    <article class="mt-4">
                        <div class="grid grid-rows-4 grid-flow-col">
                            @for (item of uploaded; track $index) {
                                <input type="file" [id]="item.type" class="file-input hidden" (change)="onFileSelected($event, item.type)" #fileUpload>
                                <div class="flex items-center py-4 px-6 item" (click)="item.disabled || document.getElementById(item.type)!.click()" [ngClass]="{'primary-purple-border primary-purple-text': !item.value, 'primary-purple-light-border uploaded': item.value, 'cursor-pointer': !item.disabled, 'mr-8': $index !== 4 && $index !== 5 && $index !== 6, 'ml-8': $index === 4 || $index === 5 || $index === 6}">
                                    <img [src]="images.services.uploadGTFS" alt="{{item.type}}" class="w-8 h-8" [ngClass]="{'disabled': item.disabled || false}" />
                                    <label class="text-base font-bold ml-4" [ngClass]="{'disabled': item.disabled || false, 'cursor-pointer': !item.disabled}" [innerHTML]="literalService.get('services.modal.data.' + item.type, true)"></label>
                                    <label class="text-base" [ngClass]="{'disabled': item.disabled || false, 'cursor-pointer': !item.disabled, 'font-bold': item.required, 'ml-1': !item.required}">{{ item.required ? '*' : '(' + literalService.get('services.modal.data.optional') + ')' }}</label>
                                    <span class="text-base font-normal ml-1 mr-8 truncate" [ngClass]="{'cursor-pointer': !item.disabled}" [hidden]="!item.value">{{ item.file.fileName }}</span>
                                    <img [src]="images.check" alt="{{item.type}}Uploaded" [hidden]="!item.value" class="w-6 h-6 absolute right-4" />
                                </div>
                            }
                        </div>
                    </article>
                    <section class="flex justify-between mt-6">
                        @if (clients.length > 1) {
                            <article class="mt-4 w-full mr-[16px]">
                                <div class="flex items-center mb-[6px]">
                                    <img [src]="images.services.client" alt="client" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.client', true) }}</label>
                                </div>
                                <select id="clientId" formControlName="clientId" class="block w-full h-10 px-3 py-2 text-base font-normal bg-white border rounded-s rounded-e placeholder-slate-400 focus:outline-none" (change)="setClientId($event)">
                                    <option [value]="''" [selected]="formGroup.get('clientId')?.value === ''" [defaultSelected]="true">
                                        {{ literalService.get('services.modal.selectClient', true) }}
                                    </option>
                                    @for (client of clients; track $index) {
                                        <option [value]="client.id">
                                            <label>{{ client.name }}</label>
                                        </option>
                                    }
                                </select>
                            </article>
                        }
                        <article class="mt-4 w-full ml-[16px]">
                            <div class="flex items-center mb-[6px]">
                                <img [src]="images.area" alt="town" class="h-6 w-6 mr-[6px]" />
                                <label class="font-normal text-base">{{ literalService.get('services.modal.area', true) }}</label>
                            </div>
                            <select id="townId" formControlName="townId" class="block w-full h-10 px-3 py-2 text-base font-normal bg-white border rounded-s rounded-e placeholder-slate-400 focus:outline-none" [attr.disabled]="formGroup.get('clientId')?.errors" (change)="setTownId($event)">
                                <option [value]="''" [selected]="formGroup.get('townId')?.value === ''" [defaultSelected]="true">
                                    {{ literalService.get('services.modal.selectArea', true) }}
                                </option>
                                @for (town of towns; track $index) {
                                    <option [value]="town.id">
                                        <label>{{ town.name }}</label>
                                    </option>
                                }
                            </select>
                        </article>
                    </section>
                </section>
                <section id="footer" class="flex mt-10">
                    <input [attr.data-modal-hide]="id" type="button" class="secondary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase cursor-pointer items-center bg-white mr-8" (click)="modal.hide()" [value]="literalService.get('cancel', true)"/>
                    <input [attr.data-modal-hide]="id" type="submit" [disabled]="formGroup.invalid" class="primary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase cursor-pointer" [value]="literalService.get('upload', true)"/>
                </section>
            </form>
        </div>
    </div>
</div>