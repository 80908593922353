<div id="mapContainer" class="pt-20 pl-20 h-screen">
    <app-service-modal class="absolute mt-5 ml-5 z-10" (addService)="addService()" (selectServiceInfo)="getServiceInfoDetail($event)" (openGTFSModal)="servicesGtfsModal.toggle()" (showRoutes)="showRoutesEvent($event)"></app-service-modal>
    @if (showRoutes && routes) {
        <div class="ml-16">
            <app-service-routes-modal class="absolute mt-5 z-10 ml-96" [routes]="routes" (selectRoute)="getRouteDetail($event)" (close)="closeRoutesModal()"></app-service-routes-modal>
        </div>
    }
    <app-change-area [fromLocation]="'services'" (changeEventEmitter)="filterByTowns($event)"></app-change-area>
    <div id="map" class="w-full h-full"></div>
    <!-- @if (serviceInfoSelected || newService) {  -->
        <app-service-details [serviceInfoDetail]="serviceInfoSelected || newService" [edit]="editService" [newService]="newService" [newServiceObject]="newServiceObject" (editEvent)="editEvent()" (closeDetails)="closeDetails()"></app-service-details>
    <!-- } -->
    <!-- @if (routeSelected) {  -->
        <app-service-routes-details [route]="routeSelected" (closeDetails)="closeRouteDetails()"></app-service-routes-details>
    <!-- } -->
</div>
<app-modal-gtfs [id]="'servicesGtfs'" [modal]="servicesGtfsModal" [hidden]="!servicesGtfsShowed" [edit]="false" (action)="importGTFS($event)"></app-modal-gtfs>