import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'flowbite';
import { IBase, IClient, ITown } from 'src/app/models';
import { LiteralService, StorageService, UsersService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-gtfs',
  templateUrl: './modal-gtfs.component.html',
  styleUrl: './modal-gtfs.component.scss'
})
export class ModalGtfsComponent implements OnInit {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() edit: boolean = false;
  @Output() action = new EventEmitter<any>();

  @ViewChild('form') form: ElementRef;
  public formGroup: FormGroup;

  public agency = new FormControl('');
  public routes = new FormControl('');
  public stops = new FormControl('');
  public stopsTimes = new FormControl('');
  public trips = new FormControl('');
  public calendar = new FormControl('');
  public calendarDates = new FormControl('');

  public towns: ITown[] = [];
  public clients: IClient[] = [];

  public document = document;
  public images = images;
  public uploaded = [
    { type: 'trips', value: false, file: { fileName: '', value: {} as File}, formControl: this.trips, required: true },
    { type: 'routes', value: false, file: { fileName: '', value: {} as File}, formControl: this.routes, required: true },
    { type: 'stops', value: false, file: { fileName: '', value: {} as File}, formControl: this.stops, required: true },
    { type: 'stopTimes', value: false, file: { fileName: '', value: {} as File}, formControl: this.stopsTimes, required: true },
    { type: 'calendar', value: false, file: { fileName: '', value: {} as File}, formControl: this.calendar, required: true, disabled: false },
    { type: 'calendarDates', value: false, file: { fileName: '', value: {} as File}, formControl: this.calendar, required: true, disabled: false },
    { type: 'agency', value: false, file: { fileName: '', value: {} as File}, formControl: this.agency, required: false },
  ];

  constructor(public literalService: LiteralService, private usersService: UsersService) {}

  async ngOnInit() {
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      this.clients = res.clients;
    });
    this.formGroup = new FormGroup({});
    this.setControls();
  }

  async setControls(type?: string) {
    this.uploaded.forEach((u: any) => {
      if (type && type === 'calendar' && u.type === 'calendar') {
        this.formGroup.setControl('calendar', new FormControl('calendar', [Validators.required]));
        this.formGroup.setControl('calendarDates', new FormControl('calendarDates', []));
      } else if (type && type === 'calendarDates' && u.type === 'calendarDates') {
        this.formGroup.setControl('calendar', new FormControl('calendar', []));
        this.formGroup.setControl('calendarDates', new FormControl('calendarDates', [Validators.required]));
      } else if (type !== 'calendar' && type !== 'calendarDates'){
        const validators = u.required ? [Validators.required] : []; 
        const control = new FormControl('', validators); 
        u.formControl = control;
        this.formGroup.addControl(u.type, control);
      }
    });
    this.formGroup.addControl('townId', new FormControl('', [Validators.required]));
    this.formGroup.addControl('clientId', new FormControl('', [Validators.required]));
    if (this.clients.length === 1) {
      this.formGroup.get('clientId')?.setValue(this.clients[0].id);
      await this.usersService.getTownsByClient([this.clients[0].id!]).then((res) => {
        console.log(res);
        this.towns = res as ITown[];;
      });
    }
  }

  onFileSelected(event: any, type: string) {
    const file: File = event.target.files[0];
    if (file) {
      const fileName = file.name;
      
      this.uploaded.find((u: any) => u.type === type)!.file.fileName = fileName;
      this.uploaded.find((u: any) => u.type === type)!.file.value = file;
      this.uploaded.find((u: any) => u.type === type)!.value = true;
      if (this.uploaded.find((u: any) => u.type === 'calendar' && u.value)) {
        const calendarDates = this.uploaded.find((u: any) => u.type === 'calendarDates');
        calendarDates!['disabled'] = true;
      }
      if (this.uploaded.find((u: any) => u.type === 'calendarDates' && u.value)) {
        const calendar = this.uploaded.find((u: any) => u.type === 'calendar');
        calendar!['disabled'] = true;
      }
      console.log(this.uploaded)
      console.log(this.formGroup)
      console.log(this.calendar)
      console.log(this.calendarDates)

      const control = this.formGroup.get(type);
      control?.setValue(fileName);  
      control?.markAsTouched();
      control?.updateValueAndValidity();
      this.setControls(type);
    }
  }

  async setClientId(event: any) {
    this.formGroup.get('clientId')?.setValue(event.target.value);
    await this.usersService.getTownsByClient([event.target.value]).then((res) => {
      this.towns = res as ITown[];
    });
  }
  
  setTownId(event: any) {
    this.formGroup.get('townId')?.setValue(event.target.value);
  }

  onSubmit() {
    const files = this.uploaded.filter((u: any) => u.value);
    this.action.emit({files: files, clientId: this.formGroup.get('clientId')?.value, townId: this.formGroup.get('townId')?.value});
  }

}
