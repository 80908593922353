import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getServiceForm } from 'src/app/forms/serviceForm';
import { IServiceInfoDetail } from 'src/app/models';
import { LiteralService, StorageService, TownsService, UsersService, UtilsService } from 'src/app/services';
import { ServiceInfoService } from 'src/app/services/service-info/service-info.service';
import { images } from 'src/images';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.scss'
})
export class ServiceDetailsComponent implements OnChanges {

  @Input() serviceInfoDetail?: IServiceInfoDetail;
  @Input() newServiceObject: any = {} as IServiceInfoDetail;
  @Input() newService: boolean;
  @Input() edit: boolean;
  @Output() editEvent = new EventEmitter<any>();
  @Output() closeDetails = new EventEmitter<any>();

  public expanded = false;
  // public edit = false;

  public images = images;
  public serviceForm: any[];

  public form: FormGroup;

  public steps = [ 1 ];
  public numberSteps = 2;
  public currentStep = 1;

  constructor(public literalService: LiteralService,
              private storageService: StorageService,
              private townsService: TownsService,
              private usersService: UsersService,
              public utilsService: UtilsService,
              private fb: FormBuilder,
              private serviceInfoService: ServiceInfoService) {
                this.form = this.fb.group({
                  // client: ['', Validators.required],
                  // town: ['', [Validators.required]],
                  tag: ['', [Validators.required]],
                  // color: ['', [Validators.required]],
                  name: ['', [Validators.required]],
                });
              }

  async ngOnChanges() {
    if (this.newService) {
      await getServiceForm(this.newServiceObject!, this.newService, this.usersService, this.literalService,this.storageService, this.currentStep).then((res: any) => {
        this.serviceForm = res;
      });
    } else {
      await getServiceForm(this.serviceInfoDetail!, this.newService, this.usersService, this.literalService,this.storageService, this.currentStep).then((res: any) => {
        this.serviceForm = res;
      });
    }
  }

  close() {
    this.edit = false;
    this.closeDetails.emit();
  }

  changeData(data: any, element?: any) {
    console.log(this.form)
    if (element?.edit.valueToChange === 'client') {
      this.currentStep++;
      this.steps.push(2);
      this.newServiceObject.client = data.options[0].value;
      this.ngOnChanges();
    } else {
      if (Array.isArray(element?.edit.valueToChange)) {
        return this.setMultiSelectData(data, element);
      } else {
        const value = typeof data === 'object' ? data.target || data.options[0] : data;
        return this.setNormalData(value, typeof data === 'object');
      }
    }
  }

  private setNormalData(data: any, isEvent = false) {
    return isEvent ? data.value : data;
  }

  private setMultiSelectData(data: any, element: any) {
    const selectedValues = element.edit?.selectedValues || [];
    const index = selectedValues.findIndex(({ id }: any) => id === data.id);

    index === -1 ? selectedValues.push(data) : selectedValues.splice(index, 1);
    this.newServiceObject[data.id] = index === -1;
  }

  async onSubmit() {
    console.log(this.newServiceObject); 
    await this.serviceInfoService.createService(this.newServiceObject);
  }

}
