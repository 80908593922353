import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Modal } from 'flowbite';
import { GeoJsonObject, IBase, IServiceInfo, IServiceInfoDetail, ITown } from 'src/app/models';
import { NotificationTypes } from 'src/app/models/enums';
import { RoutesResolver, ServiceInfosResolver } from 'src/app/resolvers';
import { LiteralService, MapUtilsService, NotificationService, ServicesService, StorageService, UsersService } from 'src/app/services';
import { ServiceInfoService } from 'src/app/services/service-info/service-info.service';
import { initialServiceInfoCreate, initialServiceInfoDetailsState } from 'src/app/store/initialisation/service-infos';
import { initialServicesState } from 'src/app/store/initialisation/services';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, AfterViewInit {

  public services = initialServiceInfoDetailsState;
  public map: google.maps.Map;
  public outlines: GeoJsonObject[] = [];

  public serviceInfoSelected: any;
  public routeSelected: any;
  public routes = initialServicesState;
  public newService: boolean = false;
  public newServiceObject = initialServiceInfoCreate();

  public servicesGtfsModal: Modal;
  public servicesGtfsShowed = false;

  public showRoutes = false;

  public towns: ITown[] = [];
  public selectedTowns: ITown[] = [];

  private images = images;

  public editService = false;
  // ****************************************************
  // TODO: Hide service list if route modal is showed
  // ****************************************************

  constructor(private titleService: Title,
              private activatedRoute: ActivatedRoute,
              public literalService: LiteralService,
              private mapUtilsService: MapUtilsService,
              private usersService: UsersService,
              private servicesService: ServicesService,
              private serviceInfoService: ServiceInfoService,
              private serviceInfosResolver: ServiceInfosResolver,
              private storageService: StorageService,
              private routesResolver: RoutesResolver,
              private notificationService: NotificationService
            ) {
  }

  async ngOnInit() {
    this.titleService.setTitle(this.literalService.get('sidebar.services', true) + ' - NEMI Backoffice');
    this.initMap();
    this.services = this.activatedRoute.snapshot.data['services'];
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      this.towns = res.towns;
    });
    console.log("this.services.value", this.services.value)
    
    await this.filterByTowns();
    // this.showOutlines();
    // this.zoomToOutlines();
  }

  ngAfterViewInit(): void {
    this.initializeModals();
  }

  async showOutlines() {
    // const routes = this.services.value.flatMap((service: IServiceInfoDetail) => service.routes);
    // this.outlines.push(...routes.filter((route: any) => route.outline).map((route: any) => route.outline));
    // console.log(this.outlines)
    const areas = await this.storageService.getServicesAreas();
    console.log(areas)
    const townIds = JSON.parse(areas).map((area: any) => area.id);
    console.log(townIds)
    if (townIds.length > 0) {
      const terrains: any[] = await this.servicesService.getTerrains(townIds);
      console.log("terrains", terrains)
      this.outlines = terrains.filter((terrain: any) => terrain.outline !== null).map((terrain: any) => terrain.outline);
      const colors = terrains.filter((terrain: any) => terrain.color !== null).map((terrain: any) => terrain.color);
      console.log(this.outlines, colors)
      this.mapUtilsService.drawOutlinesFromOutlines(this.map, this.outlines, colors);
      this.zoomToOutlines();
    }
  }

  zoomToOutlines() {
    const padding = { top: 30, bottom: 30, left: 550, right: 0 };
    const bounds = new google.maps.LatLngBounds();
    const coords: any[] = [];
    this.outlines.forEach((outline: GeoJsonObject) => {
      outline.geometry!.coordinates[0].map((coord: any) => {
        coords.push({ lat: coord[1], lng: coord[0] });
      });
    });
    coords.map((coord: any) => {
      if (coord.lat && coord.lng) {
        bounds.union(new google.maps.LatLngBounds(
          new google.maps.LatLng(coord.lat, coord.lng)
        ));
      }
    });
    this.map.fitBounds(bounds, padding);
    this.map.setZoom(Math.min(this.map.getZoom()! - 0.3, 15));
  }

  async initMap() {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const town = await this.usersService.getLoggedUserTown();
    console.log(this.services)
    const coords = { lat: town.latitude ? town.latitude : 41.4696546, lng: town.longitude ? town.longitude : 2.0596017 };
    this.map = new Map(document.getElementById('map') as HTMLElement, {
        center: coords,
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: true,
        cameraControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        mapId: environment.mapId,
    });
    this.map.addListener('click', () => {
      this.mapUtilsService.clearInfoWindows();
    });
  }

  initializeModals() {
    this.servicesGtfsModal = new Modal(document.getElementById('servicesGtfs'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.servicesGtfsShowed = false),
      onShow: () => (this.servicesGtfsShowed = true),
    });
  }

  async filterByTowns(event?: any) {    
    const storedAreas: any = await this.storageService.getServicesAreas().catch(() => []);
    this.selectedTowns = storedAreas ? JSON.parse(storedAreas) : [];
    if (event) {
      const { value, checked } = event.target;
      const town = this.towns.find((town: ITown) => town.id == value)!;
      const index = this.selectedTowns.findIndex((town: ITown) => town.id == value);
      checked
        ? this.selectedTowns.push(town)
        : this.selectedTowns.splice(index, 1);
    }
    this.storageService.setServicesAreas(JSON.stringify(this.selectedTowns));
    this.services = await this.serviceInfosResolver.resolveWithTowns(6, 1, this.selectedTowns);
    this.showOutlines();
  }

  closeModals() {
    if (this.servicesGtfsShowed) this.servicesGtfsModal.toggle();
  }

  // selectServiceInfo(event: any) {
  //   this.serviceInfoSelected = event;
  //   console.log(this.serviceInfoSelected)
  // }

  async getServiceInfoDetail(serviceInfo: IServiceInfo) {
    this.serviceInfoSelected = await this.serviceInfoService.getServiceInfo(serviceInfo.id!);
    console.log(this.serviceInfoSelected)
  }

  async getRouteDetail(routeId: number) {
    this.routeSelected = await this.servicesService.getService(routeId);
    console.log(this.routeSelected)
  }

  importGTFS(event: any) {
    this.servicesGtfsModal.toggle();
    this.servicesService.importServiceFromGtfs(event.files, event.clientId, event.townId).then(() => {
      this.notificationService.image = this.images.checkCircle;
      this.notificationService.title = 'services.actions.uploadGtfs.create.title';
      this.notificationService.message = 'services.actions.uploadGtfs.create.text';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, () => {
      this.notificationService.image = this.images.cancelCircle;
      this.notificationService.title = 'services.actions.uploadGtfsError.title';
      this.notificationService.message = 'services.actions.uploadGtfsError.text';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.DANGER);
    });
  }

  async showRoutesEvent(serviceInfo: IServiceInfo) {
    this.routes;
    this.routes.pagination.numberOfElements = 5;
    this.routes.pagination.actualPage = 1;
    this.activatedRoute.snapshot.data['routes'] = this.routes;
    await this.routesResolver.resolve(this.activatedRoute.snapshot, [serviceInfo.id!]);
    console.log(this.routes)
    this.showRoutes = true;
  }

  async addService() {
    this.newService = true;
    this.newServiceObject = initialServiceInfoCreate();
    this.adjustSize();
  }

  editEvent() {
    this.editService = !this.editService;
    this.adjustSize();
  }

  closeDetails() {
    this.newService = false;
    this.newServiceObject = initialServiceInfoCreate();
    this.serviceInfoSelected = undefined;
  }

  closeRouteDetails() {
    this.routeSelected = undefined;
  }

  closeRoutesModal() {
    this.showRoutes = false;
    this.routes = initialServicesState;
  }

  adjustSize() {
    const mapContainer = document.getElementById("mapContainer");
    const detailsContainer = document.getElementById("serviceDetailsContainer");
    mapContainer!.style.height = `${detailsContainer?.offsetHeight! + (80 * 2)}px`;
  }

}
