import { Component, Input } from '@angular/core';
import { LiteralService } from 'src/app/services';

@Component({
  selector: 'app-input-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

  @Input() title: string;
  @Input() text: string;

  constructor(public literalService: LiteralService) {}

}
