export type ImageName = keyof typeof images;

export const images = {
  landscapeLogin: './assets/images/landscapes/landscape-login.jpg',
  landscape: './assets/images/landscapes/landscape.svg',
  login: {
    username: './assets/images/users/username.svg',
    password: './assets/images/users/password.svg',
  },
  darkMode: {
    sun: './assets/images/dark-mode/sun.svg',
    moon: './assets/images/dark-mode/moon.svg',
  },
  notification: {
    success: './assets/images/notification/success.svg',
    danger: './assets/images/notification/danger.svg',
    warning: './assets/images/notification/warning.svg',
    close: './assets/images/notification/close.svg',
    error: './assets/images/notification/error.svg',
    userActive: './assets/images/notification/user-active.svg',
    userInactive: './assets/images/notification/user-inactive.svg',
    userBlocked: './assets/images/notification/user-blocked.svg',
    userDeleted: './assets/images/notification/user-deleted.svg',
  },
  sidebar: {
    admin: './assets/images/sidebar/admin.svg',
    users: './assets/images/sidebar/users.svg',
    operators: './assets/images/sidebar/operators.svg',
    vehicles: './assets/images/sidebar/vehicles.svg',
    towns: './assets/images/sidebar/towns.svg',
    services: './assets/images/sidebar/services.svg',
    trips: './assets/images/sidebar/trips.svg',
    bookings: './assets/images/sidebar/bookings.svg',
    messages: './assets/images/sidebar/messages.svg',
    dashboard: './assets/images/sidebar/dashboard.svg',
    apps: './assets/images/sidebar/apps.svg',
    clients: './assets/images/sidebar/clients.svg',
    identityProvider: './assets/images/sidebar/identity-provider.svg',
  },
  logos: {
    backofficeLogo: './assets/images/logos/backoffice-logo.svg',
    nemiSmall: './assets/images/logos/nemi-small.svg',
    nemiLarge: './assets/images/logos/nemi-large.svg',
    nemiWithoutBackground: './assets/images/logos/nemi-without-background.svg',
  },
  userProfile: {
    languages: './assets/images/user-profile/languages.svg',
    signOut: './assets/images/user-profile/sign-out.svg',
    changePassword: './assets/images/users/password.svg',
  },
  flags: {
    ca: './assets/images/flags/ca.svg',
    el: './assets/images/flags/el.svg',
    en: './assets/images/flags/en.svg',
    es: './assets/images/flags/es.svg',
    it: './assets/images/flags/it.svg',
    pt: './assets/images/flags/pt.svg',
    eu: './assets/images/flags/eu.svg',
    fr: './assets/images/flags/fr.svg',
  },
  user: {
    name: './assets/images/users/user.svg',
    mail: './assets/images/users/mail.svg',
    phone: './assets/images/users/phone.svg',
    id: './assets/images/users/id.svg',
    calendar: './assets/images/users/calendar.svg',
    star: './assets/images/users/star.svg',
    addUser: './assets/images/users/add-user.svg',
    addDriver: './assets/images/users/add-driver.svg',
    userActive: './assets/images/users/user-active.svg',
    userDisabled: './assets/images/users/user-disabled.svg',
    userInactive: './assets/images/users/user-disabled.svg',
    userDeleted: './assets/images/users/user-deleted.svg',
    userBlocked: './assets/images/users/user-blocked.svg',
    edit: './assets/images/users/edit.svg',
    account: './assets/images/users/account.svg',
    area: './assets/images/users/area.svg',
    comments: './assets/images/users/comments.svg',
    gender: './assets/images/users/gender.svg',
    birthDate: './assets/images/users/birthdate.svg',
    settlement: './assets/images/users/town.svg',
    additionalPhone: './assets/images/users/additional-phone.svg',
    role: './assets/images/users/rol.svg',
    privacyPolicy: './assets/images/users/privacy-policy.svg',
    language: './assets/images/users/language.svg',
    clients: './assets/images/users/clients.svg',
    notifications: './assets/images/users/notifications.svg',
    device: './assets/images/users/device.svg',
    token: './assets/images/users/token.svg',
    smartPhone: './assets/images/users/smart-phone.svg',
    password: './assets/images/users/password.svg',
    operator: './assets/images/users/operator.svg',
    changelog: './assets/images/users/info.svg',
  },
  booking: {
    user: './assets/images/bookings/user.svg',
    service: './assets/images/bookings/service.svg',
    date: './assets/images/bookings/date.svg',
    stops: './assets/images/bookings/stops.svg',
    seats: './assets/images/bookings/seats.svg',
    smartPhone: './assets/images/bookings/smart-phone.svg',
    notifications: './assets/images/bookings/notifications.svg',
    searchCriteria: './assets/images/bookings/search-criteria.svg',
    pickUpTime: './assets/images/bookings/pickup-dropoff-time.svg',
    dropOffTime: './assets/images/bookings/pickup-dropoff-time.svg',
    empty: './assets/images/bookings/empty.svg',
    feedback: './assets/images/bookings/feedback.svg',
    vehicle: './assets/images/trips/vehicle.svg',
    driver: './assets/images/trips/driver.svg',
    addBooking: './assets/images/bookings/add-booking.svg',
    area: './assets/images/bookings/area.svg',
    eco: './assets/images/bookings/eco.svg',
    pedestrian: './assets/images/bookings/pedestrian.svg',
    passengers: './assets/images/bookings/passengers.svg',
    maxReservationTime: './assets/images/bookings/max-reservation-time.svg',
    warning: './assets/images/bookings/warning.svg',
    availability: './assets/images/bookings/availability.svg',
    noOptions: './assets/images/bookings/no-options.svg',
    changeStops: './assets/images/bookings/change-stops.svg',
    rebook: './assets/images/bookings/rebook.svg',
    actions: {
      favorite: './assets/images/bookings/star.svg',
      trip: './assets/images/bookings/trip.svg',
      user: './assets/images/bookings/user.svg',
      open: './assets/images/bookings/open.svg',
      edit: './assets/images/bookings/edit.svg',
      editCircle: './assets/images/bookings/edit2.svg',
      missed: './assets/images/bookings/missed.svg',
      delete: './assets/images/bookings/delete.svg',
    },
    notified: {
      app: './assets/images/bookings/notified/app.svg',
      notNotified: './assets/images/bookings/notified/not-notified.svg',
      notified: './assets/images/bookings/notified/notified.svg',
      phone: './assets/images/bookings/notified/phone.svg',
      whatsapp: './assets/images/bookings/notified/whatsapp.svg'
    },
    ticket: {
      ticket: './assets/images/bookings/ticket/ticket.svg',
      ticketOk: './assets/images/bookings/ticket/ticket.svg',
      ticketWarning: './assets/images/bookings/ticket/ticket-warning.svg',
      ticketError: './assets/images/bookings/ticket/ticket-error.svg'
    }
  },
  services: {
    route: './assets/images/services/route.svg',
    addService: './assets/images/services/add-service.svg',
    gtfs: './assets/images/services/gtfs.svg',
    edit: './assets/images/services/edit.svg',
    tag: './assets/images/services/tag.svg',
    color: './assets/images/services/color.svg',
    name: './assets/images/services/name.svg',
    client: './assets/images/services/client.svg',
    criteria: './assets/images/services/criteria.svg',
    forward: './assets/images/services/forward.svg',
    backward: './assets/images/services/backward.svg',
    showTimes: './assets/images/services/showTimes.svg',
    uploadGTFS: './assets/images/services/upload-gtfs.svg',
    routes: {
      addRoute: './assets/images/services/routes/add-route.svg',
      additionalClients: './assets/images/services/routes/additional-clients.svg',
      apps: './assets/images/services/routes/apps.svg',
      area: './assets/images/services/routes/area.svg',
      client: './assets/images/services/routes/client.svg',
      config: './assets/images/services/routes/config.svg',
      downloadGTFS: './assets/images/services/routes/download-gtfs.svg',
      notifications: './assets/images/services/routes/notifications.svg',
      publishingSites: './assets/images/services/routes/publishing-sites.svg',
      settings: './assets/images/services/routes/settings.svg',
      ticketing: './assets/images/services/routes/ticketing.svg',
      times: './assets/images/services/routes/times.svg',
      visibility: './assets/images/services/routes/visibility.svg',
    }
  },
  roles: {
    admin: './assets/images/roles/admin.svg',
    driverManager: './assets/images/roles/driver-manager.svg',
    driver_manager: './assets/images/roles/driver-manager.svg',
    operator: './assets/images/roles/operator.svg',
    superadmin: './assets/images/roles/superadmin.svg',
    userDriver: './assets/images/roles/user-driver.svg',
    userRegular: './assets/images/roles/user-regular.svg',
    user: './assets/images/roles/user-regular.svg',
  },
  types: {
    regular: './assets/images/roles/user-regular.svg',
    driver: './assets/images/roles/user-driver.svg',
  },
  sortOptions: {
    alphabetically: './assets/images/sort/alphabetically.svg',
    creation: './assets/images/sort/creation.svg',
  },
  trips: {
    name: './assets/images/trips/name.svg',
    date: './assets/images/trips/date.svg',
    vehicle: './assets/images/trips/vehicle.svg',
    driver: './assets/images/trips/driver.svg',
    open: './assets/images/trips/open.svg',
    ready: './assets/images/trips/ready.svg',
    ongoing: './assets/images/trips/ongoing.svg',
    delete: './assets/images/trips/delete.svg',
    close: './assets/images/trips/close.svg',
  },
  favourites: {
    noOptions: './assets/images/no-favourites.svg'
  },
  arrow: './assets/images/arrow.svg',
  arrowDown: './assets/images/arrow-down.svg',
  arrowLeft: './assets/images/arrow-left.svg',
  arrowRight: './assets/images/arrow-right.svg',
  arrowUp: './assets/images/arrow-up.svg',
  download: './assets/images/download.svg',
  driver: './assets/images/driver.svg',
  menuBurger: './assets/images/menu-burger.svg',
  search: './assets/images/search.svg',
  filter: './assets/images/filter.svg',
  sort: './assets/images/sort.svg',
  visibilityOff: './assets/images/visibility-off.svg',
  visibility: './assets/images/visibility.svg',
  close: './assets/images/close.svg',
  error: './assets/images/error.svg',
  origin: './assets/images/origin.svg',
  destination: './assets/images/destination.svg',
  pax: './assets/images/pax.svg',
  prm: './assets/images/prm.svg',
  moreInfo: './assets/images/more-info.svg',
  area: './assets/images/area.svg',
  bus: './assets/images/bus.svg',
  route: './assets/images/route.svg',
  busCar: './assets/images/bus-car.svg',
  peepotired: './assets/images/peepotired.svg',
  distance: './assets/images/distance.svg',
  speed: './assets/images/speed.svg',
  clock: './assets/images/clock.svg',
  openInFull: './assets/images/open-in-full.svg',
  closeInFull: './assets/images/close-in-full.svg',
  plus: './assets/images/plus.svg',
  minus: './assets/images/minus.svg',
  changeover: './assets/images/changeover.svg',
  confirm: './assets/images/confirm.svg',
  info: './assets/images/info.svg',
  world: './assets/images/world.svg',
  comment: './assets/images/comment.svg',
  link: './assets/images/link.svg',
  document: './assets/images/document.svg',
  noDocuments: './assets/images/no-documents.svg',
  loading: './assets/animations/loading.svg',
  back: './assets/images/back.svg',
  next: './assets/images/next.svg',
  moreOptions: './assets/images/more-options.svg',
  alterationWarning: './assets/images/alteration-warning.svg',
  phone: './assets/images/phone.svg',
  usr: './assets/images/user.svg',
  multicheck: './assets/images/multicheck.svg',
  check: './assets/images/check.svg',
  checkCircle: './assets/images/check-circle.svg',
  cancelCircle: './assets/images/cancel-circle.svg'
};

export const arrows = [
  { name: 'down', img: images.arrowDown },
  { name: 'left', img: images.arrowLeft },
  { name: 'right', img: images.arrowRight },
  { name: 'up', img: images.arrowUp },
];

export const userProfile = [
  { name: 'languages', img: images.userProfile.languages },
  { name: 'signOut', img: images.userProfile.signOut },
  { name: 'changePassword', img: images.userProfile.changePassword },
];

export const flags = [
  { name: 'ca', img: images.flags.ca },
  { name: 'el', img: images.flags.el },
  { name: 'en', img: images.flags.en },
  { name: 'es', img: images.flags.es },
  { name: 'it', img: images.flags.it },
  { name: 'pt', img: images.flags.pt },
  { name: 'eu', img: images.flags.eu },
  { name: 'fr', img: images.flags.fr },
];
