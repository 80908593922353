import { Component, OnChanges, input, output } from '@angular/core';
import { getRouteForm } from 'src/app/forms/routeForm';
import { IRouteDetails } from 'src/app/models';
import { LiteralService, ServicesService, StorageService, TownsService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-service-routes-details',
  templateUrl: './service-routes-details.component.html',
  styleUrl: './service-routes-details.component.scss'
})
export class ServiceRoutesDetailsComponent implements OnChanges {

  readonly route = input<any>();
  readonly routeObject: any = {} as IRouteDetails;
  readonly closeDetails = output();

  public images = images;
  public edit = false;
  public routeForm: any[];

  constructor(public literalService: LiteralService,
              private townsService: TownsService,
              private servicesService: ServicesService,
              private storageService: StorageService) {}

  async ngOnChanges() {
    await getRouteForm(this.route()!, this.townsService, this.literalService, this.storageService).then((res: any) => {
      this.routeForm = res;
    });
  }

  close() {
    this.edit = false;
    // this.route().set(undefined);
    this.closeDetails.emit();
  }

  async downloadGTFS() {
    const response = await this.servicesService.downloadGtfs(this.route().id);
    this.downloadZip(response as any);
  }

  downloadZip(response: ArrayBuffer) {
    const blob = new Blob([response], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'archivo.zip'; // Nombre del archivo a descargar
    document.body.appendChild(a);
    a.click();
  
    // Limpiar el objeto URL después de la descarga
    window.URL.revokeObjectURL(url);
  }

  onSubmit() {

  }

}
