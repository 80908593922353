import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import moment from 'moment';
import { Select2ScrollEvent, Select2SearchEvent } from 'ng-select2-component';
import { AnimationOptions } from 'ngx-lottie';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { SingleStopReservationType, UserStatus, UserType } from 'src/app/models/enums';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { images } from 'src/images';
import { newBookingForm, UserBookingData } from '../../../forms/bookingForms';
import { IAvailabilitySuccess, IBookingCreate, IBookingSeat, IFavourites, IFindResult, IReservationAvailability, IReservationAvailabilityFailure, IServiceInfo, IUserRegular } from '../../../models';
import { BookingsService, LiteralService, TownsService, UsersService, UtilsService } from '../../../services';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrl: './booking-modal.component.scss',
})
export class BookingModalComponent implements OnInit, OnChanges {

  @Input() newBooking: IBookingCreate = {} as IBookingCreate;
  @Input() creatingBooking: boolean = false;
  @Input() bookingFromFavourite = false;
  @Input() hasAlterations = false;
  @Input() closeExceedingKm? = false;
  @Output() getServiceInfosEventEmitter = new EventEmitter<any>();
  @Output() selectODEventEmitter = new EventEmitter<any>();
  @Output() goToList = new EventEmitter<any>();
  @Output() showMaxReservationTimeModalEventEmitter = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter = new EventEmitter<any>();
  @Output() showBlockedUserModalEventEmitter = new EventEmitter<any>();
  @Output() selectStop = new EventEmitter<any>();
  @Output() openFavourite = new EventEmitter<number>();
  @Output() changeTown = new EventEmitter<any>();
  @Output() showAlterationModal = new EventEmitter();
  public bookingForm: any;
  public availabilities: IFindResult = { success: [], failure: [] };

  public serviceInfos: IServiceInfo[] = [];

  public currentStep: number = 1;
  public numberSteps: number = 3;

  public steps = [ 1 ];

  private destinationsStops: unknown[];

  public availabilitiesDates: any[] = [];
  public availabilitiesSeparateByDate: any = {};
  public availabilitiesSelected: any = [];
  public loadingAvailabilities = false;
  public currentPage = 0;

  public pax = 0;
  public prm = 0;

  public currentElement = 1;
  public totalElements = 0;

  public images = images;
  public Object = Object;
  public moment = moment;

  public isBack = false;

  public favourite: IFavourites;
  public dataLoaded = false;
  public favouriteByParams = false;

  public stopOriginNotification = false;
  public stopDestinationNotification = false;

  public isSingleReservation: boolean;

  public possibleSwap = false;

  public rebookBooking: IBookingDetails;

  public optionsPin: AnimationOptions = {
    path: '/assets/animations/pin.json'
  };

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };

  constructor(private usersService: UsersService,
              private townsService: TownsService,
              private bookingsService: BookingsService,
              private favouritesService: FavouritesService,
              public literalService: LiteralService,
              public utilsService: UtilsService,
              public router: Router,
              private activatedRoute: ActivatedRoute) {}

  async ngOnInit() {
    if (this.bookingFromFavourite && this.activatedRoute.snapshot.queryParamMap.get('userId')) {
      this.activatedRoute.queryParamMap.subscribe(async (params) => {
        this.favouriteByParams = true;
        const favouriteId = params.get('favouriteId');
        const userId = params.get('userId');
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(userId)) as IUserRegular;
        const favourite: IFavourites = await this.favouritesService.getFavourite(Number(favouriteId));
        this.favourite = favourite;
        this.newBooking.favourite = this.favourite;
        this.setData(this.newBooking.user, this.newBooking.favourite, undefined);
      });
    } else if (this.activatedRoute.snapshot.queryParamMap.get('bookingId') && !this.isBack) {
      this.activatedRoute.queryParamMap.subscribe(async (params) => {
        const bookingId = params.get('bookingId');
        this.rebookBooking = (await this.bookingsService.getBooking(Number(bookingId))) as IBookingDetails;
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(this.rebookBooking.userId)) as IUserRegular;
        this.setData(undefined, undefined, this.rebookBooking);
        this.dataLoaded = true;
      });
    } else {
      if (!this.newBooking.destinationStops) this.newBooking.destinationStops = [];
      if (moment(this.newBooking.date).isValid()) {
        this.newBooking.date = moment(this.newBooking.date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
      } else {
        const dates = this.newBooking.date.split(',');
        this.newBooking.date = dates.toString();
      }
      this.newBooking.time = this.newBooking.time && moment(this.newBooking.time, 'HH:mm').format('HH:mm') || moment().format('HH:mm');
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false}));
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['closeExceedingKm'] && changes['closeExceedingKm'].currentValue) {
      window.location.reload();
    }
    if (changes['newBooking'].currentValue && !changes['newBooking'].currentValue.targetUserId && changes['newBooking'].previousValue && changes['newBooking'].previousValue.targetUserId) {
      this.bookingFromFavourite = false;
      this.back();
    } else if (changes['newBooking'] && changes['newBooking'].currentValue.targetUserId && this.bookingFromFavourite) {
      this.favouriteByParams = false;
      this.newBooking = {} as IBookingCreate;
      this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(changes['newBooking'].currentValue.targetUserId)) as IUserRegular;
      const favourite: IFavourites = await this.favouritesService.getFavourite(changes['newBooking'].currentValue.favourite.id);
      this.favourite = favourite;
      this.newBooking.favourite = this.favourite;
      this.setData(this.newBooking.user, this.newBooking.favourite, undefined, false);
    }
  }

  setData = async (user?: IUserRegular, favourite?: IFavourites, bookingDetail?: IBookingDetails, showBlockedUserModal: boolean = true) => {
    this.dataLoaded = false;
    this.newBooking.targetUserId = bookingDetail ? bookingDetail.userId! : user!.id!;
    if (this.newBooking.user && this.newBooking.user.status?.toUpperCase() === UserStatus.BLOCKED && showBlockedUserModal) {
      const penalty = { penaltyEndDate: this.newBooking.user.penaltyEndDate, penaltyReason: this.newBooking.user.penaltyReason };
      this.showBlockedUserModal(penalty);
    }
    await this.updateBookingForm(new UserBookingData({showMoreUsers: false}), ['user']);
    !this.steps.includes(2) && this.steps.push(2);
    this.currentStep = 2;
    
    this.newBooking.townId = bookingDetail ? bookingDetail.service.town.id! : this.newBooking.townId || favourite!.town!.id!;
    this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
    await this.updateBookingForm(undefined, !this.isBack && ['town'] || ['']);
    this.getServiceInfosEventEmitter.emit({serviceInfos: this.serviceInfos, townId: this.newBooking.townId});
    if (this.isSingleReservation) {
      this.destinationsStops = this.newBooking.stops;
    }

    this.newBooking.originStopId = bookingDetail ? bookingDetail.origin.serviceStopId! : this.newBooking.originStopId || favourite!.inStop!.id!;
    bookingDetail ? this.setLocation(bookingDetail.origin.serviceStopId!) : this.setLocation(favourite!.inStop!.id!);
    if (!this.isSingleReservation) {
      this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
    }
    await this.updateBookingForm(undefined, ['stop']);
    this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'origin'});
    this.selectStop.emit({stopId: this.newBooking.originStopId, townId: this.newBooking.townId});

    if (!this.isBack) {
      this.newBooking.destinationStops = [];
      let outStopId;
      if(bookingDetail && bookingDetail.destination.mergedStop){
        outStopId = bookingDetail.destination.mergedStop.id
      } else if(!bookingDetail){
        outStopId = favourite?.outStop?.id
      } else{
        outStopId = bookingDetail?.destination.serviceStopId
      }
      this.addSeat({outStopId: outStopId , pax: bookingDetail ? bookingDetail.seats : favourite!.passengers, prm: bookingDetail ? bookingDetail.prmSeats : favourite!.prmPassengers });    
      await this.updateBookingForm();
      this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'destination'});
    }

    this.getPassengers();
    this.newBooking.date = moment(this.newBooking.date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
    this.newBooking.time = bookingDetail ? moment(bookingDetail.originPassingTime, 'HH:mm').format('HH:mm') : this.newBooking.time && moment(this.newBooking.time, 'HH:mm').format('HH:mm') || favourite!.time!;
    await this.updateBookingForm();
    if (this.favouriteByParams) {
      this.dataLoaded = true;
    }
  };

  public updateBookingForm = async (userBookingData?: UserBookingData, additionalFields = ['']) => {
    this.isSingleReservation = this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
    const favouriteOrRebookSingle = this.isSingleReservation && (this.bookingFromFavourite || this.rebookBooking !== undefined);
    this.bookingForm = await newBookingForm(this.newBooking, this.usersService, userBookingData, this.serviceInfos, this.destinationsStops, additionalFields, favouriteOrRebookSingle);
  };

  set = async (data: any, valueToChange: string) => {
    if (!data && valueToChange !== 'date') return;
  
    switch (valueToChange) {
      case 'targetUserId': {
        this.newBooking.targetUserId = data.id;
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, data.id) as IUserRegular;
  
        if (this.newBooking.user?.status?.toUpperCase() === UserStatus.BLOCKED) {
          const { penaltyEndDate, penaltyReason } = this.newBooking.user;
          this.showBlockedUserModal({ penaltyEndDate, penaltyReason });
        }
        this.newBooking.townId = data.town.id;
        this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
        this.getServiceInfosEventEmitter.emit({ serviceInfos: this.serviceInfos, townId: this.newBooking.townId });
        this.changeTown.emit();
        await this.updateBookingForm(undefined, ['user']);
        
        this.isSingleReservation = this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
        if (this.isSingleReservation) {
          this.destinationsStops = this.newBooking.stops;
        }

        if (!this.steps.includes(2)) this.steps.push(2);
        break;
      }
      case 'town': {
        if (this.newBooking.townId === data) break
        this.newBooking.townId = data;
        this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
        
        await this.updateBookingForm(undefined, ['town']);
        
        this.getServiceInfosEventEmitter.emit({ serviceInfos: this.serviceInfos, townId: this.newBooking.townId });
        this.changeTown.emit();
  
        this.isBack = false;
        this.dataLoaded = true;
        this.possibleSwap = false;
        this.changeTown.emit();
  
        this.isSingleReservation = this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
  
        if (this.isSingleReservation) {
          this.destinationsStops = this.newBooking.stops;
        }
  
        break;
      }
      case 'originStopId': {
        this.newBooking.originStopId = data;
        
        const serviceInfoData = this.serviceInfos.find(serviceInfo =>
          serviceInfo.stops.some(stop => stop.id === data)
        ) || this.serviceInfos[0];
  
        this.newBooking.serviceInfo = serviceInfoData;
        this.setLocation(data);
  
        if (!this.isSingleReservation) {
          this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
        }
  
        await this.updateBookingForm(undefined, ['stop']);
        let singleStopReservationType = this.newBooking.originStopId ? SingleStopReservationType.ORIGIN : SingleStopReservationType.DESTINATION;
        if (this.isSingleReservation) {
          singleStopReservationType === SingleStopReservationType.ORIGIN && this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'origin', isSingleReservation: this.isSingleReservation });
        } else {
          this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'origin', isSingleReservation: this.isSingleReservation });
        }
        this.selectStop.emit({ destinationsStops: this.destinationsStops });
  
        if (this.isSingleReservation) {
          this.setDestinationStops(undefined);
        }
  
        break;
      }
      case 'destinationsStops': {
        this.setDestinationStops(data);
        break;
      }
      case 'date': {
        if (data) {
          this.newBooking.date = data ? data.split(',').map((date: string) => 
            moment(date.trim(), 'DD/MM/YYYY').format('YYYY-MM-DD')
          ).join(',') : '';
        } else {
          this.newBooking.date = '';
        }
  
        await this.updateBookingForm();
        break;
      }
      case 'time': {
        this.newBooking.time = data;
        await this.updateBookingForm();
        break;
      }
      default:
        break;
    }
  
    this.currentStep = this.steps.at(-1)!;
  };

  addSeat = (data: any, fromFavourite: boolean = false) => {
    if (fromFavourite) {
      this.setDestinationStops(data);
    } else {
      let destination: any = 0;
      destination = this.destinationsStops.find((destination: any) => 
        (destination.id > 0 ?  -1 * destination.id : destination.id) === data.outStopId
      );
      let bookingSeat: IBookingSeat;
      for (let index = 0; index < data.pax; index++) {
        bookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: false };
        this.newBooking.destinationStops.push(bookingSeat);
      }
      for (let index = 0; index < data.prm; index++) {
        bookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: true };
        this.newBooking.destinationStops.push(bookingSeat);
      }
      this.setLocation(this.newBooking.destinationStops[0].exitStop.id!, false);
    }
    this.possibleSwap = true;
  };

  setDestinationStops = async (data: any) => {
    let destination: any;
    destination = typeof data === 'object' ? { ...this.newBooking.destinationStops } : this.destinationsStops.find((d: any) => d.id === data);
    let singleStopReservationType = this.newBooking.originStopId ? SingleStopReservationType.ORIGIN : SingleStopReservationType.DESTINATION;
    let bookingSeat: IBookingSeat;
    if (singleStopReservationType === SingleStopReservationType.ORIGIN && !destination) {
      bookingSeat = {
        exitStop: { id: 0, name: '' },
        prm: data && data.type === 'prm' 
      };
    } else {
      bookingSeat = {
        exitStop: { id: destination[0]?.exitStop?.id || destination.id, name: destination[0]?.exitStop?.name || destination.name },
        prm: this.newBooking.destinationStops.length === 0 ? this.newBooking.user.prm : data.type === 'prm'
      };
      this.newBooking.serviceInfo = this.serviceInfos.find(serviceInfo =>
        serviceInfo.stops.some(stop => stop.id === destination[0]?.exitStop?.id || destination.id)
      ) || this.serviceInfos[0];
    }

    if (typeof data === 'object') {
      let index = -1;
      const existingSeat = this.newBooking.destinationStops.find((d: any, i: number) => { index = i; return d.prm === bookingSeat.prm; });

      if (existingSeat && data.key === 'minus') this.newBooking.destinationStops.splice(index, 1);
      else if (data.key === 'plus') this.newBooking.destinationStops.push(bookingSeat);
      else if (data.key === 'value' && data.value !== '') {
        this.newBooking.destinationStops = this.newBooking.destinationStops.filter((d: any) => d.prm !== bookingSeat.prm);
        Array.from({ length: data.value }).forEach(() => this.newBooking.destinationStops.push(bookingSeat));
      }
      destination.length && this.setLocation(destination[0].id, false);
    } else {
      this.newBooking.destinationStops = this.newBooking.destinationStops.length > 1
        ? this.newBooking.destinationStops.map((d: IBookingSeat) => ({ ...bookingSeat, prm: d.prm }))
        : [bookingSeat];
        destination && this.setLocation(destination.id, false);
    }

    this.newBooking.destinationStops = [...this.newBooking.destinationStops];
    this.pax = this.newBooking.destinationStops.filter((d: any) => !d.prm).length;
    this.prm = this.newBooking.destinationStops.filter((d: any) => d.prm).length;
    await this.updateBookingForm(undefined, ['destination']);
    if (this.isSingleReservation) {
      singleStopReservationType === SingleStopReservationType.DESTINATION && this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'destination', isSingleReservation: this.isSingleReservation });
    } else {
      this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'destination', isSingleReservation: this.isSingleReservation });
    }
    this.possibleSwap = true;
  };

  setLocation = (id: number, origin = true) => {
    this.serviceInfos.map((serviceInfo: IServiceInfo) => {
      serviceInfo.stops.find((stop: any) => {
        if (stop.id === id) {
          origin ?
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.originStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            })
          :
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.destinationStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            });
        }
      });
    });
  };

  scroll = async (event: Select2ScrollEvent) => {
    if (event.way === 'down') {
      if (event.search !== '') {
        await this.updateBookingForm(new UserBookingData({showMoreUsers: true, search: true, value: event.search}));
      } else {
        await this.updateBookingForm(new UserBookingData({showMoreUsers: true}));
      }
    } 
  };

  search = async (event: Select2SearchEvent) => {
    const dropDown = document.getElementsByClassName('select2-results__options')[0];
    console.log(event)
    const value = event.search;
    if (value !== '') {
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false, search: true, value: value}));
      dropDown.scroll({
        top: 0
      });
    } else {
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false}));
    }
  };

  change = (data: any, valueToChange: string) => {
    if (valueToChange === 'destinationsStops') {
      this.set(data, 'destinationsStops');
    } else if (valueToChange === 'date') {
      this.set(data.target.value, 'date');
    } else if (valueToChange === 'time') {
      this.set(data.target.value, 'time');
    }
  };

  getAvailabilities = async () => {
    window.scrollTo(0, 0);
    this.loadingAvailabilities = true;
    !this.steps.includes(3) && this.steps.push(3);
    this.currentStep = this.steps.at(-1)!;
    const dates = this.newBooking.date.split(',').filter((date: string) => {
      return date !== '';
    });
    const dateTimes = dates.map((date: any) => {
      return moment(date + ' ' + this.newBooking.time).format('YYYY-MM-DD[T]HH:mm');
    });
    const seats = this.newBooking.destinationStops.filter((destinationStop: IBookingSeat) => !destinationStop.prm).length;
    const prmSeats = this.newBooking.destinationStops.filter((destinationStop: IBookingSeat) => destinationStop.prm).length;
    await this.townsService.getAvailabilities(
      this.newBooking.townId,
      this.newBooking.targetUserId,
      this.newBooking.originStopId,
      this.newBooking.destinationStops[0].exitStop.id!,
      seats,
      prmSeats,
      dateTimes,
      undefined,  // is_arrival_time: boolean = false
      undefined,  // reservationId?: number
      this.isSingleReservation
    ).then((resp: any) => {
      this.availabilities = resp;
    }, (error: any) => {
      console.log("ERROR", error);
      this.back();
    });
    

    this.availabilities.success.map((successAvailabilities: IReservationAvailability) => {
      const date = moment(successAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['success'].push(successAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.availabilities.failure.map((failureAvailabilities: IReservationAvailabilityFailure) => {
      const date = moment(failureAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['failure'].push(failureAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.availabilitiesDates.sort();
    this.totalElements = Object.keys(this.availabilitiesSeparateByDate).length;
    this.loadingAvailabilities = false;
  };

  getPassengers = () => {
    if (this.bookingFromFavourite) {
      this.pax = this.favourite.passengers!;
      this.prm = this.favourite.prmPassengers!;
    } else {
      this.pax = this.newBooking.destinationStops.filter((destination: any) => !destination.prm).length;
      this.prm = this.newBooking.destinationStops.filter((destination: any) => destination.prm).length;
    }
  };

  createBookings = async (multiple: boolean) => {
    this.creatingBooking = true;
    const availabilitiesToBook = multiple 
      ? this.availabilitiesDates.map(date => this.availabilitiesSeparateByDate[date].success[0]).filter((availability) => availability !== undefined)
      : this.availabilitiesSelected;
    const newBookings = this.makeBookingArray(availabilitiesToBook);
    await this.bookingsService.multipleBooking(newBookings).then(() => {
      this.goToList.emit();
      this.creatingBooking = false;
    }, (error) => {
      console.log("ERROR", error);
      this.creatingBooking = false;
      this.back();
    });
  };

  makeBookingArray(availabilities: any) {
    const newBookings: IBookingCreate[] = [] as IBookingCreate[];
    availabilities.forEach((availability: any) => {
      let booking = {...this.newBooking};
      booking.availabilityId = availability.availabilityId;
      booking.originStopId = availability.inStop.id!;
      booking.destinationStops.forEach((destination: any) => {
        destination.exitStop.id = availability.outStop.id!;
        destination.exitStop.name = availability.outStop.name!;
      });
      booking.destinationId = availability.outStop.id!;
      booking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
      booking.serviceId = availability.serviceId;
      booking.tripId = availability.expeditionId;
      newBookings.push(booking);
    });
    return newBookings;
  }

  showMaxReservationTimeModal = () => {
    this.showMaxReservationTimeModalEventEmitter.emit();
    this.back();
  };
  
  showExceedingKmLimitModal() {
    const availabilitiesToBook = this.availabilitiesSelected.length > 0 ? this.availabilitiesSelected : this.availabilitiesDates.map(date => this.availabilitiesSeparateByDate[date].success[0])
    this.showExceedingKmLimitModalEventEmitter.emit(availabilitiesToBook);
  }
  
  showBlockedUserModal = (penalty: any) => { 
    this.showBlockedUserModalEventEmitter.emit({penalty: penalty, booking: this.newBooking});
  };

  getDate(): string {
    return this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0] ?
      moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0].dateTime).format('DD/MM/YYYY')
      :
      this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].failure[0].dateTime).format('DD/MM/YYYY');
  };

  back = async () => {
    this.steps.pop();
    this.currentStep = this.steps.at(-1)!;
    this.isBack = true;
    if (this.currentStep === 1) {
      this.newBooking = {} as IBookingCreate;
      this.newBooking.destinationStops = [];
      this.newBooking.stops = [];
      this.newBooking.date = moment().format('YYYY-MM-DD');
      this.newBooking.time = moment().format('HH:mm');
    } else {
      this.creatingBooking = false;
      this.availabilitiesDates = [];
      this.availabilitiesSeparateByDate = {};
      this.availabilitiesSelected = [];
      this.currentPage = 0;
    }
    await this.updateBookingForm(new UserBookingData({showMoreUsers: false}));
    this.ngOnInit();
  };

  changeStops = async() => {
    const newBookingCopy = {...this.newBooking};
    if (!this.newBooking.serviceInfo.singleStopReservations) {
      let oppositeStop = this.newBooking.destinationStops[0].exitStop.id!;
      const origin = this.newBooking.stops.find((stop: any) => stop.id === oppositeStop) 
                      || this.newBooking.stops.find((stop: any) => Math.abs(stop.id) === Math.abs(oppositeStop));
      this.newBooking.originStopId = origin.id;
      this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'origin' });

      this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
      this.newBooking.destinationStops = [];
      await this.updateBookingForm(undefined, ['stop']);
      
      oppositeStop = newBookingCopy.originStopId;
      const destination: any = this.destinationsStops.find((stop: any) => stop.id === oppositeStop) 
                      || this.destinationsStops.find((stop: any) => Math.abs(stop.id) === Math.abs(oppositeStop));
      destination && newBookingCopy.destinationStops.forEach((destinationStop: any) => {
        destinationStop.exitStop.id = destination.id;
        destinationStop.exitStop.name = destination.name;
      });
      this.newBooking.destinationStops = !destination ? [] : newBookingCopy.destinationStops;

      await this.updateBookingForm();
      this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'destination' });
      this.showMessageIfAnyStopAreIncompatible();
    } else {
      const singleStopReservationType = this.newBooking.destinationStops[0].exitStop.id !== 0 ? SingleStopReservationType.DESTINATION: SingleStopReservationType.ORIGIN;
      if (singleStopReservationType === SingleStopReservationType.DESTINATION) {
        this.newBooking.originStopId = newBookingCopy.destinationStops[0].exitStop.id!;
        this.newBooking.destinationStops.map((destinationStop: any) => {
          destinationStop.exitStop.id = 0;
          destinationStop.exitStop.name = '';
        });
      } else {
        this.newBooking.originStopId = 0;
        this.newBooking.destinationStops.map((destinationStop: any) => {
          destinationStop.exitStop.id = newBookingCopy.originStopId;
        });
      }
      await this.updateBookingForm();
    }
  };

  showMessageIfAnyStopAreIncompatible = () => {
    const { stops, originStopId, destinationStops } = this.newBooking;
    const stopOrigin = stops && stops.find((stop: any) => stop.id === originStopId);
    const stopDestination = destinationStops.length > 0 && this.destinationsStops.find(
      (destinationStop: any) => destinationStop.id === destinationStops[0].exitStop.id);
    if (!stopOrigin && !stopDestination) {
      this.stopOriginNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopOriginNotification = false;
      }, 5000);
      this.newBooking.originStopId = 0;
      this.newBooking.destinationStops = [];
    } else if (!stopOrigin) {
      this.stopOriginNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopOriginNotification = false;
      }, 5000);
      this.newBooking.originStopId = 0;
    } else if (!stopDestination) {
      this.stopDestinationNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopDestinationNotification = false;
      }, 5000);
      this.newBooking.destinationStops = [];
    }
  };

  disabledViewAvailabilities() {
    return this.isSingleReservation ? 
      !this.newBooking.originStopId && this.newBooking.destinationStops.length === 0 || this.newBooking.date === '' : 
      !this.newBooking.originStopId || this.newBooking.destinationStops.length === 0 || this.newBooking.date === '';
  }

  previousAvailability() {
    this.currentPage > 0 && this.currentPage--;
  }

  previousAvailabilityDisabled() {
    return this.currentPage === 0;
  }

  nextAvailability(availability?: IReservationAvailability | IReservationAvailabilityFailure) {
    (this.currentPage < Object.keys(this.availabilitiesSeparateByDate).length - 1) && this.currentPage++;
    if (availability) {
      const dateTime = moment(availability.dateTime).format('YYYY-MM-DD');
      const index = this.availabilitiesSelected.findIndex((availabilitySelected: IReservationAvailability | IReservationAvailabilityFailure) => moment(availabilitySelected.dateTime).format('YYYY-MM-DD') === dateTime);
      if (index !== -1) {
        this.availabilitiesSelected.splice(index, 1);
      }
      this.availabilitiesSelected.push(availability) 
    }
  }

  nextAvailabilityDisabled() {
    const successAvailabilities = this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success.map((successAvailability: any) => {
      return successAvailability;
    });
    const isAvailabilitySelected = this.availabilitiesSelected.some((availability: any) => 
      successAvailabilities.includes(availability)
    );
    return (this.currentPage === Object.keys(this.availabilitiesSeparateByDate).length - 1) || (successAvailabilities.length > 0 && !isAvailabilitySelected);
  }

  checkAllAvailabilitiesSelected() {
    const dates = this.availabilities.success.map((availability: any) => 
      moment(availability.dateTime).format('YYYY-MM-DD')
    );
    const selectedDates = this.availabilitiesSelected.map((availability: any) => 
      moment(availability.dateTime).format('YYYY-MM-DD')
    );
    return dates.every(date => selectedDates.includes(date));
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1.5);
  }

}
