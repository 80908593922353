import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StorageService } from 'src/app/services';
import { objectToCamel } from 'ts-case-convert';
import { RolesHttpService } from './role.http.service';
import { IUserPermission } from 'src/app/models/userPermission';

const userPermissions : IUserPermission[] = [];

@Injectable({
  providedIn: 'root',
})
export class RoleService {

  public permissions: { [role: string]: { [operation: string]: boolean } } = {
    'USER': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'EXPEDITION': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'RESERVATION': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'VEHICLE': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'SERVICE': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'MESSAGE': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    },
    'DASHBOARD': {
      'CREATE': false,
      'READ': false,
      'UPDATE': false,
      'DELETE': false
    }
  };

  constructor(
    private rolesHttpService: RolesHttpService,
    private storageService: StorageService
  ) {}

  async getUserPermissions() {
      const source$ = this.rolesHttpService.getUserPermissions();
      const response = await lastValueFrom(source$);
      const permissions = objectToCamel(response as object);
      return permissions;
  }

  public async setPermissions() {
    const storedPermissions = await this.storageService.getPermissions();
    if (storedPermissions) {
      const uPermissions: IUserPermission[] = JSON.parse(storedPermissions);
      for (let i = 0; i < uPermissions.length; i++) {
        const permission = uPermissions[i];
        if (this.permissions[permission.entityType]) {
          if (permission.operations.includes('CREATE')) {
            this.permissions[permission.entityType]['CREATE'] = true;
          }
          if (permission.operations.includes('READ')) {
            this.permissions[permission.entityType]['READ'] = true;
          }
          if (permission.operations.includes('UPDATE')) {
            this.permissions[permission.entityType]['UPDATE'] = true;
          }
          if (permission.operations.includes('DELETE')) {
            this.permissions[permission.entityType]['DELETE'] = true;
          }
        }
      }
      this.storageService.setUserPermissions(JSON.stringify(this.permissions));
    }
  }

  public async reloadPermissions() {
    const storedPermissions = await this.storageService.getUserPermissions();
    if (storedPermissions) {
      this.permissions = JSON.parse(storedPermissions);
    }
  }

  public async updateUserPermissions() {
    const permissions = await this.getUserPermissions();
    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
    for (let i = 0; i < permissionsArray.length; i++) {
      const permission = permissionsArray[i];
      userPermissions.push(permission);
    }
    const parsedPermissions = JSON.stringify(userPermissions);
    this.storageService.setPermissions(parsedPermissions);
    await this.setPermissions();
  }
}
