import { Injectable } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeIt from '@angular/common/locales/it';
import localeEl from '@angular/common/locales/el';
import localeEu from '@angular/common/locales/eu';
import localeFr from '@angular/common/locales/fr';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';
import { registerLocaleData } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageSubject = new BehaviorSubject<string>('es');
  public language$ = this.languageSubject.asObservable();

  private locales = [
    { key: 'localeEs', value: localeEs },
    { key: 'localeCa', value: localeCa },
    { key: 'localeEn', value: localeEn },
    { key: 'localePt', value: localePt },
    { key: 'localeIt', value: localeIt },
    { key: 'localeEl', value: localeEl },
    { key: 'localeEu', value: localeEu },
    { key: 'localeFr', value: localeFr },
  ];

  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    private utilsService: UtilsService,
  ) {}

  public setDefaultLanguage(language = 'en') {
    this.translate.setDefaultLang(language);
  }

  public async setLanguage(language = 'en') {
    this.translate.use(language);
    this.languageSubject.next(language);
    await this.storageService.removeLanguage();
    await this.storageService.setLanguage(language);
    const locale = `locale${this.utilsService.capitalize(language)}`;
    const localeSelected = this.getLocale(locale);
    registerLocaleData(localeSelected);
  }

  public getLocale(locale: string) {
    const localeSelected = this.locales.find((item) => {
      return item.key === locale;
    });
    return localeSelected ? localeSelected.value : localeEn;
  }
}
