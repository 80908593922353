import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { images } from 'src/images';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() value?: any;
  @Input() isRange?: boolean = false;
  @Input() required?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() showOnTop?: boolean = false;
  @Input() onlyFutureDays?: boolean = false;
  @Input() daysInAdvance?: number;
  @Input() multipleDates?: boolean = false;
  @Input() hideCalendar?: boolean = true;
  @Input() showYear?: boolean = true;
  @Input() possibleMultipleDates?: boolean = false;
  @Output() changeEventEmitter = new EventEmitter<any>();

  public showCalendar: boolean = false;
  public images = images;
  public calendar: any;
  public years: any[] = [];
  public calWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']; // NOTE: Change for any different calendars "SUN (the first)"
  public calMonthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  public InputType = InputType;

  @ViewChild('datepicker') datepicker: ElementRef;
  @ViewChild('calendarComponent') calendarComponent: ElementRef;

  public inputs: HTMLElement[] = [];
  public dropdowns: HTMLElement[] = [];

  public multipleDatesSelected: any[] = [];

  public datesToDisplay = '';

  @HostListener('change', ['$event'])
  onChange(event: Event) {
    console.log('onChange', event);
    setTimeout(() => {
      this.changeEventEmitter.emit(event);
    });
  }

  constructor(
    public literalService: LiteralService,
    public utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2,
  ) {
    Array.from(Array(new Date().getFullYear() - 1900 + 1).keys()).map((x) =>
      this.years.push({ id: x + 1900, name: x + 1900 }),
    );

    renderer.listen('window', 'click', (e: Event) => {
      const close = utilsService.closeComponent(
        e,
        this.inputs,
        this.dropdowns,
        this.title,
      );
      close ? this.handleCalendar(false) : this.handleCalendar(true);
    });
  }

  ngOnInit(): void {
    this.calendar = this.calendarControl();
    if (moment(this.value).isValid()) {
      this.multipleDatesSelected = [this.value];
      this.multipleDatesFormat();
    }
    const targetDate = this.multipleDates ? this.value.split(',')[0] : this.calendar.currentDate;
    this.calendar.changeYear(targetDate.year());
    this.calendar.changeMonth(targetDate.month());
    console.log("CALENDAR VALUE", this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChangesCalendar", changes);
    this.value = changes['value'] ? changes['value'].currentValue : this.value;
    this.multipleDatesSelected = this.value.split(',');
    this.multipleDatesFormat();
  }

  ngAfterViewInit(): void {
    this.inputs.push(document.getElementById('input-' + this.title)!);
    this.dropdowns.push(document.getElementById('dropdown-' + this.title)!);
  }

  public calendarControl() {
    const calendarControl = {
      localDate: new Date(),
      currentDate: this.value && moment(this.value),
      calWeekDays: this.calWeekDays,
      calMonthName: this.calMonthName,
      actualDate: moment(),
      multipleDatesActive: this.multipleDates,
      multipleDates: [ moment() ],
      daysInMonth: function () {
        const month = this.actualDate.month();
        const year = this.actualDate.year();
        return moment(new Date(year, month), 'YYYY-MM').daysInMonth();
      },
      firstDay: function () {
        return new Date(this.actualDate.year(), this.actualDate.month(), 1);
      },
      lastDay: function () {
        return new Date(this.actualDate.year(), this.actualDate.month(), 0);
      },
      firstDayNumber: function () {
        // NOTE: Uncomment if the first day is sunday
        // return (calendarControl.firstDay().getDay() + 1) % 7 || 7;
        const day = calendarControl.firstDay().getDay();
        return day === 0 ? 7 : day;  
      },
      lastDayNumber: function () {
        return calendarControl.lastDay().getDay();
      },
      previousMonth: function () {
        this.actualDate.subtract(1, 'M');
      },
      nextMonth: function () {
        this.actualDate.add(1, 'M');
      },
      changeYear: function (year: number) {
        this.actualDate.year(year);
      },
      changeMonth: function (month: number) {
        this.actualDate.month(month);
      },
      changeDay: function (day: number) {
        this.actualDate.date(day);
        this.currentDate = moment()
          .year(this.actualDate.year())
          .month(this.actualDate.month())
          .date(day);
      },
      displayYear: function () {
        return this.actualDate.year();
      },
      displayMonth: function () {
        return calendarControl.calMonthName[this.actualDate.month()];
      },
      getMonthNumber: function () {
        return this.actualDate.month();
      },
    };
    return calendarControl;
  }

  public selectDay(i: number) {
    this.calendar.changeDay(i);
    this.value = this.calendar.currentDate;

    if (this.multipleDates) {
      const date = moment(`${this.calendar.actualDate.year()}/${this.calendar.actualDate.month() + 1}/${i}`).format('YYYY-MM-DD');
      const index = this.multipleDatesSelected.findIndex((dateSelected: any) => {
        return dateSelected === date;
      });
      if (index > -1) {
        this.multipleDatesSelected.splice(index, 1);
      } else {
        this.multipleDatesSelected.push(date);
      }
      this.multipleDatesSelected = this.multipleDatesSelected.filter((date: any) => date !== undefined);
    }
    this.multipleDatesFormat();
    this.changeDetectorRef.detectChanges();
    const element = document.getElementById('datepicker');
    const event = new Event('change', { bubbles: true });
    element?.dispatchEvent(event);
    this.hideCalendar && this.handleCalendar(false);
  }

  public isToday(i: number): boolean {
    const actualDate = moment();
    return (
      (
        this.calendar.actualDate && this.calendar.actualDate.year() === actualDate.year() &&
        this.calendar.actualDate && this.calendar.getMonthNumber() === actualDate.month() &&
        i === actualDate.date()) ||
      false
    );
  }

  public selectedDate(i: number): boolean {
    if (this.multipleDates) {
      const date = moment(`${this.calendar.actualDate.year()}/${this.calendar.actualDate.month() + 1}/${i}`).format('YYYY-MM-DD');
      return this.multipleDatesSelected.includes(date);
    }
    const valueDate = moment(this.value);
    return (
      (this.calendar.actualDate.year() === valueDate.year() &&
        this.calendar.actualDate.month() === valueDate.month() &&
        this.calendar.currentDate &&
        i === this.calendar.currentDate.date()) ||
      false
    );
  }

  public handleCalendar(show: boolean) {
    show
      ? (this.showCalendar = show)
      : setTimeout(() => {
          this.showCalendar = show;
        });
  }

  public getYear() {
    return (
      (this.value && this.calendar.currentDate && this.calendar.currentDate.year()) ||
      this.calendar.actualDate.year()
    );
  }

  public changeYear(year: any) {
    this.calendar.changeYear(year.value);
  }

  public isDisabled(i: number) {
    const currentDate = this.calendar.actualDate;
    const targetDate = new Date(currentDate.year(), currentDate.month(), i);
    const targetMoment = moment(targetDate);

    if (this.onlyFutureDays) {
      if (this.daysInAdvance) {
        return !targetMoment.isBetween(moment().add(-1, 'days'), moment().add(this.daysInAdvance - 1, 'days'));
      } else {
        return targetMoment.isBefore(moment(), 'day');
      }
    }
    return targetMoment.isSameOrAfter(moment(), 'day');
  }

  multipleDatesFormat = () => {
    this.datesToDisplay = '';
    this.multipleDatesSelected = this.multipleDatesSelected.filter((date: any) => moment(date).isValid()).sort();
    if (this.multipleDates && this.multipleDatesSelected.length > 0) {
      this.datesToDisplay = this.multipleDatesSelected.map((date: any) => moment(date).format('DD/MM/YYYY')).join(', ');
    } else if (!this.multipleDates) {
      this.datesToDisplay = moment(this.calendar.currentDate).format('DD/MM/YYYY');
    }
  };

  changeMultipleDates = () => {
    this.multipleDates = !this.multipleDates;
    if (!this.multipleDates) {
      // this.calendar.currentDate, this.value = moment(); // Uncomment this for set the last selected
      this.calendar.currentDate = this.value = moment();
    }
  };
}
